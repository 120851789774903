import React from 'react'
import type { AppointmentStatus } from '../../../models/SelectCandidate'
import {
  menuItemsDict,
  appointmentStatusColorDict,
} from '../../../fixture/common/formOptions'

export const AppointmentStatusIndexView: React.FC = () => {
  return (
    <>
      <div className="md:block hidden">
        <MdAppointmentStatusIndexView />
      </div>
      <div className="md:hidden">
        <SmAppointmentStatusIndexView />
      </div>
    </>
  )
}

const MdAppointmentStatusIndexView: React.FC = () => {
  const appointmentStatusList = menuItemsDict.appointment_status
  return (
    <div className="flex items-center justify-start">
      <div className="grid grid-cols-4 rounded-none">
        {appointmentStatusList.map((status, index) => {
          return (
            <div
              key={index}
              className={`col-span-1 flex items-center justify-start py-1 text-xs`}
            >
              <div
                className={`w-3 h-3 border ${
                  appointmentStatusColorDict[status.key as AppointmentStatus]
                }`}
              ></div>
              <div>・・</div>
              <div className="pl-1">{status.value}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const SmAppointmentStatusIndexView: React.FC = () => {
  const appointmentStatusList = menuItemsDict.appointment_status
  return (
    <div className="flex items-center justify-start">
      <div className="grid grid-cols-2 rounded-none">
        {appointmentStatusList.map((status, index) => {
          return (
            <div
              key={index}
              className={`col-span-1 flex items-center justify-start text-xs`}
            >
              <div
                className={`border ${
                  appointmentStatusColorDict[status.key as AppointmentStatus]
                }`}
              >
                {status.value}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
