import type {
  Customer,
  CustomerView,
  Parent,
  ParentView,
  Child,
  ChildView,
  FamilyMember,
  FamilyMemberView,
} from '../../models/Customer'
import type { CompromisePoint } from '../../models/SelectCandidate'
import {
  appointmentStatusDict,
  genderDict,
  workStyleDict,
  insuranceDict,
  residenceTypeDict,
  residenceAppearanceDict,
  floorPlanDict,
  compromisePointCandidates,
} from '../../fixture/common/formOptions'
import {
  dateViewJPConverter,
  truncateNumber,
  boolViewConverter,
  birthdateToAgeConverter,
} from '../common/stringParser'
import type dayjs from '../../vendors/Dayjs'

export const customerViewParser = (props: {
  customer: Customer
  today: dayjs.Dayjs
}): CustomerView => {
  const { customer, today } = props

  const initCustomerView: CustomerView = {
    id: '',
    meta: '',
    appointment_status: '',
    inquiry_date: '',
    inquiry_address_ward: '',
    inquiry_property_name: '',
    residents: '',
    family_structure: '',
    nationality: '',
    have_permanent_residency: '',
    acquisition_expected_date: '',
    year_of_construction: '',
    inquiry_property_price: '',
    inquiry_property_width: '',
    inquiry_property_floor_plan: '',
    media: '',
    line_resistration: '',
    memo: '',
    reaction: '',
    address_ward: '',
    address_other: '',
    nearest_station: '',
    on_foot_minutes: '',
    plan_to_get_married: '',
    is_get_married: '',
    pet_breeding: '',
    have_vehicle: '',
    have_bicycle: '',
    parking_fee: '',
    customer_info: {
      family_name: '',
      name: '',
      family_name_kana: '',
      name_kana: '',
      gender: '',
      birth_date: '',
      age: '',
      phone_number: '',
      email: '',
      annual_income: '',
      length_of_service: '',
      work_style: '',
      workplace: '',
      industry_type: '',
      nearest_station: '',
      birthplace: '',
    },
    consort_info: {
      family_name: '',
      name: '',
      family_name_kana: '',
      name_kana: '',
      gender: '',
      birth_date: '',
      age: '',
      phone_number: '',
      email: '',
      annual_income: '',
      length_of_service: '',
      work_style: '',
      workplace: '',
      industry_type: '',
      nearest_station: '',
      birthplace: '',
    },
    children_info: [],
    family_member_info: [],
    total_customer_assets: '',
    customer_asset: '',
    parent_asset: '',
    stocks_and_bonds: '',
    other_asset: '',
    debt: '',
    details: '',
    insurance: '',
    have_disease: '',
    residence_type: '',
    residence_appearance: '',
    residence_type_other: '',
    have_ev: '',
    residence_width: '',
    residence_floor_plan: '',
    residence_rent: '',
    have_residence_rent_subsidy: '',
    residence_rent_subsidy: '',
    residence_years: '',
    residence_update_date: '',
    purchase_motive: '',
    when_looking_for: '',
    model_experience: '',
    inquiries_to_other: '',
    width_of_desire: '',
    width_of_min_desire: '',
    desired_floor_plan: '',
    monthly_payment: '',
    bonus_payment: '',
    budget_max: '',
    internet_search_conditions: '',
    desired_area_and_station: '',
    compromise_point: [],
  }
  // CustomerBlock
  initCustomerView.id = customer.id
  initCustomerView.meta = customer.meta
  if (customer.appointment_status) {
    initCustomerView.appointment_status =
      appointmentStatusDict[customer.appointment_status]
  }

  // InquiryBlock
  if (customer.inquiry_date) {
    initCustomerView.inquiry_date = dateViewJPConverter(customer.inquiry_date)
  }

  if (customer.media) {
    initCustomerView.media = customer.media
  }

  if (customer.inquiry_address_ward) {
    initCustomerView.inquiry_address_ward = customer.inquiry_address_ward
  }

  if (customer.inquiry_property_name) {
    initCustomerView.inquiry_property_name = customer.inquiry_property_name
  }

  if (customer.inquiry_property_price) {
    initCustomerView.inquiry_property_price = truncateNumber(
      customer.inquiry_property_price,
      10000
    )
  }

  if (customer.inquiry_property_width) {
    initCustomerView.inquiry_property_width = String(
      customer.inquiry_property_width
    )
  }

  if (customer.residents) {
    initCustomerView.residents = String(customer.residents)
  }

  initCustomerView.family_structure = familyStructureGenerator(customer)

  if (customer.nationality) {
    initCustomerView.nationality = customer.nationality
  }

  initCustomerView.have_permanent_residency = boolViewConverter({
    value: customer.have_permanent_residency,
    trueView: '有',
    falseView: '無',
    undefinedView: '未選択',
  })

  if (customer.acquisition_expected_date) {
    initCustomerView.acquisition_expected_date = dateViewJPConverter(
      customer.acquisition_expected_date
    )
  }

  // FamilyBlock
  if (customer.customer_info) {
    initCustomerView.customer_info = familyBlockViewParser(
      customer.customer_info,
      today
    )
  }

  if (customer.consort_info) {
    initCustomerView.consort_info = familyBlockViewParser(
      customer.consort_info,
      today
    )
  }

  if (customer.children_info) {
    initCustomerView.children_info = childrenBlockViewParser(
      customer.children_info,
      today
    )
  }

  if (customer.family_member_info) {
    initCustomerView.family_member_info = familyMemberBlockViewParser(
      customer.family_member_info,
      today
    )
  }

  // AddressBlock
  if (customer.address_ward) {
    initCustomerView.address_ward = customer.address_ward
  }

  if (customer.address_other) {
    initCustomerView.address_other = customer.address_other
  }

  if (customer.nearest_station) {
    initCustomerView.nearest_station = customer.nearest_station
  }

  if (customer.on_foot_minutes) {
    initCustomerView.on_foot_minutes = String(customer.on_foot_minutes)
  }

  initCustomerView.plan_to_get_married = boolViewConverter({
    value: customer.plan_to_get_married,
    trueView: '有',
    falseView: '無',
    undefinedView: '未選択',
  })

  initCustomerView.is_get_married = boolViewConverter({
    value: customer.is_get_married,
    trueView: '有',
    falseView: '無',
    undefinedView: '未選択',
  })

  initCustomerView.pet_breeding = boolViewConverter({
    value: customer.pet_breeding,
    trueView: '有',
    falseView: '無',
    undefinedView: '未選択',
  })

  initCustomerView.have_vehicle = boolViewConverter({
    value: customer.have_vehicle,
    trueView: '有',
    falseView: '無',
    undefinedView: '未選択',
  })

  if (customer.parking_fee) {
    initCustomerView.parking_fee = String(customer.parking_fee)
  }

  initCustomerView.have_bicycle = boolViewConverter({
    value: customer.have_bicycle,
    trueView: '有',
    falseView: '無',
    undefinedView: '未選択',
  })

  // AssetBlock
  if (customer.total_customer_assets) {
    initCustomerView.total_customer_assets = truncateNumber(
      customer.total_customer_assets,
      10000
    )
  }

  if (customer.customer_asset) {
    initCustomerView.customer_asset = truncateNumber(
      customer.customer_asset,
      10000
    )
  }

  if (customer.parent_asset) {
    initCustomerView.parent_asset = truncateNumber(customer.parent_asset, 10000)
  }

  if (customer.stocks_and_bonds) {
    initCustomerView.stocks_and_bonds = truncateNumber(
      customer.stocks_and_bonds,
      10000
    )
  }

  if (customer.other_asset) {
    initCustomerView.other_asset = truncateNumber(customer.other_asset, 10000)
  }

  if (customer.insurance) {
    initCustomerView.insurance = insuranceDict[customer.insurance]
  }

  initCustomerView.have_disease = boolViewConverter({
    value: customer.have_disease,
    trueView: '有',
    falseView: '無',
    undefinedView: '未選択',
  })

  if (customer.debt) {
    initCustomerView.debt = truncateNumber(customer.debt, 10000)
  }

  if (customer.details) {
    initCustomerView.details = customer.details
  }

  // ResidenceEnvironmentBlock
  if (customer.residence_type) {
    initCustomerView.residence_type = residenceTypeDict[customer.residence_type]
  }

  if (customer.residence_type_other) {
    initCustomerView.residence_type_other = customer.residence_type_other
  }

  if (customer.residence_appearance) {
    initCustomerView.residence_appearance =
      residenceAppearanceDict[customer.residence_appearance]
  }

  initCustomerView.have_ev = boolViewConverter({
    value: customer.have_ev,
    trueView: '有',
    falseView: '無',
    undefinedView: '未選択',
  })

  if (customer.residence_width) {
    initCustomerView.residence_width = String(customer.residence_width)
  }

  if (customer.residence_floor_plan) {
    initCustomerView.residence_floor_plan =
      floorPlanDict[customer.residence_floor_plan]
  }

  if (customer.residence_rent) {
    initCustomerView.residence_rent = String(customer.residence_rent)
  }

  initCustomerView.have_residence_rent_subsidy = boolViewConverter({
    value: customer.have_residence_rent_subsidy,
    trueView: '有',
    falseView: '無',
    undefinedView: '未選択',
  })

  if (customer.residence_rent_subsidy) {
    initCustomerView.residence_rent_subsidy = String(
      customer.residence_rent_subsidy
    )
  }

  if (customer.residence_years) {
    initCustomerView.residence_years = String(customer.residence_years)
  }

  if (customer.residence_update_date) {
    initCustomerView.residence_update_date = dateViewJPConverter(
      customer.residence_update_date
    )
  }

  if (customer.purchase_motive) {
    initCustomerView.purchase_motive = customer.purchase_motive
  }

  if (customer.when_looking_for) {
    initCustomerView.when_looking_for = customer.when_looking_for
  }

  if (customer.model_experience) {
    initCustomerView.model_experience = String(customer.model_experience)
  }

  initCustomerView.inquiries_to_other = boolViewConverter({
    value: customer.inquiries_to_other,
    trueView: '有',
    falseView: '無',
    undefinedView: '未選択',
  })

  // DesireBlock
  if (customer.width_of_desire) {
    initCustomerView.width_of_desire = String(customer.width_of_desire)
  }

  if (customer.width_of_min_desire) {
    initCustomerView.width_of_min_desire = String(customer.width_of_min_desire)
  }

  if (customer.desired_floor_plan) {
    initCustomerView.desired_floor_plan =
      floorPlanDict[customer.desired_floor_plan]
  }

  if (customer.monthly_payment) {
    initCustomerView.monthly_payment = truncateNumber(
      customer.monthly_payment,
      10000
    )
  }

  if (customer.bonus_payment) {
    initCustomerView.bonus_payment = truncateNumber(
      customer.bonus_payment,
      10000
    )
  }

  if (customer.budget_max) {
    initCustomerView.budget_max = truncateNumber(customer.budget_max, 10000)
  }

  if (customer.internet_search_conditions) {
    initCustomerView.internet_search_conditions =
      customer.internet_search_conditions
  }

  if (customer.desired_area_and_station) {
    initCustomerView.desired_area_and_station =
      customer.desired_area_and_station
  }

  // GraphBlock
  if (customer.compromise_point) {
    initCustomerView.compromise_point = compromisePointParser(customer)
  }

  return initCustomerView
}

const familyBlockViewParser = (
  parentInfo: Parent,
  today: dayjs.Dayjs
): ParentView => {
  return {
    family_name: parentInfo.family_name ? parentInfo.family_name : '',
    name: parentInfo.name ? parentInfo.name : '',
    family_name_kana: parentInfo.family_name_kana
      ? parentInfo.family_name_kana
      : '',
    name_kana: parentInfo.name_kana ? parentInfo.name_kana : '',
    gender: parentInfo.gender ? genderDict[parentInfo.gender] : '',
    birth_date: parentInfo.birth_date ? parentInfo.birth_date : '',
    age: parentInfo.birth_date
      ? birthdateToAgeConverter(parentInfo.birth_date, today)
      : '',
    phone_number: parentInfo.phone_number ? parentInfo.phone_number : '',
    email: parentInfo.email ? parentInfo.email : '',
    annual_income: parentInfo.annual_income
      ? truncateNumber(parentInfo.annual_income, 10000)
      : '',
    length_of_service: parentInfo.length_of_service
      ? String(parentInfo.length_of_service)
      : '',
    work_style: parentInfo.work_style
      ? workStyleDict[parentInfo.work_style]
      : '',
    workplace: parentInfo.workplace ? parentInfo.workplace : '',
    industry_type: parentInfo.industry_type ? parentInfo.industry_type : '',
    nearest_station: parentInfo.nearest_station
      ? parentInfo.nearest_station
      : '',
    birthplace: parentInfo.birthplace ? parentInfo.birthplace : '',
  }
}

const childrenBlockViewParser = (
  childrenInfo: Child[],
  today: dayjs.Dayjs
): ChildView[] => {
  return childrenInfo.map((child) => {
    return {
      birth_date: child.birth_date ? child.birth_date : '',
      age: child.birth_date
        ? birthdateToAgeConverter(child.birth_date, today)
        : '',
      gender: child.gender ? genderDict[child.gender] : '',
      school_name: child.school_name ? child.school_name : '',
      school_area: child.school_area ? child.school_area : '',
    }
  })
}

const familyMemberBlockViewParser = (
  familyMemberInfo: FamilyMember[],
  today: dayjs.Dayjs
): FamilyMemberView[] => {
  return familyMemberInfo.map((famlyMember) => {
    return {
      relationship: famlyMember.relationship ? famlyMember.relationship : '',
      birth_date: famlyMember.birth_date ? famlyMember.birth_date : '',
      age: famlyMember.birth_date
        ? birthdateToAgeConverter(famlyMember.birth_date, today)
        : '',
    }
  })
}

const familyStructureGenerator = (customer: Customer): string => {
  let familyStructure = []

  if (customer.consort_info && customer.is_get_married) {
    const consort = customer.consort_info.gender === 'male' ? '夫' : '妻'
    familyStructure.push(consort)
  }

  if (customer.children_info) {
    const children = new Array(customer.children_info.length).fill('子')
    familyStructure = familyStructure.concat(children)
  }

  if (customer.family_member_info) {
    const familyMember = customer.family_member_info
      .map((member) => member.relationship)
      .filter((v) => v)
    familyStructure = familyStructure.concat(familyMember as string[])
  }

  return familyStructure.join(' ')
}

const compromisePointParser = (customer: Customer): CompromisePoint[] => {
  const compromisePoint = customer.compromise_point
    ? customer.compromise_point
    : {}
  return compromisePointCandidates.filter(
    (item) => compromisePoint[item] === true
  )
}
