import type { Inquiry, InquiryView } from '../../models/Inquiry'
import {
  datetimeViewJPConverter,
  truncateNumber,
  boolViewConverter,
} from '../common/stringParser'
import {
  floorPlanDict,
  reactionDict,
  appointmentStatusDict,
} from '../../fixture/common/formOptions'

export const inquiryParser = (props: { inquiry: Inquiry }): InquiryView => {
  const { inquiry } = props

  const initInquiryView: InquiryView = {
    id: '',
    meta: '',
    inquiry_date: '',
    full_name: '',
    nearest_station: '',
    property_name: '',
    year_of_construction: '',
    price: '',
    floor_plan: '',
    media: '',
    line_resistration: '',
    memo: '',
    reaction: '',
    appointment_status: '',
    price_and_floor_plan: '',
  }
  initInquiryView.id = inquiry.id
  initInquiryView.meta = inquiry.meta

  if (inquiry.inquiry_date) {
    initInquiryView.inquiry_date = datetimeViewJPConverter(inquiry.inquiry_date)
  }

  if (inquiry.family_name ?? inquiry.name) {
    const fullName = []
    if (inquiry.family_name) {
      fullName.push(inquiry.family_name)
    }
    if (inquiry.name) {
      fullName.push(inquiry.name)
    }
    initInquiryView.full_name = fullName.join(' ')
  }
  if (inquiry.nearest_station) {
    initInquiryView.nearest_station = inquiry.nearest_station
  }
  if (inquiry.property_name) {
    initInquiryView.property_name = inquiry.property_name
  }
  if (inquiry.price) {
    initInquiryView.price = `${truncateNumber(inquiry.price, 10000)}万円`
  }
  if (inquiry.floor_plan) {
    initInquiryView.floor_plan = floorPlanDict[inquiry.floor_plan]
  }

  initInquiryView.price_and_floor_plan = `${initInquiryView.price}\n${initInquiryView.floor_plan}`

  if (inquiry.year_of_construction) {
    initInquiryView.year_of_construction = inquiry.year_of_construction
  }
  if (inquiry.memo) {
    initInquiryView.memo = inquiry.memo
  }
  initInquiryView.line_resistration = boolViewConverter({
    value: inquiry.line_resistration,
    trueView: '有',
    falseView: '無',
    undefinedView: '未選択',
  })
  if (inquiry.media) {
    initInquiryView.media = inquiry.media
  }
  if (inquiry.reaction) {
    initInquiryView.reaction = reactionDict[inquiry.reaction]
  }
  if (inquiry.appointment_status) {
    initInquiryView.appointment_status =
      appointmentStatusDict[inquiry.appointment_status]
  }

  return initInquiryView
}

export const inquiriesParser = (props: {
  inquiries: Inquiry[]
}): InquiryView[] => {
  const { inquiries } = props

  return inquiries.map((inquiry) => {
    return inquiryParser({ inquiry })
  })
}
