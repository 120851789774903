import type { Appointment, AppointmentView } from '../../models/Appointment'
import {
  datetimeViewJPConverter,
  boolViewConverter,
  birthdateToAgeConverter,
  truncateNumber,
} from '../common/stringParser'
import {
  appointmentStatusDict,
  genderDict,
  rankDict,
  preparationDict,
  appointmentResultDict,
  floorPlanDict,
} from '../../fixture/common/formOptions'
import dayjs from '../../vendors/Dayjs'

export const appointmentParser = (props: {
  appointment: Appointment
  today: dayjs.Dayjs
}): AppointmentView => {
  const { appointment, today } = props

  const initAppointmentView: AppointmentView = {
    id: '',
    meta: '',
    customer_id: '',
    appointment_status: '',
    appointment_date: '',
    rank: '',
    appointment_family_number: '',
    inquiry_price: '',
    floor_plan: '',
    monthly_repayment_amount: '',
    annual_income: '',
    own_resources: '',
    appointment_count: '',
    media: '',
    negotiation_person: '',
    preparation: '',
    preparation_check: '',
    memo: '',
    remarks: '',
    neck: '',
    appointment_result: '',
    appointment_result_reason: '',
    line_resistration: '',
    age: '',
    gender: '',
    address_ward: '',
    full_name: '',
    age_and_gender: '',
    inquiry_price_and_floor_plan: '',
  }
  initAppointmentView.id = appointment.id
  initAppointmentView.meta = appointment.meta
  initAppointmentView.customer_id = appointment.customer_id

  if (appointment.appointment_status) {
    initAppointmentView.appointment_status =
      appointmentStatusDict[appointment.appointment_status]
  }

  if (appointment.appointment_date) {
    initAppointmentView.appointment_date = datetimeViewJPConverter(
      appointment.appointment_date
    )
  }

  if (appointment.rank) {
    initAppointmentView.rank = rankDict[appointment.rank]
  }

  if (appointment.appointment_family_number) {
    initAppointmentView.appointment_family_number =
      appointment.appointment_family_number
  }

  if (appointment.inquiry_price) {
    initAppointmentView.inquiry_price = `${truncateNumber(
      appointment.inquiry_price,
      10000
    )}万円`
  }

  if (appointment.floor_plan) {
    initAppointmentView.floor_plan = floorPlanDict[appointment.floor_plan]
  }

  initAppointmentView.inquiry_price_and_floor_plan = `${initAppointmentView.inquiry_price}\n${initAppointmentView.floor_plan}`

  if (appointment.monthly_repayment_amount) {
    initAppointmentView.monthly_repayment_amount =
      appointment.monthly_repayment_amount
  }

  if (appointment.annual_income) {
    initAppointmentView.annual_income = `${truncateNumber(
      appointment.annual_income,
      10000
    )}万円`
  }

  if (appointment.own_resources) {
    initAppointmentView.own_resources = appointment.own_resources
  }

  if (appointment.appointment_count) {
    initAppointmentView.appointment_count = String(
      appointment.appointment_count
    )
  }

  if (appointment.media) {
    initAppointmentView.media = appointment.media
  }

  if (appointment.negotiation_person) {
    initAppointmentView.negotiation_person = appointment.negotiation_person
  }

  if (appointment.preparation) {
    initAppointmentView.preparation = preparationDict[appointment.preparation]
  }

  initAppointmentView.preparation_check = boolViewConverter({
    value: appointment.preparation_check,
    trueView: '済',
    falseView: '未',
    undefinedView: '未選択',
  })

  if (appointment.memo) {
    initAppointmentView.memo = appointment.memo
  }

  if (appointment.remarks) {
    initAppointmentView.remarks = appointment.remarks
  }

  if (appointment.neck) {
    initAppointmentView.neck = appointment.neck
  }

  if (appointment.appointment_result) {
    initAppointmentView.appointment_result =
      appointmentResultDict[appointment.appointment_result]
  }

  if (appointment.appointment_result_reason) {
    initAppointmentView.appointment_result_reason =
      appointment.appointment_result_reason
  }

  if (appointment.family_name ?? appointment.name) {
    const fullName = []
    if (appointment.family_name) {
      fullName.push(appointment.family_name)
    }
    if (appointment.name) {
      fullName.push(appointment.name)
    }
    initAppointmentView.full_name = fullName.join(' ')
  }

  initAppointmentView.line_resistration = boolViewConverter({
    value: appointment.line_resistration,
    trueView: '有',
    falseView: '無',
    undefinedView: '未選択',
  })

  if (appointment.birth_date) {
    initAppointmentView.age = birthdateToAgeConverter(
      appointment.birth_date,
      today
    )
  }

  if (appointment.gender) {
    initAppointmentView.gender = genderDict[appointment.gender]
  }

  initAppointmentView.age_and_gender = `${initAppointmentView.age}\n（${initAppointmentView.gender}）`

  if (appointment.address_ward) {
    initAppointmentView.address_ward = appointment.address_ward
  }

  return initAppointmentView
}

export const appointmentsParser = (props: {
  appointments: Appointment[]
}): AppointmentView[] => {
  const { appointments } = props
  const today = dayjs()

  return appointments.map((appointment) => {
    return appointmentParser({ appointment, today })
  })
}
