import React from 'react'
import { Typography, IconButton } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import ModeEditIcon from '@mui/icons-material/ModeEdit'
import EditOffIcon from '@mui/icons-material/EditOff'
import SendIcon from '@mui/icons-material/Send'
import type { Customer, CustomerView } from '../../../models/Customer'
import { customerViewParser } from '../../../utils/customer/customerViewParser'
import { customerInputConverter } from '../../../utils/customer/customerInputConverter'
import dayjs from '../../../vendors/Dayjs'
import {
  CustomerChartInquiryBlock,
  SmCustomerChartInquiryBlock,
} from './containerComponents/CustomerChartInquiryBlock'
import {
  CustomerChartAppointmentBlock,
  SmCustomerChartAppointmentBlock,
} from './containerComponents/CustomerChartAppointmentBlock'
import {
  CustomerChartAddressBlock,
  SmCustomerChartAddressBlock,
} from './containerComponents/CustomerChartAddressBlock'
import {
  CustomerChartAssetBlock,
  SmCustomerChartAssetBlock,
} from './containerComponents/CustomerChartAssetBlock'
import {
  CustomerChartResidenceEnvironmentBlock,
  SmCustomerChartResidenceEnvironmentBlock,
} from './containerComponents/CustomerChartResidenceEnvironmentBlock'
import {
  CustomerChartDesireBlock,
  SmCustomerChartDesireBlock,
} from './containerComponents/CustomerChartDesireBlock'
import {
  CustomerChartGraphBlock,
  SmCustomerChartGraphBlock,
} from './containerComponents/CustomerChartGraphBlock'
import { toggleEditHandler } from '../../../utils/customer/customerChartModules'
import { usePutCustomerMutation } from '../../../vendors/graphql/generated'

interface CustomerChartTableProps {
  title: string
  customer: Customer
}

interface CustomerChartTableChildProps {
  editCustomer: Customer
  customerView: CustomerView
  setEditCustomer: React.Dispatch<React.SetStateAction<Customer>>
  editMode: boolean
}

export const CustomerChartContainer: React.FC<CustomerChartTableProps> = (
  props: CustomerChartTableProps
) => {
  const { title, customer } = props
  const today = dayjs()

  const [putCustomer] = usePutCustomerMutation()

  const [editMode, setEditMode] = React.useState<boolean>(false)
  const initCustomer = {}
  const [editCustomer, setEditCustomer] = React.useState<Customer>(initCustomer)
  const customerView = customerViewParser({ customer: editCustomer, today })
  // const customerView = React.useMemo(
  //   () => customerViewParser({ customer: editCustomer, today }),
  //   [editCustomer]
  // )

  const handleUpdateCustomer = async (): Promise<void> => {
    console.log('update editCustomer', editCustomer)
    const key = {
      id: editCustomer.id ?? '',
      meta: editCustomer.meta ?? '',
    }
    const inputCustomer = customerInputConverter({ customer: editCustomer })
    await putCustomer({ variables: { input: inputCustomer, key } })
    setEditMode(false)
  }

  React.useEffect(() => {
    setEditCustomer(customer)
  }, [setEditCustomer, customer])

  return (
    <div>
      <div className="flex justify-start">
        <Typography className="pt-4" variant="subtitle1">
          {title}
        </Typography>
        <div className="pt-2 pl-2">
          <IconButton
            onClick={() => {
              toggleEditHandler(editMode, setEditMode)
            }}
          >
            {editMode ? <EditOffIcon /> : <ModeEditIcon />}
          </IconButton>
        </div>
        {editMode && (
          <div className="pt-2 pl-2">
            <IconButton
              onClick={async () => {
                await handleUpdateCustomer()
              }}
            >
              <SendIcon />
            </IconButton>
          </div>
        )}
      </div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div
          className="my-2 max-w-5xl"
          onDoubleClick={() => {
            !editMode && toggleEditHandler(editMode, setEditMode)
          }}
        >
          <div className="md:block hidden">
            <MdCustomerChartContainer
              editCustomer={editCustomer}
              customerView={customerView}
              setEditCustomer={setEditCustomer}
              editMode={editMode}
            />
          </div>
          <div className="md:hidden">
            <SmCustomerChartContainer
              editCustomer={editCustomer}
              customerView={customerView}
              setEditCustomer={setEditCustomer}
              editMode={editMode}
            />
          </div>
        </div>
      </LocalizationProvider>
    </div>
  )
}

export const MdCustomerChartContainer: React.FC<
  CustomerChartTableChildProps
> = (props: CustomerChartTableChildProps) => {
  const { editCustomer, customerView, setEditCustomer, editMode } = props
  return (
    <>
      <section>
        <CustomerChartInquiryBlock
          editCustomer={editCustomer}
          customerView={customerView}
          setEditCustomer={setEditCustomer}
          editMode={editMode}
        />
      </section>
      <section className="mt-6">
        <CustomerChartAppointmentBlock
          editCustomer={editCustomer}
          customerView={customerView}
          setEditCustomer={setEditCustomer}
          editMode={editMode}
        />
      </section>
      <section className="mt-6">
        <CustomerChartAddressBlock
          editCustomer={editCustomer}
          customerView={customerView}
          setEditCustomer={setEditCustomer}
          editMode={editMode}
        />
      </section>
      <section className="mt-6">
        <CustomerChartAssetBlock
          editCustomer={editCustomer}
          customerView={customerView}
          setEditCustomer={setEditCustomer}
          editMode={editMode}
        />
      </section>
      <section className="">
        <CustomerChartResidenceEnvironmentBlock
          editCustomer={editCustomer}
          customerView={customerView}
          setEditCustomer={setEditCustomer}
          editMode={editMode}
        />
      </section>
      <section className="mt-6">
        <CustomerChartDesireBlock
          editCustomer={editCustomer}
          customerView={customerView}
          setEditCustomer={setEditCustomer}
          editMode={editMode}
        />
      </section>
      <section className="mt-6">
        <CustomerChartGraphBlock
          editCustomer={editCustomer}
          customerView={customerView}
          setEditCustomer={setEditCustomer}
          editMode={editMode}
        />
      </section>
    </>
  )
}

export const SmCustomerChartContainer: React.FC<
  CustomerChartTableChildProps
> = (props: CustomerChartTableChildProps) => {
  const { editCustomer, customerView, setEditCustomer, editMode } = props
  return (
    <>
      <section>
        <SmCustomerChartInquiryBlock
          editCustomer={editCustomer}
          customerView={customerView}
          setEditCustomer={setEditCustomer}
          editMode={editMode}
        />
      </section>
      <section className="mt-6">
        <SmCustomerChartAppointmentBlock
          editCustomer={editCustomer}
          customerView={customerView}
          setEditCustomer={setEditCustomer}
          editMode={editMode}
        />
      </section>
      <section className="mt-6">
        <SmCustomerChartAddressBlock
          editCustomer={editCustomer}
          customerView={customerView}
          setEditCustomer={setEditCustomer}
          editMode={editMode}
        />
      </section>
      <section className="mt-6">
        <SmCustomerChartAssetBlock
          editCustomer={editCustomer}
          customerView={customerView}
          setEditCustomer={setEditCustomer}
          editMode={editMode}
        />
      </section>
      <section className="">
        <SmCustomerChartResidenceEnvironmentBlock
          editCustomer={editCustomer}
          customerView={customerView}
          setEditCustomer={setEditCustomer}
          editMode={editMode}
        />
      </section>
      <section className="mt-6">
        <SmCustomerChartDesireBlock
          editCustomer={editCustomer}
          customerView={customerView}
          setEditCustomer={setEditCustomer}
          editMode={editMode}
        />
      </section>
      <section className="mt-6">
        <SmCustomerChartGraphBlock
          editCustomer={editCustomer}
          customerView={customerView}
          setEditCustomer={setEditCustomer}
          editMode={editMode}
        />
      </section>
    </>
  )
}
