import dayjs from '../../vendors/Dayjs'

interface BoolViewConverterInput {
  value: boolean | undefined
  trueView: string
  falseView: string
  undefinedView: string
}

export const timeViewConverter = (date: string | undefined): string => {
  if (date === undefined) {
    return '----/--/-- --:--'
  }
  return dayjs(date).format('YYYY/MM/DD hh:mm A')
}

export const dateViewJPConverter = (date: string | undefined): string => {
  if (date === undefined) {
    return '----年--月--日 (-)'
  }
  return dayjs(date).format('YYYY年MM月DD日（ddd）')
}

export const datetimeViewJPConverter = (date: string | undefined): string => {
  if (date === undefined) {
    return '----年--月--日 (-) --:--'
  }
  return dayjs(date).format('YYYY年MM月DD日（ddd）A hh:mm')
}

export const boolViewConverter = (props: BoolViewConverterInput): string => {
  const { value, trueView, falseView, undefinedView } = props
  if (value == null) {
    return undefinedView
  }
  return value ? trueView : falseView
}

export const birthdateToAgeConverter = (
  date: string,
  today: dayjs.Dayjs
): string => {
  const thisYear = today.year()
  const birthDate = dayjs(date)
  const baseAge = thisYear - birthDate.year()
  const thisYearBirthDay = birthDate.year(thisYear)
  const age = today.isBefore(thisYearBirthDay) ? baseAge - 1 : baseAge
  return String(age)
}

export const truncateNumber = (value: number, base: number): string => {
  return String(Math.floor(value) / base)
}
