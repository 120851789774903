import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { Auth0Provider } from '@auth0/auth0-react'
import { BrowserRouter } from 'react-router-dom'
import { AuthorizedApolloProvider } from './vendors/apollo/authorized-apollo-client'

const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN as string
const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID as string

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Auth0Provider
    domain={AUTH0_DOMAIN}
    clientId={AUTH0_CLIENT_ID}
    authorizationParams={{ redirect_uri: window.location.origin }}
  >
    <BrowserRouter>
      <React.StrictMode>
        <AuthorizedApolloProvider>
          <App />
        </AuthorizedApolloProvider>
      </React.StrictMode>
    </BrowserRouter>
  </Auth0Provider>
)
