import React from 'react'
import { TextField } from '@mui/material'
import type {
  Appointment,
  AppointmentNumberHeaderKey,
} from '../../../../models/Appointment'
import { handleChangeNumber } from '../../../../utils/appointment/appointmentStateHandler'

interface NumberInputFormProps {
  label?: string
  keyName: AppointmentNumberHeaderKey
  appointment: Appointment
  setAppointment: React.Dispatch<React.SetStateAction<Appointment>>
  classString?: string
  disabled?: boolean
}

export const NumberInputForm: React.FC<NumberInputFormProps> = (
  props: NumberInputFormProps
) => {
  const { label, keyName, appointment, setAppointment, classString, disabled } =
    props

  return (
    <>
      <TextField
        id={keyName}
        label={label}
        variant="standard"
        value={appointment[keyName] ?? ''}
        className={classString}
        onChange={handleChangeNumber({
          key: keyName,
          appointment,
          setAppointment,
        })}
        inputProps={{
          inputMode: 'numeric',
          pattern: '[0-9]*',
        }}
        disabled={disabled}
      />
    </>
  )
}
