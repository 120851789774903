import type { Appointment } from '../../models/Appointment'
import type {
  CustomerInput,
  Parent as GqParent,
} from '../../vendors/graphql/generated'

export const appoToUpCusInputConverter = (props: {
  appointment: Appointment
}): CustomerInput => {
  const { appointment } = props
  const item: CustomerInput = {}

  item.customer_info = familyBlockParser(appointment)
  if (appointment.appointment_status) {
    item.appointment_status = appointment.appointment_status
  }
  if (appointment.line_resistration) {
    item.line_resistration = appointment.line_resistration
  }
  return item
}

const familyBlockParser = (appointment: Appointment): GqParent => {
  const initParent: GqParent = {}
  if (appointment.family_name) {
    initParent.family_name = appointment.family_name
  }
  if (appointment.name) {
    initParent.name = appointment.name
  }
  if (appointment.family_name_kana) {
    initParent.family_name_kana = appointment.family_name_kana
  }
  if (appointment.name_kana) {
    initParent.name_kana = appointment.name_kana
  }
  if (appointment.birth_date) {
    initParent.birth_date = appointment.birth_date
  }
  if (appointment.gender) {
    initParent.gender = appointment.gender
  }
  if (appointment.annual_income) {
    initParent.annual_income = appointment.annual_income
  }
  return initParent
}
