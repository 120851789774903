import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material'
import type {
  Customer,
  CustomerBoolHeaderKey,
} from '../../../../models/Customer'
import { handleChangeBool } from '../../../../utils/customer/customerStateHandler'

interface CustomerBooleanRadioFormProps {
  label?: string
  keyName: CustomerBoolHeaderKey
  customer: Customer
  setCustomer: React.Dispatch<React.SetStateAction<Customer>>
}

export const CustomerBooleanRadioForm: React.FC<
  CustomerBooleanRadioFormProps
> = (props: CustomerBooleanRadioFormProps) => {
  const { label, keyName, customer, setCustomer } = props
  return (
    <div className="pt-1">
      <FormControl>
        <FormLabel id={`bool-radio-${keyName}-id`}>{label}</FormLabel>
        <RadioGroup
          aria-labelledby={`bool-radio-${keyName}-id`}
          name={`bool-radio-${keyName}-group`}
          value={customer[keyName] != null ? customer[keyName] : ''}
          onChange={handleChangeBool({
            key: keyName,
            customer,
            setCustomer,
          })}
        >
          <div className="flex">
            <FormControlLabel value={''} control={<Radio />} label="未選択" />
            <FormControlLabel value={true} control={<Radio />} label="有" />
            <FormControlLabel value={false} control={<Radio />} label="無" />
          </div>
        </RadioGroup>
      </FormControl>
    </div>
  )
}
