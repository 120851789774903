import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { setContext } from '@apollo/client/link/context'
import {
  ApolloProvider,
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  HttpLink,
} from '@apollo/client'
import type { ReactNode } from 'react'
import { LinearProgress } from '@mui/material'

const stage = process.env.REACT_APP_ENV_STAGE
const AUTHO_AUTHORIZER_IDENTIFIER = process.env
  .REACT_APP_AUTH0_AUTHORIZER_IDENTIFIER as string
const REACT_APP_APPSYNC_BASEURL = process.env
  .REACT_APP_APPSYNC_BASEURL as string

interface AuthorizedApolloProviderProps {
  children: ReactNode
}

export const AuthorizedApolloProvider: React.FC<
  AuthorizedApolloProviderProps
> = ({ children }: AuthorizedApolloProviderProps) => {
  const [token, setToken] = React.useState<string>('')
  const { isLoading, getAccessTokenSilently, getAccessTokenWithPopup } =
    useAuth0()

  if (isLoading) {
    return (
      <div className="my-4">
        <LinearProgress />
      </div>
    )
  }

  const httpLink = new HttpLink({
    uri: REACT_APP_APPSYNC_BASEURL,
    fetchOptions: { credentials: 'same-origin' },
  })

  const withTokenLink = setContext(async () => {
    if (token) {
      return { auth0Token: token }
    }

    let newToken
    if (stage === 'local') {
      newToken = await getAccessTokenWithPopup({
        authorizationParams: {
          audience: AUTHO_AUTHORIZER_IDENTIFIER,
        },
      })
    } else {
      newToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: AUTHO_AUTHORIZER_IDENTIFIER,
        },
      })
    }
    setToken(newToken ?? '')
    return { auth0Token: newToken }
  })

  const authLink = setContext((_, { headers, auth0Token }) => ({
    headers: {
      ...headers,
      ...(auth0Token ? { authorization: auth0Token } : {}),
    },
  }))

  const client = new ApolloClient({
    link: ApolloLink.from([withTokenLink, authLink, httpLink]),
    cache: new InMemoryCache(),
  })

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
