import type {
  Customer,
  Parent,
  Child,
  FamilyMember,
  CompromisePointStates,
} from '../../models/Customer'
import type {
  GetCustomerQuery,
  Parent as GqParent,
  Child as GqChild,
  FamilyMember as GqFamilyMember,
  CompromisePointStates as GqCompromisePointStates,
} from '../../vendors/graphql/generated'

export const customerGraphqlConverter = (props: {
  data: GetCustomerQuery
}): Customer => {
  /*
  __typenameを除く、null要素は除く
  */
  const { data } = props

  const customer = data.getCustomer

  const item: Customer = {}

  // CustomerBlock
  if (customer.id) {
    item.id = customer.id
  }

  if (customer.meta) {
    item.meta = customer.meta
  }

  if (customer.created_at) {
    item.created_at = customer.created_at
  }

  if (customer.updated_at) {
    item.updated_at = customer.updated_at
  }

  if (customer.appointment_status) {
    item.appointment_status = customer.appointment_status
  }

  // InquiryBlock
  if (customer.inquiry_date) {
    item.inquiry_date = customer.inquiry_date
  }

  if (customer.media) {
    item.media = customer.media
  }

  if (customer.inquiry_address_ward) {
    item.inquiry_address_ward = customer.inquiry_address_ward
  }

  if (customer.inquiry_property_name) {
    item.inquiry_property_name = customer.inquiry_property_name
  }

  if (customer.inquiry_property_price) {
    item.inquiry_property_price = customer.inquiry_property_price
  }

  if (customer.inquiry_property_width) {
    item.inquiry_property_width = customer.inquiry_property_width
  }

  if (customer.residents) {
    item.residents = customer.residents
  }

  if (customer.nationality) {
    item.nationality = customer.nationality
  }

  if (customer.have_permanent_residency) {
    item.have_permanent_residency = customer.have_permanent_residency
  }

  if (customer.acquisition_expected_date) {
    item.acquisition_expected_date = customer.acquisition_expected_date
  }
  // FamilyBlock
  if (customer.customer_info) {
    item.customer_info = familyBlockParser(customer.customer_info)
  }

  if (customer.consort_info) {
    item.consort_info = familyBlockParser(customer.consort_info)
  }

  if (customer.children_info) {
    item.children_info = childrenBlockParser(customer.children_info)
  }

  if (customer.family_member_info) {
    item.family_member_info = familyMemberBlockParser(
      customer.family_member_info
    )
  }
  // AddressBlock
  if (customer.address_ward) {
    item.address_ward = customer.address_ward
  }

  if (customer.address_other) {
    item.address_other = customer.address_other
  }

  if (customer.nearest_station) {
    item.nearest_station = customer.nearest_station
  }

  if (customer.on_foot_minutes) {
    item.on_foot_minutes = customer.on_foot_minutes
  }

  if (customer.plan_to_get_married) {
    item.plan_to_get_married = customer.plan_to_get_married
  }

  if (customer.is_get_married) {
    item.is_get_married = customer.is_get_married
  }

  if (customer.pet_breeding) {
    item.pet_breeding = customer.pet_breeding
  }

  if (customer.have_vehicle) {
    item.have_vehicle = customer.have_vehicle
  }

  if (customer.parking_fee) {
    item.parking_fee = customer.parking_fee
  }

  if (customer.have_bicycle) {
    item.have_bicycle = customer.have_bicycle
  }

  // AssetBlock
  if (customer.total_customer_assets) {
    item.total_customer_assets = customer.total_customer_assets
  }

  if (customer.customer_asset) {
    item.customer_asset = customer.customer_asset
  }

  if (customer.parent_asset) {
    item.parent_asset = customer.parent_asset
  }

  if (customer.stocks_and_bonds) {
    item.stocks_and_bonds = customer.stocks_and_bonds
  }

  if (customer.other_asset) {
    item.other_asset = customer.other_asset
  }

  if (customer.insurance) {
    item.insurance = customer.insurance
  }

  if (customer.have_disease) {
    item.have_disease = customer.have_disease
  }

  if (customer.debt) {
    item.debt = customer.debt
  }

  if (customer.details) {
    item.details = customer.details
  }

  // ResidenceEnvironmentBlock
  if (customer.residence_type) {
    item.residence_type = customer.residence_type
  }

  if (customer.residence_type_other) {
    item.residence_type_other = customer.residence_type_other
  }

  if (customer.residence_appearance) {
    item.residence_appearance = customer.residence_appearance
  }

  if (customer.have_ev) {
    item.have_ev = customer.have_ev
  }

  if (customer.residence_width) {
    item.residence_width = customer.residence_width
  }

  if (customer.residence_floor_plan) {
    item.residence_floor_plan = customer.residence_floor_plan
  }

  if (customer.residence_rent) {
    item.residence_rent = customer.residence_rent
  }

  if (customer.have_residence_rent_subsidy) {
    item.have_residence_rent_subsidy = customer.have_residence_rent_subsidy
  }

  if (customer.residence_rent_subsidy) {
    item.residence_rent_subsidy = customer.residence_rent_subsidy
  }

  if (customer.residence_years) {
    item.residence_years = customer.residence_years
  }

  if (customer.residence_update_date) {
    item.residence_update_date = customer.residence_update_date
  }

  if (customer.purchase_motive) {
    item.purchase_motive = customer.purchase_motive
  }

  if (customer.when_looking_for) {
    item.when_looking_for = customer.when_looking_for
  }

  if (customer.model_experience) {
    item.model_experience = customer.model_experience
  }

  if (customer.inquiries_to_other) {
    item.inquiries_to_other = customer.inquiries_to_other
  }

  // DesireBlock
  if (customer.width_of_desire) {
    item.width_of_desire = customer.width_of_desire
  }

  if (customer.width_of_min_desire) {
    item.width_of_min_desire = customer.width_of_min_desire
  }

  if (customer.desired_floor_plan) {
    item.desired_floor_plan = customer.desired_floor_plan
  }

  if (customer.monthly_payment) {
    item.monthly_payment = customer.monthly_payment
  }

  if (customer.bonus_payment) {
    item.bonus_payment = customer.bonus_payment
  }

  if (customer.budget_max) {
    item.budget_max = customer.budget_max
  }

  if (customer.internet_search_conditions) {
    item.internet_search_conditions = customer.internet_search_conditions
  }

  if (customer.desired_area_and_station) {
    item.desired_area_and_station = customer.desired_area_and_station
  }

  // GraphBlock
  if (customer.compromise_point) {
    item.compromise_point = compromisePointParser(customer.compromise_point)
  }

  return item
}

const familyBlockParser = (parentInfo: GqParent): Parent => {
  const initParent: Parent = {}
  if (parentInfo.family_name) {
    initParent.family_name = parentInfo.family_name
  }
  if (parentInfo.name) {
    initParent.name = parentInfo.name
  }
  if (parentInfo.family_name_kana) {
    initParent.family_name_kana = parentInfo.family_name_kana
  }
  if (parentInfo.name_kana) {
    initParent.name_kana = parentInfo.name_kana
  }
  if (parentInfo.gender) {
    initParent.gender = parentInfo.gender
  }
  if (parentInfo.birth_date) {
    initParent.birth_date = parentInfo.birth_date
  }
  if (parentInfo.phone_number) {
    initParent.phone_number = parentInfo.phone_number
  }
  if (parentInfo.email) {
    initParent.email = parentInfo.email
  }
  if (parentInfo.annual_income) {
    initParent.annual_income = parentInfo.annual_income
  }
  if (parentInfo.length_of_service) {
    initParent.length_of_service = parentInfo.length_of_service
  }
  if (parentInfo.work_style) {
    initParent.work_style = parentInfo.work_style
  }
  if (parentInfo.industry_type) {
    initParent.industry_type = parentInfo.industry_type
  }
  if (parentInfo.nearest_station) {
    initParent.nearest_station = parentInfo.nearest_station
  }
  if (parentInfo.birthplace) {
    initParent.birthplace = parentInfo.birthplace
  }
  return initParent
}

const childrenBlockParser = (childrenInfo: Array<GqChild | null>): Child[] => {
  return childrenInfo.map((child) => {
    const initChild: Child = {}
    if (child == null) {
      return initChild
    }
    if (child.birth_date) {
      initChild.birth_date = child.birth_date
    }
    if (child.gender) {
      initChild.gender = child.gender
    }
    if (child.school_name) {
      initChild.school_name = child.school_name
    }
    if (child.school_area) {
      initChild.school_area = child.school_area
    }
    return initChild
  })
}

const familyMemberBlockParser = (
  familyMemberInfo: Array<GqFamilyMember | null>
): FamilyMember[] => {
  return familyMemberInfo.map((famlyMember) => {
    const initFamilyMember: FamilyMember = {}
    if (famlyMember == null) {
      return initFamilyMember
    }
    if (famlyMember.relationship) {
      initFamilyMember.relationship = famlyMember.relationship
    }
    if (famlyMember.birth_date) {
      initFamilyMember.birth_date = famlyMember.birth_date
    }
    return initFamilyMember
  })
}

const compromisePointParser = (
  compromisePoint: GqCompromisePointStates
): CompromisePointStates => {
  const item: CompromisePointStates = {}
  if (compromisePoint.sunny) {
    item.sunny = compromisePoint.sunny
  }
  if (compromisePoint.view) {
    item.view = compromisePoint.view
  }
  if (compromisePoint.one_floor) {
    item.one_floor = compromisePoint.one_floor
  }
  if (compromisePoint.north) {
    item.north = compromisePoint.north
  }
  if (compromisePoint.highway) {
    item.highway = compromisePoint.highway
  }
  if (compromisePoint.dirty) {
    item.dirty = compromisePoint.dirty
  }
  if (compromisePoint.accident) {
    item.accident = compromisePoint.accident
  }
  return item
}
