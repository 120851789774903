import React from 'react'
import { Typography, Container, Paper, Tabs, Tab } from '@mui/material'
import { useParams } from 'react-router-dom'
import type { Customer } from '../../../models/Customer'
// import type { CustomerHistory } from '../../../models/CustomerHistory'
import { CustomerChartContainer } from '../../components/customer/CustomerChartContainer'
import { CustomerChartAppointmentTable } from '../../components/customer/CustomerChartAppointmentTable'
import { CustomerChartHistoryTable } from '../../components/customer/CustomerChartHistoryTable'
// import { mockCustomerCharts } from '../../../mock/Customer'
import { useGetCustomerQuery } from '../../../vendors/graphql/generated'
import { customerGraphqlConverter } from '../../../utils/customer/customerGraphqlConverter'

const CustomerChartPage: React.FC = () => {
  const { customerId } = useParams()

  const { data } = useGetCustomerQuery({
    variables: { key: { id: customerId ?? '', meta: 'Customer' } },
  })

  const [tapState, setTapState] = React.useState(0)
  const [customer, setCustomer] = React.useState<Customer>({})

  // const [customerHistories, setCustomerHistories] = React.useState<
  //   CustomerHistory[]
  // >([])
  // mock
  // React.useEffect(() => {
  //   const searchMockCustomer = (
  //     customerCharts: CustomerChart[],
  //     customerId: string | undefined
  //   ): CustomerChart => {
  //     const emptyCustomerChart = {
  //       customer: {},
  //       appointments: [],
  //       histories: [],
  //     }
  //     const customer = customerCharts.find(
  //       (customerChart) => customerChart.customer.id === customerId
  //     )
  //     if (customer != null) {
  //       return customer
  //     } else {
  //       return emptyCustomerChart
  //     }
  //   }
  //   setCustomer(searchMockCustomer(mockCustomerCharts, customerId).customer)
  //   setAppointments(
  //     searchMockCustomer(mockCustomerCharts, customerId).appointments
  //   )
  //   setCustomerHistories(
  //     searchMockCustomer(mockCustomerCharts, customerId).histories
  //   )
  // }, [setCustomer, customerId])

  React.useEffect(() => {
    if (data) {
      console.log('data', data)
      setCustomer(customerGraphqlConverter({ data }))
    }
  }, [data])

  const handleChangeTab = (_: React.SyntheticEvent, newValue: number): void => {
    setTapState(newValue)
  }

  return (
    <>
      <div className="md:m-5">
        <Container className="md:py-4 py-1" component={Paper}>
          <Typography className="md:py-2 pt-1" variant="h5">
            顧客カルテ
          </Typography>

          <div className="">
            <Tabs value={tapState} onChange={handleChangeTab}>
              <Tab label="顧客情報" />
              <Tab label="追客履歴" />
              <Tab label="案内一覧" />
            </Tabs>
          </div>

          <div className="md:px-5">
            {tapState === 0 && (
              <CustomerChartContainer title="顧客情報" customer={customer} />
            )}

            {tapState === 1 && (
              <CustomerChartHistoryTable title="追客履歴" customer={customer} />
            )}

            {tapState === 2 && (
              <CustomerChartAppointmentTable title="案内" customer={customer} />
            )}
          </div>
        </Container>
      </div>
    </>
  )
}

export default CustomerChartPage
