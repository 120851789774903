import React from 'react'
import type { Dayjs } from 'dayjs'
import { Box, TextField } from '@mui/material'
import { MobileDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'

interface SearchDatePickersProps {
  label: string
  startDate: Dayjs | null
  endDate: Dayjs | null
  setStartDate: React.Dispatch<React.SetStateAction<Dayjs | null>>
  setEndDate: React.Dispatch<React.SetStateAction<Dayjs | null>>
  setDateCandidate?: React.Dispatch<React.SetStateAction<string>>
}

export const SearchDatePickers: React.FC<SearchDatePickersProps> = (
  props: SearchDatePickersProps
) => {
  const {
    label,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    setDateCandidate,
  } = props

  const handleStartChange = (newValue: Dayjs | null): void => {
    setStartDate(newValue)
    if (setDateCandidate != null) {
      setDateCandidate('')
    }
  }

  const handleEndChange = (newValue: Dayjs | null): void => {
    setEndDate(newValue)
    if (setDateCandidate != null) {
      setDateCandidate('')
    }
  }

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-center',
          }}
        >
          <CalendarMonthOutlinedIcon sx={{ mr: 1, my: 2 }} />
          <MobileDatePicker
            label={`${label}日時・期間開始日`}
            inputFormat="YYYY/MM/DD"
            value={startDate}
            onChange={handleStartChange}
            renderInput={(params) => <TextField {...params} />}
            closeOnSelect={true}
          />
          <Box className="mx-4 my-4 text-xl">~</Box>
          <CalendarMonthOutlinedIcon sx={{ mr: 1, my: 2 }} />
          <MobileDatePicker
            label={`${label}日時・期間終了日`}
            inputFormat="YYYY/MM/DD"
            value={endDate}
            onChange={handleEndChange}
            renderInput={(params) => <TextField {...params} />}
            closeOnSelect={true}
          />
        </Box>
      </LocalizationProvider>
    </div>
  )
}

export const SmSearchDatePickers: React.FC<SearchDatePickersProps> = (
  props: SearchDatePickersProps
) => {
  const {
    label,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    setDateCandidate,
  } = props

  const handleStartChange = (newValue: Dayjs | null): void => {
    setStartDate(newValue)
    if (setDateCandidate != null) {
      setDateCandidate('')
    }
  }

  const handleEndChange = (newValue: Dayjs | null): void => {
    setEndDate(newValue)
    if (setDateCandidate != null) {
      setDateCandidate('')
    }
  }

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-center',
          }}
        >
          <MobileDatePicker
            label={`${label}日時・期間開始日`}
            inputFormat="YYYY/MM/DD"
            value={startDate}
            onChange={handleStartChange}
            renderInput={(params) => <TextField {...params} />}
            closeOnSelect={true}
          />
          <Box className="mx-2 my-4 text-xl">~</Box>
          <MobileDatePicker
            label={`${label}日時・期間終了日`}
            inputFormat="YYYY/MM/DD"
            value={endDate}
            onChange={handleEndChange}
            renderInput={(params) => <TextField {...params} />}
            closeOnSelect={true}
          />
        </Box>
      </LocalizationProvider>
    </div>
  )
}
