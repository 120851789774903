import {
  DatePickerIcon,
  MobileDatePickerForm,
} from '../../common/editForms/DatePickersIcon'
import { TextFieldForm } from '../../common/editForms/TextFieldForm'
import { NumberInputForm } from '../../common/editForms/NumberInputForm'
import { SelectForm } from '../../common/editForms/SelectForm'
import { CustomerBooleanRadioForm } from '../../common/editForms/BooleanRadioForm'
import type { Customer, CustomerView } from '../../../../models/Customer'

interface CustomerChartResidenceEnvironmentBlockProps {
  editCustomer: Customer
  customerView: CustomerView
  setEditCustomer: React.Dispatch<React.SetStateAction<Customer>>
  editMode: boolean
}

export const CustomerChartResidenceEnvironmentBlock: React.FC<
  CustomerChartResidenceEnvironmentBlockProps
> = (props: CustomerChartResidenceEnvironmentBlockProps) => {
  const { customerView, editCustomer, setEditCustomer, editMode } = props

  const blockColor = 'bg-yellow-100'
  return (
    <>
      <div className="grid grid-cols-12 rounded-none">
        <div
          className={`col-start-1 col-span-2 relative flex items-center justify-center border-l border-b border-current ${blockColor}`}
        >
          <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
            住環境
          </div>
          <div className="py-4 px-4 text-base">
            {editMode ? (
              <SelectForm
                keyName="residence_type"
                customer={editCustomer}
                setCustomer={setEditCustomer}
                classString="pb-3"
              />
            ) : (
              customerView.residence_type
            )}
          </div>
        </div>
        <div
          className={`col-span-3 relative flex items-center justify-center border-r border-b border-current ${blockColor}`}
        >
          <div>（</div>
          {editCustomer.residence_type === 'other' ? (
            <>
              <div className="py-4 px-6 text-base">
                {editMode ? (
                  <TextFieldForm
                    keyName="residence_type_other"
                    customer={editCustomer}
                    setCustomer={setEditCustomer}
                  />
                ) : (
                  customerView.residence_type_other
                )}
              </div>
            </>
          ) : (
            <div className="mx-20"></div>
          )}
          <div>）</div>
        </div>

        <div
          className={`col-span-2 relative flex items-center justify-center border-r border-b border-current ${blockColor}`}
        >
          <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
            外見
          </div>
          <div className="py-4 px-4 text-base">
            {editMode ? (
              <SelectForm
                keyName="residence_appearance"
                customer={editCustomer}
                setCustomer={setEditCustomer}
                classString="pb-3"
              />
            ) : (
              customerView.residence_appearance
            )}
          </div>
        </div>
        <div
          className={`col-span-3 relative flex items-center justify-center border-r border-b border-current ${blockColor}`}
        >
          <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
            EV
          </div>
          <div className="py-4 px-4 text-base">
            {editMode ? (
              <CustomerBooleanRadioForm
                keyName="have_ev"
                customer={editCustomer}
                setCustomer={setEditCustomer}
              />
            ) : (
              customerView.have_ev
            )}
          </div>
        </div>
        <div
          className={`col-start-1 col-span-1 relative flex items-center justify-center border-x border-b border-current ${blockColor}`}
        >
          <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
            広さ
          </div>
          <div className="py-4 px-4 text-base">
            {editMode ? (
              <NumberInputForm
                keyName="residence_width"
                customer={editCustomer}
                setCustomer={setEditCustomer}
                classString="w-10"
              />
            ) : (
              customerView.residence_width
            )}
          </div>
          <div className="absolute flex bottom-0 right-0 py-1 px-2 font-semibold text-xs">
            ㎡
          </div>
        </div>
        <div
          className={`col-span-2 relative flex items-center justify-center border-r border-b border-current ${blockColor}`}
        >
          <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
            間取り
          </div>
          <div className="py-4 px-4 text-base">
            {editMode ? (
              <SelectForm
                keyName="residence_floor_plan"
                customer={editCustomer}
                setCustomer={setEditCustomer}
                classString="pb-3"
              />
            ) : (
              customerView.residence_floor_plan
            )}
          </div>
        </div>
        <div
          className={`col-span-2 relative flex items-center justify-center border-r border-b border-current ${blockColor}`}
        >
          <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
            家賃
          </div>
          <div className="py-4 px-4 text-base">
            {editMode ? (
              <NumberInputForm
                keyName="residence_rent"
                customer={editCustomer}
                setCustomer={setEditCustomer}
                classString="w-20"
              />
            ) : (
              customerView.residence_rent
            )}
          </div>
          <div className="absolute flex bottom-0 right-0 py-1 px-2 font-semibold text-xs">
            円
          </div>
        </div>
        <div
          className={`col-span-3 relative flex items-center justify-center border-b border-current ${blockColor}`}
        >
          <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
            家賃補助
          </div>
          <div className="py-4 px-4 text-base">
            {editMode ? (
              <CustomerBooleanRadioForm
                keyName="have_residence_rent_subsidy"
                customer={editCustomer}
                setCustomer={setEditCustomer}
              />
            ) : (
              customerView.have_residence_rent_subsidy
            )}
          </div>
        </div>
        {editCustomer.have_residence_rent_subsidy && (
          <div
            className={`col-span-1 relative flex items-center justify-center border-b border-current ${blockColor}`}
          >
            <div className="pt-6 pb-4 pl-4 pr-12 text-base">
              {editMode ? (
                <NumberInputForm
                  keyName="residence_rent_subsidy"
                  customer={editCustomer}
                  setCustomer={setEditCustomer}
                  classString="w-20"
                />
              ) : (
                customerView.residence_rent_subsidy
              )}
            </div>
            <div className="absolute flex bottom-0 right-0 py-1 px-2 font-semibold text-xs">
              円
            </div>
          </div>
        )}
        <div className={`col-span-1 border-l border-current`}></div>
        <div
          className={`col-start-1 col-span-1 relative flex items-center justify-center border-x border-current ${blockColor}`}
        >
          <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
            居住年数
          </div>
          <div className="pt-6 pb-4 px-4 text-base">
            {editMode ? (
              <NumberInputForm
                keyName="residence_years"
                customer={editCustomer}
                setCustomer={setEditCustomer}
                classString="w-10"
              />
            ) : (
              customerView.residence_years
            )}
          </div>
          <div className="absolute flex bottom-0 right-0 py-1 px-2 font-semibold text-xs">
            年
          </div>
        </div>
        <div
          className={`col-span-3 relative flex items-center justify-center border-r border-current ${blockColor}`}
        >
          <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
            更新時期
          </div>
          <div className="flex items-center pt-6 pb-4 px-4">
            {customerView.residence_update_date}
            {editMode && (
              <DatePickerIcon
                keyName="residence_update_date"
                customer={editCustomer}
                setCustomer={setEditCustomer}
              />
            )}
          </div>
        </div>
        <div
          className={`col-start-1 col-span-12 relative flex items-center justify-center border border-current ${blockColor}`}
        >
          <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
            購入動機
          </div>
          <div className="pt-6 pb-4 px-4 text-base w-full">
            {editMode ? (
              <TextFieldForm
                keyName="purchase_motive"
                customer={editCustomer}
                setCustomer={setEditCustomer}
              />
            ) : (
              customerView.purchase_motive
            )}
          </div>
        </div>
        <div
          className={`col-start-1 col-span-2 relative flex items-center justify-center border-x border-b border-current ${blockColor}`}
        >
          <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
            いつから探している
          </div>
          <div className="pt-6 pb-4 px-4 text-base w-full">
            {editMode ? (
              <TextFieldForm
                keyName="when_looking_for"
                customer={editCustomer}
                setCustomer={setEditCustomer}
              />
            ) : (
              customerView.when_looking_for
            )}
          </div>
        </div>
        <div
          className={`col-span-1 relative flex items-center justify-center border-r border-b border-current ${blockColor}`}
        >
          <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
            モデル経験
          </div>
          <div className="pt-6 pb-4 px-4 text-base">
            {editMode ? (
              <NumberInputForm
                keyName="model_experience"
                customer={editCustomer}
                setCustomer={setEditCustomer}
                classString="w-10"
              />
            ) : (
              customerView.model_experience
            )}
          </div>
          <div className="absolute flex bottom-0 right-0 py-1 px-2 font-semibold text-xs">
            回
          </div>
        </div>
        <div
          className={`col-span-3 relative flex items-center justify-center border-b border-r border-current ${blockColor}`}
        >
          <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
            他社への問い合わせ
          </div>
          <div className="pt-6 pb-4 px-4 text-base">
            {editMode ? (
              <CustomerBooleanRadioForm
                keyName="inquiries_to_other"
                customer={editCustomer}
                setCustomer={setEditCustomer}
              />
            ) : (
              customerView.inquiries_to_other
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export const SmCustomerChartResidenceEnvironmentBlock: React.FC<
  CustomerChartResidenceEnvironmentBlockProps
> = (props: CustomerChartResidenceEnvironmentBlockProps) => {
  const { customerView, editCustomer, setEditCustomer, editMode } = props

  const blockColor = 'bg-yellow-100'
  return (
    <>
      <div className="grid grid-cols-4 rounded-none">
        <div
          className={`col-start-1 col-span-2 relative flex items-center justify-center border-l border-b border-current ${blockColor}`}
        >
          <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
            住環境
          </div>
          <div className="flex items-center h-12 py-1 px-2 text-base">
            {editMode ? (
              <SelectForm
                keyName="residence_type"
                customer={editCustomer}
                setCustomer={setEditCustomer}
                classString="pb-3"
              />
            ) : (
              customerView.residence_type
            )}
          </div>
        </div>
        <div
          className={`col-span-2 relative flex items-center justify-center border-r border-b border-current ${blockColor}`}
        >
          <div>（</div>
          {editCustomer.residence_type === 'other' ? (
            <>
              <div className="flex items-center h-12 py-1 px-2 text-base">
                {editMode ? (
                  <TextFieldForm
                    keyName="residence_type_other"
                    customer={editCustomer}
                    setCustomer={setEditCustomer}
                  />
                ) : (
                  customerView.residence_type_other
                )}
              </div>
            </>
          ) : (
            <div className="mx-20"></div>
          )}
          <div>）</div>
        </div>

        <div
          className={`col-span-1 relative flex items-center justify-center border-x border-b border-current ${blockColor}`}
        >
          <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
            外見
          </div>
          <div className="flex items-center h-12 py-1 px-2 text-base">
            {editMode ? (
              <SelectForm
                keyName="residence_appearance"
                customer={editCustomer}
                setCustomer={setEditCustomer}
                classString="pb-3"
              />
            ) : (
              customerView.residence_appearance
            )}
          </div>
        </div>
        <div
          className={`col-span-3 relative flex items-center justify-center border-r border-b border-current ${blockColor}`}
        >
          <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
            EV
          </div>
          <div className="flex items-center h-10 py-1 px-2 text-base">
            {editMode ? (
              <CustomerBooleanRadioForm
                keyName="have_ev"
                customer={editCustomer}
                setCustomer={setEditCustomer}
              />
            ) : (
              customerView.have_ev
            )}
          </div>
        </div>
        <div
          className={`col-start-1 col-span-2 relative flex items-center justify-center border-x border-b border-current ${blockColor}`}
        >
          <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
            広さ
          </div>
          <div className="flex items-center h-12 py-1 px-2 text-base">
            {editMode ? (
              <NumberInputForm
                keyName="residence_width"
                customer={editCustomer}
                setCustomer={setEditCustomer}
                classString="w-10"
              />
            ) : (
              <div>{customerView.residence_width}</div>
            )}
          </div>
          <div className="absolute flex bottom-0 right-0 px-1 font-semibold text-xs">
            ㎡
          </div>
        </div>
        <div
          className={`col-start-1 col-span-2 relative flex items-center justify-center border-x border-b border-current ${blockColor}`}
        >
          <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
            間取り
          </div>
          <div className="flex items-center h-12 py-1 px-2 text-base">
            {editMode ? (
              <SelectForm
                keyName="residence_floor_plan"
                customer={editCustomer}
                setCustomer={setEditCustomer}
                classString="pb-3"
              />
            ) : (
              customerView.residence_floor_plan
            )}
          </div>
        </div>
        <div
          className={`col-span-2 relative flex items-center justify-center border-r border-y border-current ${blockColor}`}
        >
          <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
            家賃
          </div>
          <div className="flex items-center h-12 py-1 px-2 text-base">
            {editMode ? (
              <NumberInputForm
                keyName="residence_rent"
                customer={editCustomer}
                setCustomer={setEditCustomer}
                classString="w-20"
              />
            ) : (
              customerView.residence_rent
            )}
          </div>
          <div className="absolute flex bottom-0 right-0 px-1 font-semibold text-xs">
            円
          </div>
        </div>
        <div
          className={`col-start-1 col-span-3 relative flex items-center justify-center border-l border-b border-current ${blockColor}`}
        >
          <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
            家賃補助
          </div>
          <div className="flex items-center h-12 py-1 px-2 text-base">
            {editMode ? (
              <CustomerBooleanRadioForm
                keyName="have_residence_rent_subsidy"
                customer={editCustomer}
                setCustomer={setEditCustomer}
              />
            ) : (
              customerView.have_residence_rent_subsidy
            )}
          </div>
        </div>
        {editCustomer.have_residence_rent_subsidy && (
          <div
            className={`col-span-1 relative flex items-center justify-center border-r border-b border-current ${blockColor}`}
          >
            <div className="flex items-center h-12 py-1 pr-6 text-base">
              {editMode ? (
                <NumberInputForm
                  keyName="residence_rent_subsidy"
                  customer={editCustomer}
                  setCustomer={setEditCustomer}
                  classString="w-20"
                />
              ) : (
                customerView.residence_rent_subsidy
              )}
            </div>
            <div className="absolute flex bottom-0 right-0 px-1 font-semibold text-xs">
              円
            </div>
          </div>
        )}
        <div className={`col-span-1 border-l border-current`}></div>
        <div
          className={`col-start-1 col-span-2 relative flex items-center justify-center border-x border-current ${blockColor}`}
        >
          <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
            居住年数
          </div>
          <div className="flex items-center h-10 py-1 px-2 text-base">
            {editMode ? (
              <NumberInputForm
                keyName="residence_years"
                customer={editCustomer}
                setCustomer={setEditCustomer}
                classString="w-10"
              />
            ) : (
              customerView.residence_years
            )}
          </div>
          <div className="absolute flex bottom-0 right-0 px-1 font-semibold text-xs">
            年
          </div>
        </div>
        <div
          className={`col-start-1 col-span-3 relative flex items-center justify-center border-x border-t border-current ${blockColor}`}
        >
          <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
            更新時期
          </div>
          <div className="flex items-center h-24 py-1 px-4">
            {editMode ? (
              <MobileDatePickerForm
                keyName="residence_update_date"
                customer={editCustomer}
                setCustomer={setEditCustomer}
              />
            ) : (
              customerView.residence_update_date
            )}
          </div>
        </div>
        <div
          className={`col-start-1 col-span-4 relative flex items-center justify-center border border-current ${blockColor}`}
        >
          <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
            購入動機
          </div>
          <div className="pt-6 pb-4 px-4 text-base w-full">
            {editMode ? (
              <TextFieldForm
                keyName="purchase_motive"
                customer={editCustomer}
                setCustomer={setEditCustomer}
              />
            ) : (
              customerView.purchase_motive
            )}
          </div>
        </div>
        <div
          className={`col-start-1 col-span-3 relative flex items-center justify-center border-x border-b border-current ${blockColor}`}
        >
          <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
            いつから探している
          </div>
          <div className="pt-6 pb-4 px-4 text-base w-full">
            {editMode ? (
              <TextFieldForm
                keyName="when_looking_for"
                customer={editCustomer}
                setCustomer={setEditCustomer}
              />
            ) : (
              customerView.when_looking_for
            )}
          </div>
        </div>
        <div
          className={`col-span-1 relative flex items-center justify-center border-r border-b border-current ${blockColor}`}
        >
          <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
            モデル経験
          </div>
          <div className="pt-6 pb-4 px-4 text-base">
            {editMode ? (
              <NumberInputForm
                keyName="model_experience"
                customer={editCustomer}
                setCustomer={setEditCustomer}
                classString="w-10"
              />
            ) : (
              customerView.model_experience
            )}
          </div>
          <div className="absolute flex bottom-0 right-0 px-1 font-semibold text-xs">
            回
          </div>
        </div>
        <div
          className={`col-span-3 relative flex items-center justify-center border-b border-x border-current ${blockColor}`}
        >
          <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
            他社への問い合わせ
          </div>
          <div className="pt-6 pb-4 px-4 text-base">
            {editMode ? (
              <CustomerBooleanRadioForm
                keyName="inquiries_to_other"
                customer={editCustomer}
                setCustomer={setEditCustomer}
              />
            ) : (
              customerView.inquiries_to_other
            )}
          </div>
        </div>
      </div>
    </>
  )
}
