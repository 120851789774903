import type { Inquiry } from '../../models/Inquiry'
import type { Customer, Parent } from '../../models/Customer'

export const inquiryToCustomerTranslator = (props: {
  inquiry: Inquiry
}): Customer => {
  const { inquiry } = props

  const initCustomer: Customer = {}

  if (inquiry.inquiry_date) {
    initCustomer.inquiry_date = inquiry.inquiry_date
  }

  if (inquiry.nearest_station) {
    initCustomer.nearest_station = inquiry.nearest_station
  }

  if (inquiry.property_name) {
    initCustomer.inquiry_property_name = inquiry.property_name
  }

  if (inquiry.year_of_construction) {
    initCustomer.year_of_construction = inquiry.year_of_construction
  }

  if (inquiry.price) {
    initCustomer.inquiry_property_price = inquiry.price
  }

  if (inquiry.floor_plan) {
    initCustomer.inquiry_property_floor_plan = inquiry.floor_plan
  }

  if (inquiry.media) {
    initCustomer.media = inquiry.media
  }

  if (inquiry.line_resistration) {
    initCustomer.line_resistration = inquiry.line_resistration
  }

  if (inquiry.memo) {
    initCustomer.memo = inquiry.memo
  }

  if (inquiry.reaction) {
    initCustomer.reaction = inquiry.reaction
  }

  if (inquiry.appointment_status) {
    initCustomer.appointment_status = inquiry.appointment_status
  }

  const initCustomerInfo: Parent = {}
  if (inquiry.family_name) {
    initCustomerInfo.family_name = inquiry.family_name
  }
  if (inquiry.name) {
    initCustomerInfo.name = inquiry.name
  }
  if (inquiry.family_name_kana) {
    initCustomerInfo.family_name_kana = inquiry.family_name_kana
  }
  if (inquiry.name_kana) {
    initCustomerInfo.name_kana = inquiry.name_kana
  }

  if (Object.keys(initCustomerInfo).length) {
    initCustomer.customer_info = initCustomerInfo
  }

  return initCustomer
}
