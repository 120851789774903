import React from 'react'
import type { Dayjs } from 'dayjs'
import dayjs from '../../../vendors/Dayjs'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
  Link,
} from '@mui/material'
import type { SxProps } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useNavigate } from 'react-router-dom'
import LaunchIcon from '@mui/icons-material/Launch'
import DescriptionIcon from '@mui/icons-material/Description'
import ModeEditIcon from '@mui/icons-material/ModeEdit'
import EditOffIcon from '@mui/icons-material/EditOff'
import SendIcon from '@mui/icons-material/Send'
import type { Appointment } from '../../../models/Appointment'
import { appointmentStatusColorSelector } from '../../../utils/common/appointmentStatusColorSelector'
import { appointmentsParser } from '../../../utils/appointment/appointmentViewParser'
import { appointmentSearchFilter } from '../../../utils/appointment/appointmentSearchFilter'
import { AppointmentModal } from '../../components/appointment/AppointmentModal'
import { SearchInputForm } from '../../components/appointment/SearchInputForm'
import { TableCellAppointmentFields } from '../../components/appointment/TableCellAppointmentFields'
import { IndentText } from '../../components/common/IndentText'
import { datetimeFormat } from '../../../fixture/common/formOptions'
import { appointmentHeaders } from '../../../fixture/appointment/formOptions'
import { AppointmentStatusIndexView } from '../../components/inquiry/AppointmentStatusIndexView'
import { mockAppointments } from '../../../mock/Appointment'
import {
  useSearchAppointmentQuery,
  usePutAppointmentMutation,
  useUpdateCustomerMutation,
} from '../../../vendors/graphql/generated'
import type { QuerySearchAppointmentArgs } from '../../../vendors/graphql/generated'
import { appointmentGraphqlConverter } from '../../../utils/appointment/appointmentGraphqlConverter'
import { appointmentInputConverter } from '../../../utils/appointment/appointmentInputConverter'
import { appoToUpCusInputConverter } from '../../../utils/customer/appoToUpCusInputConverter'

const AppointmentPage: React.FC = () => {
  const [putAppointment] = usePutAppointmentMutation()
  const [updateCustomer] = useUpdateCustomerMutation()

  const navigate = useNavigate()

  const initAppointment = {}
  const thisStartOfWeekDay = dayjs().startOf('week')
  const thisEndOfWeekDay = thisStartOfWeekDay.add(6, 'day')

  const [appointments, setAppointments] = React.useState([] as Appointment[])
  const [inputEditAppointment, setInputEditAppointment] =
    React.useState<Appointment>(initAppointment)
  const [editModes, setEditModes] = React.useState<boolean[]>([])
  const [openModal, setOpenModal] = React.useState(false)
  const [modalAppointment, setModalAppointment] =
    React.useState<Appointment>(initAppointment)

  const [searchText, setSearchText] = React.useState<string>('')
  const [startAppointmentDate, setStartAppointmentDate] =
    React.useState<Dayjs | null>(thisStartOfWeekDay)
  const [endAppointmentDate, setEndAppointmentDate] =
    React.useState<Dayjs | null>(thisEndOfWeekDay)
  const [searchAppointmentStatus, setSearchAppointmentStatus] =
    React.useState<string>('')

  const initSearchAppointmentArgs = {
    conditions: {
      start_date: thisStartOfWeekDay?.format(datetimeFormat),
      end_date: thisEndOfWeekDay?.format(datetimeFormat),
    },
    options: {},
  }
  const [searchAppointmentArgs, setSearchAppointmentArgs] =
    React.useState<QuerySearchAppointmentArgs>(initSearchAppointmentArgs)
  const { data, refetch } = useSearchAppointmentQuery({
    variables: searchAppointmentArgs,
  })

  const handleRefetch = async (): Promise<void> => {
    await refetch()
  }

  const handleCallApi = async (): Promise<void> => {
    console.log(startAppointmentDate, 'startAppointmentDate')
    console.log(endAppointmentDate, 'endAppointmentDate')
    setSearchAppointmentArgs({
      conditions: {
        start_date: startAppointmentDate?.format(datetimeFormat),
        end_date: endAppointmentDate?.format(datetimeFormat),
      },
      options: {},
    })
    await refetch()
    setEditModes(Array(appointments.length).fill(false))
  }

  const openModalHandler = (appointment: Appointment): void => {
    setModalAppointment(appointment)
    setOpenModal(true)
  }
  const toggleEditHandler = (
    appointment: Appointment,
    index: number,
    editModes: boolean[]
  ): void => {
    setInputEditAppointment(appointment)
    resetEditModes(index, editModes)
  }

  const handleUpdateAppointment = async (
    index: number,
    editModes: boolean[]
  ): Promise<void> => {
    console.log('updateAppointment param: ', inputEditAppointment)
    const inputAppointment = appointmentInputConverter({
      appointment: inputEditAppointment,
    })
    const key = {
      id: inputEditAppointment.id ?? '',
      meta: inputEditAppointment.meta ?? '',
    }
    const inputUpdateCustomer = appoToUpCusInputConverter({
      appointment: inputEditAppointment,
    })
    const updateCustomerKey = {
      id: inputEditAppointment.customer_id ?? '',
      meta: 'Customer',
    }
    await putAppointment({ variables: { input: inputAppointment, key } })
    await updateCustomer({
      variables: {
        input: inputUpdateCustomer,
        key: updateCustomerKey,
        type: 'appointment',
      },
    })
    setInputEditAppointment(initAppointment)
    resetEditModes(index, editModes)
    setTimeout(handleRefetch, 500)
  }

  const resetEditModes = (index: number, editModes: boolean[]): void => {
    const toggleEditBool = !editModes[index]
    editModes.fill(false)
    editModes[index] = toggleEditBool
    setEditModes([...editModes])
  }
  const handleClose = (): void => {
    setOpenModal(false)
  }

  React.useEffect(() => {
    if (data) {
      const appointments = appointmentGraphqlConverter({
        data,
      })
      const filteredInquiries = appointmentSearchFilter({
        appointments,
        filterOption: {
          searchText,
          searchAppointmentStatus,
        },
      })
      setAppointments(filteredInquiries)
      setEditModes(Array(mockAppointments.length).fill(false))
    }
  }, [data, searchText, searchAppointmentStatus])

  const title = '案内一覧'

  const appointmentViews = React.useMemo(
    () =>
      appointmentsParser({
        appointments,
      }),
    [appointments]
  )
  return (
    <>
      <AppointmentModal
        open={openModal}
        handleClose={handleClose}
        appointment={modalAppointment}
        handleRefetch={handleRefetch}
      />
      <SearchInputForm
        searchText={searchText}
        setSearchText={setSearchText}
        startDate={startAppointmentDate}
        setStartDate={setStartAppointmentDate}
        endDate={endAppointmentDate}
        setEndDate={setEndAppointmentDate}
        searchAppointmentStatus={searchAppointmentStatus}
        setSearchAppointmentStatus={setSearchAppointmentStatus}
        handleCallApi={handleCallApi}
      />
      <Paper className="md:m-5 md:py-4 m-2 py-2">
        <div className="my-2 md:mx-6 flex justify-between items-center">
          <div className="flex justify-start items-center">
            <Typography className="px-3 py-2" variant="h6">
              {title}
            </Typography>
          </div>
          <div className="mr-10">
            <AppointmentStatusIndexView />
          </div>
        </div>
        <TableContainer className="max-w-full max-h-full">
          <Table
            sx={{
              marginY: 2,
              marginX: 2,
              // width: 700,
              '@media screen and (min-width:768px)': {
                // width: 1025,
                marginX: 5,
              },
            }}
            // padding="none"
            size="small"
            aria-label="simple table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell sx={{ border: 0 }} align="center"></TableCell>
                {appointmentHeaders.map((header, headerIndex) => {
                  let headerStyle: SxProps = {
                    ...header.headSx,
                    borderTop: 1,
                    borderRight: 1,
                    borderBottom: 1,
                  }
                  if (headerIndex === 0) {
                    headerStyle = {
                      ...headerStyle,
                      borderLeft: 1,
                    }
                  } else {
                    headerStyle = {
                      ...headerStyle,
                      borderLeft: 0,
                    }
                  }
                  return (
                    <TableCell
                      sx={headerStyle}
                      align="center"
                      key={headerIndex}
                    >
                      {IndentText(header.name)}
                    </TableCell>
                  )
                })}
                <TableCell sx={{ border: 0 }} align="center"></TableCell>
                <TableCell sx={{ border: 0 }} align="center"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                {appointments.map((appointment, appointmentIndex) => (
                  <TableRow key={appointmentIndex}>
                    <TableCell sx={{ px: 0, border: 0 }} align="center">
                      <div className="flex">
                        <IconButton
                          onClick={() => {
                            toggleEditHandler(
                              appointment,
                              appointmentIndex,
                              editModes
                            )
                          }}
                        >
                          {editModes[appointmentIndex] ? (
                            <EditOffIcon />
                          ) : (
                            <ModeEditIcon />
                          )}
                        </IconButton>
                        {editModes[appointmentIndex] && (
                          <IconButton
                            onClick={async () => {
                              await handleUpdateAppointment(
                                appointmentIndex,
                                editModes
                              )
                            }}
                          >
                            <SendIcon />
                          </IconButton>
                        )}
                      </div>
                    </TableCell>
                    {appointmentHeaders.map((header, bodyIndex) => {
                      const appointmentView = appointmentViews[appointmentIndex]
                      let bodyStyle: SxProps = {
                        whiteSpace: 'nowrap',
                        borderBottom: 1,
                        borderRight: 1,
                      }
                      if (bodyIndex === 0) {
                        bodyStyle = {
                          ...bodyStyle,
                          borderLeft: 1,
                        }
                      } else {
                        bodyStyle = {
                          ...bodyStyle,
                          borderLeft: 0,
                        }
                      }
                      return (
                        <TableCell
                          sx={bodyStyle}
                          align="center"
                          key={`${appointmentIndex}-${header.key}`}
                          className={appointmentStatusColorSelector(
                            appointment.appointment_status
                          )}
                          onDoubleClick={() => {
                            !editModes[appointmentIndex] &&
                              toggleEditHandler(
                                appointment,
                                appointmentIndex,
                                editModes
                              )
                          }}
                        >
                          {editModes[appointmentIndex] ? (
                            <TableCellAppointmentFields
                              header={header}
                              appointment={inputEditAppointment}
                              setAppointment={setInputEditAppointment}
                            />
                          ) : header.key === 'full_name' ? (
                            <Link
                              underline="none"
                              onClick={() => {
                                navigate(
                                  `/customer/${
                                    appointment.customer_id
                                      ? appointment.customer_id
                                      : ''
                                  }`
                                )
                              }}
                              sx={{ cursor: 'pointer' }}
                            >
                              {IndentText(appointmentView[header.key])}
                            </Link>
                          ) : (
                            <div>{IndentText(appointmentView[header.key])}</div>
                          )}
                        </TableCell>
                      )
                    })}
                    <TableCell sx={{ border: 0 }} align="center">
                      <IconButton
                        onClick={() => {
                          openModalHandler(appointment)
                        }}
                      >
                        <LaunchIcon sx={{}} />
                      </IconButton>
                    </TableCell>
                    <TableCell sx={{ border: 0 }} align="center">
                      <IconButton
                        onClick={() => {
                          navigate(
                            `/customer/${
                              appointment.customer_id
                                ? appointment.customer_id
                                : ''
                            }`
                          )
                        }}
                      >
                        <DescriptionIcon sx={{}} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </LocalizationProvider>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  )
}

export default AppointmentPage
