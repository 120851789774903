import type {
  Appointment,
  AppointmentSelectHeaderKey,
} from '../../models/Appointment'
// import type { Dayjs } from 'dayjs'
// import dayjs from '../../vendors/Dayjs'

interface FilterOption {
  searchText: string
  // startAppointmentDate: Dayjs | null
  // endAppointmentDate: Dayjs | null
  searchAppointmentStatus: string
}

interface AppointmentSearchFilter {
  appointments: Appointment[]
  filterOption: FilterOption
}

const searchTextSelector = (
  searchText: string,
  appointment: Appointment
): boolean => {
  if (searchText === '') {
    return true
  }

  let names = ''
  names += String(appointment.family_name)
  names += String(appointment.name)
  names += String(appointment.family_name_kana)
  names += String(appointment.name_kana)
  if (names.includes(searchText)) {
    return true
  }

  return false
}

// const appointmentDateFilter = (
//   startAppointmentDate: Dayjs | null,
//   endAppointmentDate: Dayjs | null,
//   appointment: Appointment
// ): boolean => {
//   if (startAppointmentDate == null && endAppointmentDate == null) {
//     return true
//   }

//   const appointmentDate = appointment.appointment_date
//   if (appointmentDate == null) {
//     return false
//   }
//   const appointmentDayjs = dayjs(appointmentDate)

//   if (startAppointmentDate == null && endAppointmentDate != null) {
//     if (appointmentDayjs < endAppointmentDate.add(1, 'day')) {
//       return true
//     }
//   }

//   if (startAppointmentDate != null && endAppointmentDate == null) {
//     if (startAppointmentDate <= appointmentDayjs) {
//       return true
//     }
//   }

//   if (startAppointmentDate != null && endAppointmentDate != null) {
//     if (
//       startAppointmentDate <= appointmentDayjs &&
//       appointmentDayjs < endAppointmentDate.add(1, 'day')
//     ) {
//       return true
//     }
//   }
//   return false
// }

const searchSelectFilter = (
  searchSelectedValue: string,
  appointment: Appointment,
  key: AppointmentSelectHeaderKey
): boolean => {
  if (searchSelectedValue === '' || searchSelectedValue === appointment[key]) {
    return true
  }
  return false
}

export const appointmentSearchFilter = (
  props: AppointmentSearchFilter
): Appointment[] => {
  const { appointments, filterOption } = props
  return appointments.filter(
    (appointment) =>
      searchTextSelector(filterOption.searchText, appointment) &&
      searchSelectFilter(
        filterOption.searchAppointmentStatus,
        appointment,
        'appointment_status'
      )
    // appointmentDateFilter(
    //   filterOption.startAppointmentDate,
    //   filterOption.endAppointmentDate,
    //   appointment
    // )
  )
}
