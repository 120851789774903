import { Select, MenuItem, FormControl, InputLabel } from '@mui/material'
import type { SxProps } from '@mui/material'
import type {
  Inquiry,
  InquirySelectHeaderKey,
} from '../../../../models/Inquiry'
import { menuItemsDict } from '../../../../fixture/common/formOptions'
import { handleChangeSelect } from '../../../../utils/inquiry/inquiryStateHandler'

interface SelectFormProps {
  label?: string
  keyName: InquirySelectHeaderKey
  inquiry: Inquiry
  setInquiry: React.Dispatch<React.SetStateAction<Inquiry>>
  sx?: SxProps
  classString?: string
  disabled?: boolean
}

export const SelectForm: React.FC<SelectFormProps> = (
  props: SelectFormProps
) => {
  const { label, keyName, inquiry, setInquiry, classString, sx, disabled } =
    props
  return (
    <div className={classString}>
      <FormControl sx={sx} disabled={disabled}>
        <InputLabel id={`select-${keyName}-labelId`}>{label}</InputLabel>
        <Select
          id={`select-${keyName}-id`}
          labelId={`select-${keyName}-labelId`}
          variant="standard"
          value={inquiry[keyName] != null ? (inquiry[keyName] as string) : ''}
          onChange={handleChangeSelect({
            key: keyName,
            inquiry,
            setInquiry,
          })}
        >
          <MenuItem value="">
            <em>未選択</em>
          </MenuItem>
          {menuItemsDict[keyName].map((menuItem, menui) => (
            <MenuItem value={menuItem.key} key={`menui-${menui}`}>
              {menuItem.value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}
