import type { Gender } from '../../models/SelectCandidate'

export const toggleEditHandler = (
  editMode: boolean,
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>
): void => {
  setEditMode(!editMode)
}

export const genderColorStringCreater = (
  gender: Gender | undefined
): string => {
  if (gender === 'male') {
    return 'bg-cyan-100'
  } else if (gender === 'female') {
    return 'bg-pink-300'
  } else {
    return ''
  }
}
