import React from 'react'
import { TextField } from '@mui/material'
import type { SxProps } from '@mui/material'
import type { Inquiry, InquiryTextHeaderKey } from '../../../../models/Inquiry'
import { handleChangeText } from '../../../../utils/inquiry/inquiryStateHandler'

interface TextFieldFormProps {
  label?: string
  keyName: InquiryTextHeaderKey
  inquiry: Inquiry
  setInquiry: React.Dispatch<React.SetStateAction<Inquiry>>
  sx?: SxProps
  disabled?: boolean
}

export const TextFieldForm: React.FC<TextFieldFormProps> = (
  props: TextFieldFormProps
) => {
  const { label, keyName, inquiry, setInquiry, sx, disabled } = props

  return (
    <>
      <TextField
        fullWidth
        id={keyName}
        label={label}
        variant="standard"
        value={inquiry[keyName] != null ? inquiry[keyName] : ''}
        sx={sx}
        onChange={handleChangeText({
          key: keyName,
          inquiry,
          setInquiry,
        })}
        disabled={disabled}
      />
    </>
  )
}
