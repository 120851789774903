import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material'
import type {
  Appointment,
  AppointmentBoolHeaderKey,
} from '../../../../models/Appointment'
import { handleChangeBool } from '../../../../utils/appointment/appointmentStateHandler'

interface BooleanRadioFormProps {
  label?: string
  keyName: AppointmentBoolHeaderKey
  appointment: Appointment
  setAppointment: React.Dispatch<React.SetStateAction<Appointment>>
  undefinedValue?: string
  trueValue?: string
  falseValue?: string
  disabled?: boolean
}

export const BooleanRadioForm: React.FC<BooleanRadioFormProps> = (
  props: BooleanRadioFormProps
) => {
  const {
    label,
    keyName,
    appointment,
    setAppointment,
    undefinedValue,
    trueValue,
    falseValue,
    disabled,
  } = props
  return (
    <div className="pt-1">
      <FormControl disabled={disabled}>
        <FormLabel id={`bool-radio-${keyName}-id`}>{label}</FormLabel>
        <RadioGroup
          aria-labelledby={`bool-radio-${keyName}-id`}
          name={`bool-radio-${keyName}-group`}
          value={appointment[keyName] != null ? appointment[keyName] : ''}
          onChange={handleChangeBool({
            key: keyName,
            appointment,
            setAppointment,
          })}
        >
          <div className="flex">
            <FormControlLabel
              value={''}
              control={<Radio />}
              label={undefinedValue ?? '未選択'}
            />
            <FormControlLabel
              value={true}
              control={<Radio />}
              label={trueValue ?? '有'}
            />
            <FormControlLabel
              value={false}
              control={<Radio />}
              label={falseValue ?? '無'}
            />
          </div>
        </RadioGroup>
      </FormControl>
    </div>
  )
}
