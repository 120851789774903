import dayjs from '../../vendors/Dayjs'
import type { Dayjs } from 'dayjs'
import type { Candidate } from '../../models/Common'

interface DateCandidateGeneratorInput {
  startWeekIndex: number
  endWeekIndex: number
}

export const dateCandidatesGenerator = (
  props: DateCandidateGeneratorInput
): Candidate[] => {
  const { startWeekIndex, endWeekIndex } = props

  const today = dayjs()
  const thisStartOfWeekDay = today.startOf('week')

  const candidates = []
  for (let count = startWeekIndex; count <= endWeekIndex; count++) {
    const startOfWeekDay = thisStartOfWeekDay.add(count, 'week')
    const candidate = dateCandidateGenerator(startOfWeekDay)
    candidates.push(candidate)
  }
  return candidates.reverse()
}

const dateCandidateGenerator = (startOfWeekDay: Dayjs): Candidate => {
  const endOfWeekDay = startOfWeekDay.add(6, 'day')
  return {
    key: startOfWeekDay.format('YYYY-MM-DD'),
    value: `${startOfWeekDay.format('YYYY/MM/DD')} ~ ${endOfWeekDay.format(
      'YYYY/MM/DD'
    )}`,
  }
}
