import type { Customer, ChildView } from '../../../../models/Customer'
import { TextFieldChildInfoForm } from '../../common/editForms/TextFieldForm'
import {
  DatePickerChildInfoIcon,
  MobileDatePickerChildInfoForm,
} from '../../common/editForms/DatePickersIcon'
import { SelectChildInfoForm } from '../../common/editForms/SelectForm'
import AddBoxIcon from '@mui/icons-material/AddBox'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import { IconButton } from '@mui/material'

interface ChildrenFormProps {
  childrenView: ChildView[] | undefined
  customerChart: Customer
  setCustomer: React.Dispatch<React.SetStateAction<Customer>>
  editMode: boolean
}

interface ChildFormProps {
  childView: ChildView
  customerChart: Customer
  setCustomer: React.Dispatch<React.SetStateAction<Customer>>
  editMode: boolean
  childIndex: number
}

interface handleAddRemoveChildProps {
  customerChart: Customer
  setCustomer: React.Dispatch<React.SetStateAction<Customer>>
  childIndex: number
}

const handleAddChild = (props: handleAddRemoveChildProps): void => {
  const { customerChart, setCustomer, childIndex } = props
  const children = customerChart.children_info
  if (children) {
    children?.splice(childIndex + 1, 0, {})
  } else {
    customerChart.children_info = [{}]
  }
  console.log('customerChart!!', customerChart)
  setCustomer({ ...customerChart })
}

const handleRemoveChild = (props: handleAddRemoveChildProps): void => {
  const { customerChart, setCustomer, childIndex } = props
  const children = customerChart.children_info
  children?.splice(childIndex, 1)
  setCustomer({ ...customerChart })
}

export const ChildrenForm: React.FC<ChildrenFormProps> = (
  props: ChildrenFormProps
) => {
  const { childrenView, customerChart, setCustomer, editMode } = props
  const childrenInfo = customerChart.children_info
  const familyMemberInfo = customerChart.family_member_info
  const isBorderBottom =
    childrenInfo?.length === 0 && familyMemberInfo?.length === 0
      ? ''
      : 'border-b'

  return (
    <>
      {childrenInfo &&
        childrenView &&
        childrenInfo.map((_, index) => (
          <ChildForm
            key={index}
            childView={childrenView[index]}
            customerChart={customerChart}
            setCustomer={setCustomer}
            editMode={editMode}
            childIndex={index}
          />
        ))}
      {editMode &&
        (childrenInfo == null ||
          childrenView == null ||
          childrenInfo.length === 0) && (
          <>
            <div
              className={`col-start-1 col-span-1 py-2 flex items-center justify-center border-current ${isBorderBottom}`}
            >
              <IconButton
                onClick={() => {
                  handleAddChild({
                    customerChart,
                    setCustomer,
                    childIndex: 0,
                  })
                }}
              >
                <AddBoxIcon />
              </IconButton>
            </div>
            <div
              className={`col-span-2 py-2 flex items-center justify-center border-current ${isBorderBottom}`}
            >
              <div>子供を追加する</div>
            </div>
            <div
              className={`col-span-1 border-current ${isBorderBottom}`}
            ></div>
          </>
        )}
    </>
  )
}

const ChildForm: React.FC<ChildFormProps> = (props: ChildFormProps) => {
  const { childView, customerChart, setCustomer, editMode, childIndex } = props

  return (
    <>
      <div className="col-start-1 col-span-2 border-x border-b border-current bg-orange-300">
        <div className="relative py-1 px-4 border-b border-current">
          <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
            子供
          </div>
          <div className="flex items-center justify-center h-10 py-4 px-4 text-base">
            {childView.age}
          </div>
          <div className="absolute flex bottom-0 right-1 py-1 px-2 font-semibold text-xs">
            歳
          </div>
          <div className="absolute flex top-1 right-1 py-1 px-2 font-semibold text-xs">
            {editMode && (
              <DatePickerChildInfoIcon
                keyName1="children_info"
                keyName2="birth_date"
                childIndex={childIndex}
                customer={customerChart}
                setCustomer={setCustomer}
              />
            )}
          </div>
        </div>
        <div className="relative py-1 px-4">
          <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
            性別
          </div>
          <div className="h-10 flex items-center justify-center py-4 px-4 text-base">
            {editMode ? (
              <SelectChildInfoForm
                keyName1="children_info"
                keyName2="gender"
                childIndex={childIndex}
                customer={customerChart}
                setCustomer={setCustomer}
                classString="pb-4"
              />
            ) : (
              childView.gender
            )}
          </div>
        </div>
      </div>
      <div className="relative flex items-center justify-center col-span-3 border-r border-b border-current bg-orange-300">
        <div className="absolute top-1 left-1 py-1 px-2 font-semibold text-xs">
          学校
        </div>
        <div className="py-4 px-4 text-base">
          {editMode ? (
            <TextFieldChildInfoForm
              keyName1="children_info"
              keyName2="school_name"
              childIndex={childIndex}
              customer={customerChart}
              setCustomer={setCustomer}
              sx={{ px: 2 }}
            />
          ) : (
            childView.school_name
          )}
        </div>
      </div>
      <div className="relative flex items-center justify-center col-span-4 border-r border-b border-current bg-orange-300">
        <div className="absolute top-1 left-1 py-1 px-2 font-semibold text-xs">
          学区
        </div>
        <div className="py-4 px-4 text-base">
          {editMode ? (
            <TextFieldChildInfoForm
              keyName1="children_info"
              keyName2="school_area"
              childIndex={childIndex}
              customer={customerChart}
              setCustomer={setCustomer}
              sx={{ px: 6 }}
            />
          ) : (
            childView.school_area
          )}
        </div>
      </div>
      {editMode && (
        <div className="col-span-1 flex items-center justify-center">
          <IconButton
            onClick={() => {
              handleAddChild({ customerChart, setCustomer, childIndex })
            }}
          >
            <AddBoxIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              handleRemoveChild({ customerChart, setCustomer, childIndex })
            }}
          >
            <RemoveCircleOutlineIcon />
          </IconButton>
        </div>
      )}
    </>
  )
}

export const SmChildrenForm: React.FC<ChildrenFormProps> = (
  props: ChildrenFormProps
) => {
  const { childrenView, customerChart, setCustomer, editMode } = props
  const childrenInfo = customerChart.children_info
  const familyMemberInfo = customerChart.family_member_info
  const isBorderBottom =
    childrenInfo?.length === 0 && familyMemberInfo?.length === 0
      ? ''
      : 'border-b'

  return (
    <>
      {childrenInfo &&
        childrenView &&
        childrenInfo.map((_, index) => (
          <SmChildForm
            key={index}
            childView={childrenView[index]}
            customerChart={customerChart}
            setCustomer={setCustomer}
            editMode={editMode}
            childIndex={index}
          />
        ))}
      {editMode &&
        (childrenInfo == null ||
          childrenView == null ||
          childrenInfo.length === 0) && (
          <>
            <div
              className={`col-start-1 col-span-1 py-2 flex items-center justify-center border-current ${isBorderBottom}`}
            >
              <IconButton
                onClick={() => {
                  handleAddChild({
                    customerChart,
                    setCustomer,
                    childIndex: 0,
                  })
                }}
              >
                <AddBoxIcon />
              </IconButton>
            </div>
            <div
              className={`col-span-2 py-2 flex items-center justify-center border-current ${isBorderBottom}`}
            >
              <div>子供を追加する</div>
            </div>
            <div
              className={`col-span-1 border-current ${isBorderBottom}`}
            ></div>
          </>
        )}
    </>
  )
}

const SmChildForm: React.FC<ChildFormProps> = (props: ChildFormProps) => {
  const { childView, customerChart, setCustomer, editMode, childIndex } = props

  return (
    <>
      <div className="relative col-start-1 col-span-2 border-x border-b border-current bg-orange-300">
        <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
          子供
        </div>
        {editMode ? (
          <>
            <div className="font-semibold text-xs">
              {editMode && (
                <MobileDatePickerChildInfoForm
                  keyName1="children_info"
                  keyName2="birth_date"
                  childIndex={childIndex}
                  customer={customerChart}
                  setCustomer={setCustomer}
                />
              )}
            </div>
            <div className="absolute bottom-0 right-0 px-1 font-semibold text-xs">
              生年月日
            </div>
          </>
        ) : (
          <>
            <div className="flex items-center justify-center h-10 py-6 px-4 text-base">
              {childView.age}
            </div>
            <div className="absolute flex bottom-0 right-0 px-1 font-semibold text-xs">
              歳
            </div>
          </>
        )}
      </div>
      <div className="relative col-span-1 border-r border-b border-current bg-orange-300">
        <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
          性別
        </div>
        <div className="h-10 flex items-center justify-center pt-4 px-4 text-base">
          {editMode ? (
            <SelectChildInfoForm
              keyName1="children_info"
              keyName2="gender"
              childIndex={childIndex}
              customer={customerChart}
              setCustomer={setCustomer}
              classString="pb-4"
            />
          ) : (
            childView.gender
          )}
        </div>
      </div>
      <div className="relative flex items-center justify-center col-span-3 border-x border-b border-current bg-orange-300">
        <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
          学校
        </div>
        <div className="py-4 px-4 text-base">
          {editMode ? (
            <TextFieldChildInfoForm
              keyName1="children_info"
              keyName2="school_name"
              childIndex={childIndex}
              customer={customerChart}
              setCustomer={setCustomer}
              sx={{ px: 2 }}
            />
          ) : (
            childView.school_name
          )}
        </div>
      </div>
      <div className="relative flex items-center justify-center col-span-3 border-x border-b border-current bg-orange-300">
        <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
          学区
        </div>
        <div className="py-4 px-4 text-base">
          {editMode ? (
            <TextFieldChildInfoForm
              keyName1="children_info"
              keyName2="school_area"
              childIndex={childIndex}
              customer={customerChart}
              setCustomer={setCustomer}
              sx={{ px: 6 }}
            />
          ) : (
            childView.school_area
          )}
        </div>
      </div>
      {editMode && (
        <div className="col-span-1 flex items-center justify-center">
          <IconButton
            onClick={() => {
              handleAddChild({ customerChart, setCustomer, childIndex })
            }}
          >
            <AddBoxIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              handleRemoveChild({ customerChart, setCustomer, childIndex })
            }}
          >
            <RemoveCircleOutlineIcon />
          </IconButton>
        </div>
      )}
    </>
  )
}
