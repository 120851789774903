import React from 'react'
import { TextField } from '@mui/material'
import type {
  Appointment,
  AppointmentHeader,
  AppointmentTextHeaderKey,
  AppointmentNumberHeaderKey,
  AppointmentDateHeaderKey,
  AppointmentSelectHeaderKey,
  AppointmentBoolHeaderKey,
} from '../../../models/Appointment'
import { DateTimePicker, DatePicker } from '@mui/x-date-pickers'
import dayjs from '../../../vendors/Dayjs'
import {
  datetimeViewJPConverter,
  dateViewJPConverter,
} from '../../../utils/common/stringParser'
import {
  numberInputs,
  selectInputs,
  dateInputs,
  cellTextInputs,
  boolInputs,
} from '../../../fixture/appointment/formOptions'
import {
  handleChangeText,
  handleChangeNumber,
  handleChangeDate,
  handleChangeDateTime,
} from '../../../utils/appointment/appointmentStateHandler'
import { BooleanRadioForm } from './editForms/BooleanRadioForm'
import { SelectForm } from './editForms/SelectForm'

interface TableCellAppointmentFieldsProps {
  header: AppointmentHeader
  appointment: Appointment
  setAppointment: React.Dispatch<React.SetStateAction<Appointment>>
}

export const TableCellAppointmentFields: React.FC<
  TableCellAppointmentFieldsProps
> = (props: TableCellAppointmentFieldsProps) => {
  const { header, appointment, setAppointment } = props

  return (
    <>
      {['full_name'].includes(header.key) && (
        <div className="flex justify-start items-center">
          <div>
            <div className="w-36 flex">
              <TextField
                id={'family_name'}
                label={'名字'}
                variant="standard"
                value={appointment.family_name ?? ''}
                sx={{
                  mr: 1,
                }}
                onChange={handleChangeText({
                  key: 'family_name',
                  appointment,
                  setAppointment,
                })}
              />
              <TextField
                id={'name'}
                label={'名前'}
                variant="standard"
                value={appointment.name ?? ''}
                onChange={handleChangeText({
                  key: 'name',
                  appointment,
                  setAppointment,
                })}
              />
            </div>
            <div className="w-36 flex">
              <TextField
                id={'family_name_kana'}
                label={'名字カナ'}
                variant="standard"
                value={appointment.family_name_kana ?? ''}
                sx={{
                  mr: 1,
                }}
                onChange={handleChangeText({
                  key: 'family_name_kana',
                  appointment,
                  setAppointment,
                })}
              />
              <TextField
                id={'name_kana'}
                label={'名前カナ'}
                variant="standard"
                value={appointment.name_kana ?? ''}
                onChange={handleChangeText({
                  key: 'name_kana',
                  appointment,
                  setAppointment,
                })}
              />
            </div>
          </div>
          <div className="ml-2">
            <SelectForm
              label="案内済客フラグ"
              keyName="appointment_status"
              appointment={appointment}
              setAppointment={setAppointment}
              sx={{ minWidth: 150 }}
            />
          </div>
        </div>
      )}
      {header.key === 'age_and_gender' && (
        <div>
          <div className="flex">
            <div>
              {appointment.birth_date &&
                dateViewJPConverter(appointment.birth_date ?? '')}
            </div>
            <DatePicker
              label={header.name}
              openTo="year"
              value={
                appointment.birth_date != null
                  ? dayjs(appointment.birth_date)
                  : null
              }
              onChange={handleChangeDate({
                key: 'birth_date',
                appointment,
                setAppointment,
              })}
              renderInput={({ inputRef, inputProps, InputProps }) => (
                <div className="flex items-center">
                  <input ref={inputRef} {...inputProps} className="w-0" />
                  {InputProps?.endAdornment}
                </div>
              )}
              closeOnSelect={true}
              disableMaskedInput={true}
            />
          </div>
          <div className="mt-4">
            <SelectForm
              label="性別"
              keyName="gender"
              appointment={appointment}
              setAppointment={setAppointment}
              sx={{ minWidth: 150 }}
            />
          </div>
        </div>
      )}
      {header.key === 'inquiry_price_and_floor_plan' && (
        <div>
          <div className="">
            <TextField
              id={'inquiry_price'}
              label={'　価格(円)'}
              variant="standard"
              value={
                appointment.inquiry_price != null
                  ? appointment.inquiry_price
                  : ''
              }
              onChange={handleChangeNumber({
                key: 'inquiry_price',
                appointment,
                setAppointment,
              })}
              inputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
              }}
            />
          </div>
          <div className="mt-2">
            <SelectForm
              label="間取り"
              keyName="floor_plan"
              appointment={appointment}
              setAppointment={setAppointment}
              sx={{ minWidth: 150 }}
            />
          </div>
        </div>
      )}
      {cellTextInputs.includes(header.key) && (
        <TextField
          sx={{ width: 150 }}
          id={header.key}
          label={header.name}
          variant="standard"
          value={appointment[header.key] != null ? appointment[header.key] : ''}
          onChange={handleChangeText({
            key: `${header.key}` as AppointmentTextHeaderKey,
            appointment,
            setAppointment,
          })}
        />
      )}
      {dateInputs.includes(header.key) && (
        <div className="flex">
          <div>
            {appointment[header.key] &&
              datetimeViewJPConverter(
                (appointment[header.key] as string) ?? ''
              )}
          </div>
          <DateTimePicker
            label={header.name}
            openTo="day"
            value={
              appointment[header.key] != null
                ? dayjs(appointment[header.key] as string)
                : null
            }
            onChange={handleChangeDateTime({
              key: `${header.key}` as AppointmentDateHeaderKey,
              appointment,
              setAppointment,
            })}
            renderInput={({ inputRef, inputProps, InputProps }) => (
              <div className="flex items-center">
                <input ref={inputRef} {...inputProps} className="w-0" />
                {InputProps?.endAdornment}
              </div>
            )}
            closeOnSelect={true}
            disableMaskedInput={true}
          />
        </div>
      )}
      {boolInputs.includes(header.key) && (
        <div>
          <BooleanRadioForm
            label={header.name}
            keyName={header.key as AppointmentBoolHeaderKey}
            appointment={appointment}
            setAppointment={setAppointment}
          />
        </div>
      )}
      {selectInputs.includes(header.key) && (
        <div className="flex">
          <SelectForm
            label={header.name}
            keyName={header.key as AppointmentSelectHeaderKey}
            appointment={appointment}
            setAppointment={setAppointment}
            sx={{ minWidth: 100 }}
          />
        </div>
      )}
      {numberInputs.includes(header.key) && (
        <TextField
          sx={{ width: 150 }}
          id={header.key}
          label={header.editLabel ?? header.name}
          variant="standard"
          value={appointment[header.key] != null ? appointment[header.key] : ''}
          onChange={handleChangeNumber({
            key: `${header.key}` as AppointmentNumberHeaderKey,
            appointment,
            setAppointment,
          })}
          inputProps={{
            inputMode: 'numeric',
            pattern: '[0-9]*',
          }}
        />
      )}
    </>
  )
}
