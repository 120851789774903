import React from 'react'
import { Modal, Box, Typography, Button } from '@mui/material'
import type { Appointment } from '../../../models/Appointment'
import type { Customer } from '../../../models/Customer'
import type { ModalType } from '../../../models/Common'
import { AppointmentSaveForm } from '../appointment/AppointmentSaveForm'
import {
  usePutAppointmentMutation,
  useUpdateCustomerMutation,
  useDeleteAppointmentMutation,
} from '../../../vendors/graphql/generated'
import { appointmentInputConverter } from '../../../utils/appointment/appointmentInputConverter'
import { appoToUpCusInputConverter } from '../../../utils/customer/appoToUpCusInputConverter'

interface CustomerAppointmentModalProps {
  title: string
  open: boolean
  handleClose: () => void
  appointment: Appointment
  customer: Customer
  type: ModalType
  handleRefetch?: () => void
}

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
}

export const CustomerAppointmentModal: React.FC<
  CustomerAppointmentModalProps
> = (props: CustomerAppointmentModalProps) => {
  const {
    title,
    open,
    handleClose,
    appointment,
    customer,
    type,
    handleRefetch,
  } = props
  const [putAppointment] = usePutAppointmentMutation()
  const [updateCustomer] = useUpdateCustomerMutation()
  const [deleteAppointment] = useDeleteAppointmentMutation()

  const initAppointment = {}
  const [inputEditAppointment, setInputEditAppointment] =
    React.useState<Appointment>(initAppointment)

  React.useEffect(() => {
    if (appointment != null && type === 'update') {
      setInputEditAppointment(appointment)
    } else if (type === 'create') {
      const newCreateCustomerAppointment = {
        customer_id: customer.id,
        family_name: customer.customer_info?.family_name,
        name: customer.customer_info?.name,
        family_name_kana: customer.customer_info?.family_name_kana,
        name_kana: customer.customer_info?.name_kana,
        birth_date: customer.customer_info?.birth_date,
        gender: customer.customer_info?.gender,
        annual_income: customer.customer_info?.annual_income,
        line_resistration: customer.line_resistration,
        appointment_status: customer.appointment_status,
      }
      setInputEditAppointment(newCreateCustomerAppointment)
    }
  }, [setInputEditAppointment, appointment, customer, type])

  let modalTitle: string
  if (type === 'create') {
    modalTitle = title + '新規'
  } else {
    modalTitle = title + '更新'
  }

  const createHandler = async (): Promise<void> => {
    const inputAppointment = appointmentInputConverter({
      appointment: inputEditAppointment,
    })
    await putAppointment({ variables: { input: inputAppointment } })
    if (handleRefetch != null) {
      handleRefetch()
    }
    handleClose()
  }

  const updateHandler = async (): Promise<void> => {
    const inputAppointment = appointmentInputConverter({
      appointment: inputEditAppointment,
    })
    const key = {
      id: inputEditAppointment.id ?? '',
      meta: inputEditAppointment.meta ?? '',
    }
    const inputUpdateCustomer = appoToUpCusInputConverter({
      appointment: inputEditAppointment,
    })
    const updateCustomerKey = {
      id: inputEditAppointment.customer_id ?? '',
      meta: 'Customer',
    }
    await putAppointment({ variables: { input: inputAppointment, key } })
    await updateCustomer({
      variables: {
        input: inputUpdateCustomer,
        key: updateCustomerKey,
        type: 'appointment',
      },
    })
    if (handleRefetch != null) {
      setTimeout(handleRefetch, 500)
    }
    handleClose()
  }

  const deleteHandler = async (): Promise<void> => {
    const key = {
      id: inputEditAppointment.id ?? '',
      meta: inputEditAppointment.meta ?? '',
    }
    await deleteAppointment({
      variables: {
        key,
      },
    })
    if (handleRefetch != null) {
      handleRefetch()
    }
    handleClose()
  }

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle} className="w-11/12 overflow-y-scroll">
          <div className="flex items-center justify-start">
            <Typography className="px-6" variant="h5">
              {modalTitle}
            </Typography>

            <Box className="ml-10 flex items-center justify-center">
              <Button variant="outlined" onClick={handleClose}>
                閉じる
              </Button>
              {type === 'create' ? (
                <Button
                  sx={{ ml: 2 }}
                  variant="contained"
                  onClick={async () => {
                    await createHandler()
                  }}
                >
                  保存
                </Button>
              ) : (
                <>
                  <Button
                    sx={{ ml: 2 }}
                    variant="contained"
                    onClick={async () => {
                      await updateHandler()
                    }}
                  >
                    更新
                  </Button>
                  <Button
                    sx={{ ml: 2 }}
                    variant="contained"
                    onClick={async () => {
                      await deleteHandler()
                    }}
                  >
                    削除
                  </Button>
                </>
              )}
            </Box>
          </div>
          <AppointmentSaveForm
            inputEditAppointment={inputEditAppointment}
            setInputEditAppointment={setInputEditAppointment}
            type={type}
          />
        </Box>
      </Modal>
    </>
  )
}
