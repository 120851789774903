import type { CustomerHistory } from '../../models/CustomerHistory'
import type { CustomerHistoryInput } from '../../vendors/graphql/generated'

export const customerHistoryInputConverter = (props: {
  customerHistory: CustomerHistory
  customerId?: string
}): CustomerHistoryInput => {
  /*
  id, metaを除く
  空文字列を除く
  */
  const { customerHistory, customerId } = props

  const item: CustomerHistoryInput = {
    customer_id: customerId ?? '',
    memo: '',
    history_date: '',
  }

  if (customerHistory.customer_id) {
    item.customer_id = customerHistory.customer_id
  }

  if (customerHistory.created_at) {
    item.created_at = customerHistory.created_at
  }

  if (customerHistory.memo) {
    item.memo = customerHistory.memo
  }

  if (customerHistory.history_date) {
    item.history_date = customerHistory.history_date
  }

  return item
}
