import type { AppointmentStatus } from '../../models/SelectCandidate'
import { appointmentStatusColorDict } from '../../fixture/common/formOptions'

export const appointmentStatusColorSelector = (
  status: AppointmentStatus | undefined
): string => {
  if (status == null) {
    return ''
  }
  return appointmentStatusColorDict[status]
}
