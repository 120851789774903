import type { InquiryHeader } from '../../models/Inquiry'

export const cellTextInputs = [
  'nearest_station',
  'property_name',
  'year_of_construction',
  'memo',
  'media',
]

export const textInputs = [
  'family_name',
  'name',
  'family_name_kana',
  'name_kana',
  'nearest_station',
  'property_name',
  'year_of_construction',
  'media',
]
export const longTextInputs = ['memo']
export const numberInputs = ['price']
export const selectInputs = ['floor_plan', 'reaction', 'appointment_status']
export const dateInputs = ['inquiry_date']
export const boolInputs = ['line_resistration']

const defalutStyle = {
  fontWeight: 'bold',
  whiteSpace: 'nowrap',
  fontSize: 13,
  background: '#FFFF66',
}

const blueBackStyle = {
  ...defalutStyle,
  background: '#0099FF',
}

const fontSizeL = 15

// 反響リスト
export const inquiryHeaders: InquiryHeader[] = [
  {
    key: 'inquiry_date',
    name: '時間',
    headSx: { ...defalutStyle, fontSize: fontSizeL },
  },
  {
    key: 'full_name',
    name: '名前',
    headSx: { ...defalutStyle, fontSize: fontSizeL },
  },
  { key: 'nearest_station', name: '最寄駅', headSx: defalutStyle },
  { key: 'property_name', name: '物件名', headSx: defalutStyle },
  { key: 'year_of_construction', name: '築年', headSx: defalutStyle },
  {
    key: 'price_and_floor_plan',
    name: '価格\n間取り',
    headSx: { ...blueBackStyle, fontSize: fontSizeL },
    bodySx: {},
  },
  {
    key: 'media',
    name: '媒体',
    headSx: { ...blueBackStyle, fontSize: fontSizeL },
  },
  {
    key: 'line_resistration',
    name: 'LINE\n登録',
    headSx: { ...blueBackStyle, fontSize: fontSizeL },
  },
  {
    key: 'memo',
    name: 'タッチ',
    headSx: { ...defalutStyle, textDecoration: 'underline' },
  },
  { key: 'reaction', name: 'リアク\nション', headSx: defalutStyle },
]
