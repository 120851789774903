import type { CompromisePoint } from '../../models/SelectCandidate'

interface Candidate {
  key: string
  value: string
}

const candidateGenerator = (dict: Record<string, string>): Candidate[] => {
  return Object.keys(dict).map((key) => {
    return { key, value: dict[key] }
  })
}

export const datetimeFormat = 'YYYY-MM-DDTHH:mm:ss+09:00'
export const dateFormat = 'YYYY-MM-DD'

export const rankDict = {
  a: 'A',
  b: 'B',
  c: 'C',
}

export const preparationDict = {
  done: '済',
  pending: '未',
  progress: '中',
}

export const appointmentResultDict = {
  yes: '○',
  no: '×',
  next: '再',
}

export const appointmentStatusColorDict = {
  application: 'bg-blue-200',
  refused_application: 'bg-red-400',
  contracted: 'bg-yellow-500',
  canceled_appointment: 'bg-yellow-200',
  after_apo_chasing: 'bg-cyan-300',
  not_chasing: 'bg-gray-400',
  unguided: '',
}

export const floorPlanDict = {
  one_r: '1R',
  one_k: '1K',
  one_dk: '1DK',
  one_ldk: '1LDK',
  two_k: '2K',
  two_dk: '2DK',
  two_ldK: '2LDK',
  three_dk: '3DK',
  three_ldk: '3LDK',
  four_dk: '4DK',
  four_ldk: '4LDK',
}

export const reactionDict = {
  talked: '話した',
  email_replied: 'メール返信有',
  sms_replied: 'SMS返信有',
  apo_chasing: 'APO追客',
  action_required: '要対応',
  not_chasing: 'ダメ（追わない）',
  nothing: '無',
  contracted: '案内済',
  canceled: 'キャンセル',
  after_apo_chasing: 'APO後追客',
}

export const appointmentStatusDict = {
  application: '申込',
  refused_application: '申込断り',
  contracted: '契約済',
  canceled_appointment: '案内キャンセル',
  after_apo_chasing: '案内後追客',
  not_chasing: 'ダメ（追わない）',
  unguided: '未案内',
}

export const genderDict = {
  male: '男性',
  female: '女性',
}

export const workStyleDict = {
  employee: '社員',
  part: 'パート',
  self_employed: '自営業',
  manager: '経営者',
}

export const insuranceDict = {
  social: '社保',
  nation: '国保',
}

export const residenceTypeDict = {
  mansion: '賃マン',
  apartmet: '賃アパ',
  corp: 'コーポ',
  parents_home: '実家',
  owned_house: '持家',
  company_house: '社宅',
  other: 'その他',
}

export const residenceAppearanceDict = {
  old: '古い',
  normal: '普通',
  new: '新しい',
}

export const compromisePointDict = {
  sunny: '日当悪',
  view: '眺望悪',
  one_floor: '1F',
  north: '北',
  highway: '線路・幹線道路',
  dirty: '汚い',
  accident: '事故',
}

export const compromisePointCandidates: CompromisePoint[] = [
  'sunny',
  'view',
  'one_floor',
  'north',
  'highway',
  'dirty',
  'accident',
]

export const menuItemsDict = {
  rank: candidateGenerator(rankDict),
  preparation: candidateGenerator(preparationDict),
  appointment_result: candidateGenerator(appointmentResultDict),
  floor_plan: candidateGenerator(floorPlanDict),
  reaction: candidateGenerator(reactionDict),
  appointment_status: candidateGenerator(appointmentStatusDict),
  gender: candidateGenerator(genderDict),
  inquiry_property_floor_plan: candidateGenerator(floorPlanDict),
  work_style: candidateGenerator(workStyleDict),
  insurance: candidateGenerator(insuranceDict),
  residence_type: candidateGenerator(residenceTypeDict),
  residence_appearance: candidateGenerator(residenceAppearanceDict),
  residence_floor_plan: candidateGenerator(floorPlanDict),
  desired_floor_plan: candidateGenerator(floorPlanDict),
  compromise_point: candidateGenerator(compromisePointDict),
}
