import React from 'react'
import { Modal, Box, Button, Typography } from '@mui/material'
import type { Appointment } from '../../../models/Appointment'
import {
  AppointmentSaveForm,
  SmAppointmentSaveForm,
} from './AppointmentSaveForm'
import {
  usePutAppointmentMutation,
  useUpdateCustomerMutation,
  useDeleteAppointmentMutation,
} from '../../../vendors/graphql/generated'
import { appointmentInputConverter } from '../../../utils/appointment/appointmentInputConverter'
import { appoToUpCusInputConverter } from '../../../utils/customer/appoToUpCusInputConverter'

interface AppointmentModalProps {
  open: boolean
  handleClose: () => void
  appointment: Appointment
  handleRefetch: () => void
}

interface AppointmentModalChildProps {
  handleClose: () => void
  updateHandler: () => Promise<void>
  deleteHandler: () => Promise<void>
  inputEditAppointment: Appointment
  setInputEditAppointment: React.Dispatch<React.SetStateAction<Appointment>>
}

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: 'w-auto',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
}

export const AppointmentModal: React.FC<AppointmentModalProps> = (
  props: AppointmentModalProps
) => {
  const { open, handleClose, appointment, handleRefetch } = props
  const [putAppointment] = usePutAppointmentMutation()
  const [updateCustomer] = useUpdateCustomerMutation()
  const [deleteAppointment] = useDeleteAppointmentMutation()

  const initAppointment = {}
  const [inputEditAppointment, setInputEditAppointment] =
    React.useState<Appointment>(initAppointment)

  React.useEffect(() => {
    setInputEditAppointment(appointment)
  }, [setInputEditAppointment, appointment])

  const updateHandler = async (): Promise<void> => {
    const inputAppointment = appointmentInputConverter({
      appointment: inputEditAppointment,
    })
    const key = {
      id: inputEditAppointment.id ?? '',
      meta: inputEditAppointment.meta ?? '',
    }
    const inputUpdateCustomer = appoToUpCusInputConverter({
      appointment: inputEditAppointment,
    })
    const updateCustomerKey = {
      id: inputEditAppointment.customer_id ?? '',
      meta: 'Customer',
    }
    await putAppointment({ variables: { input: inputAppointment, key } })
    await updateCustomer({
      variables: {
        input: inputUpdateCustomer,
        key: updateCustomerKey,
        type: 'appointment',
      },
    })
    if (handleRefetch != null) {
      setTimeout(handleRefetch, 500)
    }
    handleClose()
  }

  const deleteHandler = async (): Promise<void> => {
    await deleteAppointment({
      variables: {
        key: { id: inputEditAppointment.id ?? '', meta: 'Appointment' },
      },
    })
    if (handleRefetch != null) {
      handleRefetch()
    }
    handleClose()
  }

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>
          {/* {loading && <p>Submitting...</p>}
          {error && <p>Submission error! {error.message}</p>} */}
          <div className="md:block hidden">
            <MdAppointmentModal
              handleClose={handleClose}
              updateHandler={updateHandler}
              deleteHandler={deleteHandler}
              inputEditAppointment={inputEditAppointment}
              setInputEditAppointment={setInputEditAppointment}
            />
          </div>
          <div className="md:hidden">
            <SmAppointmentModal
              handleClose={handleClose}
              updateHandler={updateHandler}
              deleteHandler={deleteHandler}
              inputEditAppointment={inputEditAppointment}
              setInputEditAppointment={setInputEditAppointment}
            />
          </div>
        </div>
      </Modal>
    </>
  )
}

const MdAppointmentModal: React.FC<AppointmentModalChildProps> = (
  props: AppointmentModalChildProps
) => {
  const {
    handleClose,
    updateHandler,
    deleteHandler,
    inputEditAppointment,
    setInputEditAppointment,
  } = props
  return (
    <>
      <Box sx={modalStyle} className="w-11/12 overflow-y-scroll">
        <div className="flex items-center justify-start">
          <Typography className="px-6" variant="h5">
            案件更新
          </Typography>

          <Box sx={{ ml: 6, display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="outlined"
              onClick={() => {
                handleClose()
              }}
            >
              閉じる
            </Button>
            <Button
              sx={{ ml: 2 }}
              variant="contained"
              onClick={async () => {
                await updateHandler()
              }}
            >
              更新
            </Button>
            <Button
              sx={{ ml: 2 }}
              variant="contained"
              onClick={async () => {
                await deleteHandler()
              }}
            >
              削除
            </Button>
          </Box>
        </div>

        <AppointmentSaveForm
          inputEditAppointment={inputEditAppointment}
          setInputEditAppointment={setInputEditAppointment}
          type="update"
        />
      </Box>
    </>
  )
}

const SmAppointmentModal: React.FC<AppointmentModalChildProps> = (
  props: AppointmentModalChildProps
) => {
  const {
    handleClose,
    updateHandler,
    deleteHandler,
    inputEditAppointment,
    setInputEditAppointment,
  } = props
  return (
    <>
      <Box sx={modalStyle} className="h-full w-11/12 overflow-y-scroll p-2">
        <div className="flex items-center justify-start mb-2">
          <div className="text-base">案件更新</div>

          <Box sx={{ ml: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="outlined"
              onClick={() => {
                handleClose()
              }}
            >
              閉じる
            </Button>
            <Button
              sx={{ ml: 1 }}
              variant="contained"
              onClick={async () => {
                await updateHandler()
              }}
            >
              更新
            </Button>
            <Button
              sx={{ ml: 1 }}
              variant="contained"
              onClick={async () => {
                await deleteHandler()
              }}
            >
              削除
            </Button>
          </Box>
        </div>

        <SmAppointmentSaveForm
          inputEditAppointment={inputEditAppointment}
          setInputEditAppointment={setInputEditAppointment}
          type="update"
        />
      </Box>
    </>
  )
}
