import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Link } from 'react-router-dom'
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  IconButton,
} from '@mui/material'
import ListIcon from '@mui/icons-material/List'
import Menu from '@mui/icons-material/Menu'
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined'
import CloseIcon from '@mui/icons-material/Close'
import LogoutIcon from '@mui/icons-material/Logout'
import { UserInfoBox } from './UserInfoBox'

interface LinkItemProps {
  path: string
  title: string
  LinkPermission: 'default' | string
  UserPermissions: string[] | undefined
}

interface LayoutProps {
  children: React.ReactNode
}

interface MdMenueProps {
  title: string
  openMenu: boolean
  setOpenMenu: React.Dispatch<React.SetStateAction<boolean>>
  permissions: string[] | undefined
}

interface SmMenuProps {
  title: string
  openMenu: boolean
  setOpenMenu: React.Dispatch<React.SetStateAction<boolean>>
  permissions: string[] | undefined
}

interface SmListProps {
  permissions: string[] | undefined
}

interface MdListProps {
  title: string
  openMenu: boolean
  setOpenMenu: React.Dispatch<React.SetStateAction<boolean>>
  permissions: string[] | undefined
}

const LinkItem: React.FC<LinkItemProps> = (props: LinkItemProps) => {
  let permissions = ['default']
  if (props.UserPermissions !== undefined) {
    permissions = permissions.concat(props.UserPermissions)
  }
  const isActive = permissions.includes(props.LinkPermission)
  return (
    <>
      {isActive && (
        <Link to={props.path}>
          <ListItem button>
            <ListItemIcon>
              <ListIcon className="text-gray-200" />
            </ListItemIcon>
            <ListItemText className="font-bold" primary={props.title} />
          </ListItem>
        </Link>
      )}
    </>
  )
}

export const Layout: React.FC<LayoutProps> = ({ children }: LayoutProps) => {
  const [openSmMenu, setOpenSmMenu] = React.useState(false)
  const [openMdMenu, setOpenMdMenu] = React.useState(true)
  // const { permissions } = React.useContext(PermissionContext)
  const permissions = [] as string[]
  const title = '東京マンション管理システム'
  return (
    <>
      <div className="md:flex h-screen">
        <SmMenu
          title={title}
          openMenu={openSmMenu}
          setOpenMenu={setOpenSmMenu}
          permissions={permissions}
        />
        <MdMenu
          title={title}
          openMenu={openMdMenu}
          setOpenMenu={setOpenMdMenu}
          permissions={permissions}
        />
        <div
          onClick={() => {
            setOpenSmMenu(false)
          }}
          className="w-full h-auto bg-gray-300 overflow-y-scroll"
        >
          <div>{children}</div>
        </div>
      </div>
    </>
  )
}

const SmMenu: React.FC<SmMenuProps> = (props: SmMenuProps) => {
  const { openMenu, setOpenMenu, permissions, title } = props
  return (
    <>
      <div className="md:hidden bg-gray-800 text-gray-200">
        <div className="md:hidden absolute top-1 left-0">
          {openMenu ? (
            <IconButton
              onClick={() => {
                setOpenMenu(false)
              }}
            >
              <CloseIcon className="text-gray-200" />
            </IconButton>
          ) : (
            <IconButton
              onClick={() => {
                setOpenMenu(true)
              }}
            >
              <Menu className="text-gray-200" />
            </IconButton>
          )}
        </div>
        <Typography className="pl-12 py-2" variant="h5">
          {title}
        </Typography>
        {openMenu && <SmList permissions={permissions} />}
      </div>
    </>
  )
}

const SmList: React.FC<SmListProps> = (props: SmListProps) => {
  const { permissions } = props
  const { logout } = useAuth0()
  return (
    <div className="absolute top-12 left-0 z-50 bg-gray-800 text-gray-200">
      <div className="">
        <LinkItem
          path="/inquiry/"
          title="反響一覧"
          LinkPermission="default"
          UserPermissions={permissions}
        />
        <Divider />
        <LinkItem
          path="/appointment/"
          title="案内一覧"
          LinkPermission="default"
          UserPermissions={permissions}
        />
        <Divider />
        <ListItem button className="py-4">
          <ListItemIcon
            className=""
            onClick={() => {
              logout({ logoutParams: { returnTo: window.location.origin } })
            }}
          >
            <LogoutIcon className="text-gray-200" />
          </ListItemIcon>
          <ListItemText className="font-bold" primary={'ログアウト'} />
        </ListItem>
      </div>
    </div>
  )
}

const MdMenu: React.FC<MdMenueProps> = (props: MdMenueProps) => {
  const { openMenu, setOpenMenu, permissions, title } = props
  return (
    <>
      <div className="md:block hidden absolute top-1 left-0">
        {!openMenu && (
          <IconButton
            onClick={() => {
              setOpenMenu(true)
            }}
          >
            <Menu className="" />
          </IconButton>
        )}
      </div>
      {openMenu && (
        <MdList
          title={title}
          openMenu={openMenu}
          setOpenMenu={setOpenMenu}
          permissions={permissions}
        />
      )}
    </>
  )
}

const MdList: React.FC<MdListProps> = (props: MdListProps) => {
  const { openMenu, setOpenMenu, permissions, title } = props
  return (
    <div className="md:block hidden static w-1/6 bg-gray-800 text-gray-200">
      <Typography className="pl-4 pt-4 pr-2" variant="h5">
        {title}
      </Typography>
      <div className="flex justify-between">
        <Typography className="pl-4 pt-4 mr-2">メニュー</Typography>
        {openMenu && (
          <IconButton
            onClick={() => {
              setOpenMenu(false)
            }}
          >
            <ArrowCircleLeftOutlinedIcon className="text-gray-200 mt-2 mr-2" />
          </IconButton>
        )}
      </div>
      <List className="">
        <Divider />
        <LinkItem
          path="/inquiry/"
          title="反響一覧"
          LinkPermission="default"
          UserPermissions={permissions}
        />
        <Divider />
        <LinkItem
          path="/appointment/"
          title="案内一覧"
          LinkPermission="default"
          UserPermissions={permissions}
        />
        <Divider />
      </List>
      <div className="absolute bottom-0">
        <UserInfoBox />
      </div>
    </div>
  )
}
