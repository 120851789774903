import { Routes, Route } from 'react-router-dom'
import Home from './views/pages/home/Home'
import Inquiry from './views/pages/inquiry/Inquiry'
import Appointment from './views/pages/appointment/Appointment'
import CustomerChart from './views/pages/customer/CustomerChart'
import NotFound from './views/pages/not_found/NotFound'
import React from 'react'

interface RouteInfo {
  path: string
  element: React.FC
}

interface RouteComponentProps extends RouteInfo {
  index: number
}

const routesInfo: RouteInfo[] = [
  {
    path: '/',
    element: Home,
  },
  {
    path: '/inquiry/',
    element: Inquiry,
  },
  {
    path: '/appointment/',
    element: Appointment,
  },
  {
    path: '*',
    element: NotFound,
  },
]

const RouteComponent: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  return (
    <Route path={props.path} element={<props.element />} key={props.index} />
  )
}

const Router: React.FC = () => {
  return (
    <>
      <Routes>
        {routesInfo.map((route, index) =>
          RouteComponent({ path: route.path, element: route.element, index })
        )}
        <Route path="/customer" element={<CustomerChart />}>
          <Route path=":customerId" element={<CustomerChart />} />
        </Route>
      </Routes>
    </>
  )
}

export default Router
