import { TextFieldForm } from '../../common/editForms/TextFieldForm'
import { NumberInputForm } from '../../common/editForms/NumberInputForm'
import { CustomerBooleanRadioForm } from '../../common/editForms/BooleanRadioForm'
import type { Customer, CustomerView } from '../../../../models/Customer'

interface CustomerChartAddressBlockProps {
  editCustomer: Customer
  customerView: CustomerView
  setEditCustomer: React.Dispatch<React.SetStateAction<Customer>>
  editMode: boolean
}

export const CustomerChartAddressBlock: React.FC<
  CustomerChartAddressBlockProps
> = (props: CustomerChartAddressBlockProps) => {
  const { customerView, editCustomer, setEditCustomer, editMode } = props
  const blockColor = 'bg-yellow-100'
  return (
    <>
      <div className="grid grid-cols-12 rounded-none">
        <div
          className={`relative col-start-1 col-span-2 flex items-center justify-center border border-current ${blockColor}`}
        >
          <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
            住所
          </div>
          <div className="absolute top-0 right-0 py-1 px-2 font-semibold text-xs">
            (市区町村)
          </div>
          <div className="py-4 px-4 h-16 text-xl">
            {editMode ? (
              <TextFieldForm
                keyName="address_ward"
                customer={editCustomer}
                setCustomer={setEditCustomer}
                sx={{ px: 4 }}
              />
            ) : (
              customerView.address_ward.replace(/[市区町村]$/, '')
            )}
          </div>
          {editMode ? (
            <></>
          ) : (
            <div className="absolute bottom-0 right-0 py-1 px-2 font-semibold text-sm">
              {customerView.address_ward.match(/[市区町村]$/)}
            </div>
          )}
        </div>
        <div
          className={`relative col-span-4 flex items-center justify-center border-y border-r border-current ${blockColor}`}
        >
          <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
            住所(その他)
          </div>
          <div className="py-4 px-4 h-16 text-xl">
            {editMode ? (
              <TextFieldForm
                keyName="address_other"
                customer={editCustomer}
                setCustomer={setEditCustomer}
                sx={{ px: 4 }}
              />
            ) : (
              customerView.address_other
            )}
          </div>
        </div>
        <div
          className={`relative col-span-4 flex items-center justify-center border-r border-y border-current ${blockColor}`}
        >
          <div>
            <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
              最寄り駅
            </div>
            <div className="py-4 text-xl">
              {editMode ? (
                <TextFieldForm
                  keyName="nearest_station"
                  customer={editCustomer}
                  setCustomer={setEditCustomer}
                  sx={{ px: 2 }}
                />
              ) : (
                customerView.nearest_station
              )}
            </div>
          </div>
        </div>
        <div
          className={`relative col-span-1 flex items-center justify-center border-r border-y border-current ${blockColor}`}
        >
          <div>
            <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
              徒歩
            </div>
            <div className="py-4 text-xl">
              {editMode ? (
                <NumberInputForm
                  keyName="on_foot_minutes"
                  customer={editCustomer}
                  setCustomer={setEditCustomer}
                  classString="w-6"
                />
              ) : (
                customerView.on_foot_minutes
              )}
            </div>
            <div className="absolute bottom-0 right-0 py-1 px-2 font-semibold text-xs">
              分
            </div>
          </div>
        </div>
        <div
          className={`relative col-start-1 col-span-3 flex items-center justify-center border-x border-b border-current ${blockColor}`}
        >
          <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
            結婚予定
          </div>
          <div className="py-1.5 px-2">
            {editMode ? (
              <CustomerBooleanRadioForm
                keyName="plan_to_get_married"
                customer={editCustomer}
                setCustomer={setEditCustomer}
              />
            ) : (
              customerView.plan_to_get_married
            )}
          </div>
        </div>
        <div
          className={`relative col-span-3 flex items-center justify-center border-r border-b border-current ${blockColor}`}
        >
          <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
            結婚
          </div>
          <div className="py-1.5 px-2">
            {editMode ? (
              <CustomerBooleanRadioForm
                keyName="is_get_married"
                customer={editCustomer}
                setCustomer={setEditCustomer}
              />
            ) : (
              customerView.is_get_married
            )}
          </div>
        </div>
        <div
          className={`relative col-start-1 col-span-3 flex items-center justify-center border-x border-current ${blockColor}`}
        >
          <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
            ペット飼育
          </div>
          <div className="py-1.5 px-2">
            {editMode ? (
              <CustomerBooleanRadioForm
                keyName="pet_breeding"
                customer={editCustomer}
                setCustomer={setEditCustomer}
              />
            ) : (
              customerView.pet_breeding
            )}
          </div>
        </div>
        <div
          className={`relative col-start-1 col-span-3 flex items-center justify-center border-x border-y border-current ${blockColor}`}
        >
          <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
            自動車（バイク）
          </div>
          <div className="py-1.5 px-2">
            {editMode ? (
              <CustomerBooleanRadioForm
                keyName="have_vehicle"
                customer={editCustomer}
                setCustomer={setEditCustomer}
              />
            ) : (
              customerView.have_vehicle
            )}
          </div>
        </div>
        <div
          className={`relative col-span-3 flex items-center justify-center border-r border-y border-current ${blockColor}`}
        >
          <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
            駐車場代
          </div>
          <div className="py-1.5 px-2">
            {editMode ? (
              <NumberInputForm
                keyName="parking_fee"
                customer={editCustomer}
                setCustomer={setEditCustomer}
                classString="w-16"
              />
            ) : (
              customerView.parking_fee
            )}
          </div>
          <div className="absolute bottom-0 right-0 py-1 px-2 font-semibold text-xs">
            円
          </div>
        </div>
        <div
          className={`relative col-start-1 col-span-3 flex items-center justify-center border-x border-b border-current ${blockColor}`}
        >
          <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
            自転車
          </div>
          <div className="py-1.5 px-2">
            {editMode ? (
              <CustomerBooleanRadioForm
                keyName="have_bicycle"
                customer={editCustomer}
                setCustomer={setEditCustomer}
              />
            ) : (
              customerView.have_bicycle
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export const SmCustomerChartAddressBlock: React.FC<
  CustomerChartAddressBlockProps
> = (props: CustomerChartAddressBlockProps) => {
  const { customerView, editCustomer, setEditCustomer, editMode } = props
  const blockColor = 'bg-yellow-100'
  return (
    <>
      <div className="grid grid-cols-4 rounded-none">
        <div
          className={`relative col-start-1 col-span-2 flex items-center justify-center border-t border-x border-current ${blockColor}`}
        >
          <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
            住所
          </div>
          <div className="absolute top-0 right-0 px-1 font-semibold text-xs">
            (市区町村)
          </div>
          <div className="py-4 px-4 h-16 text-xl">
            {editMode ? (
              <TextFieldForm
                keyName="address_ward"
                customer={editCustomer}
                setCustomer={setEditCustomer}
                sx={{ px: 4 }}
              />
            ) : (
              customerView.address_ward.replace(/[市区町村]$/, '')
            )}
          </div>
          {editMode ? (
            <></>
          ) : (
            <div className="absolute bottom-0 right-0 px-1 font-semibold text-sm">
              {customerView.address_ward.match(/[市区町村]$/)}
            </div>
          )}
        </div>
        <div
          className={`relative col-span-3 flex items-center justify-center border-t border-x border-current ${blockColor}`}
        >
          <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
            住所(その他)
          </div>
          <div className="py-4 px-4 h-16 text-xl">
            {editMode ? (
              <TextFieldForm
                keyName="address_other"
                customer={editCustomer}
                setCustomer={setEditCustomer}
                sx={{ px: 4 }}
              />
            ) : (
              customerView.address_other
            )}
          </div>
        </div>
        <div
          className={`relative col-span-2 flex items-center justify-center border-x border-y border-current ${blockColor}`}
        >
          <div>
            <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
              最寄り駅
            </div>
            <div className="flex items-center h-12 py-1 text-xl">
              {editMode ? (
                <TextFieldForm
                  keyName="nearest_station"
                  customer={editCustomer}
                  setCustomer={setEditCustomer}
                  sx={{ px: 2 }}
                />
              ) : (
                customerView.nearest_station
              )}
            </div>
          </div>
        </div>
        <div
          className={`relative col-span-1 flex items-center justify-center border-r border-y border-current ${blockColor}`}
        >
          <div>
            <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
              徒歩
            </div>
            <div className="flex items-center h-12 py-1 text-xl">
              {editMode ? (
                <NumberInputForm
                  keyName="on_foot_minutes"
                  customer={editCustomer}
                  setCustomer={setEditCustomer}
                  classString="w-6"
                />
              ) : (
                customerView.on_foot_minutes
              )}
            </div>
            <div className="absolute bottom-0 right-0 px-1 font-semibold text-xs">
              分
            </div>
          </div>
        </div>
        <div
          className={`relative col-start-1 col-span-3 flex items-center justify-center border-x border-b border-current ${blockColor}`}
        >
          <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
            結婚予定
          </div>
          <div className="flex items-center h-12 py-1 px-2">
            {editMode ? (
              <CustomerBooleanRadioForm
                keyName="plan_to_get_married"
                customer={editCustomer}
                setCustomer={setEditCustomer}
              />
            ) : (
              customerView.plan_to_get_married
            )}
          </div>
        </div>
        <div
          className={`relative col-start-1 col-span-3 flex items-center justify-center border-x border-b border-current ${blockColor}`}
        >
          <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
            結婚
          </div>
          <div className="flex items-center h-12 py-1 px-2">
            {editMode ? (
              <CustomerBooleanRadioForm
                keyName="is_get_married"
                customer={editCustomer}
                setCustomer={setEditCustomer}
              />
            ) : (
              customerView.is_get_married
            )}
          </div>
        </div>
        <div
          className={`relative col-start-1 col-span-3 flex items-center justify-center border-x border-current ${blockColor}`}
        >
          <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
            ペット飼育
          </div>
          <div className="flex items-center h-12 py-1 px-2">
            {editMode ? (
              <CustomerBooleanRadioForm
                keyName="pet_breeding"
                customer={editCustomer}
                setCustomer={setEditCustomer}
              />
            ) : (
              customerView.pet_breeding
            )}
          </div>
        </div>
        <div
          className={`relative col-start-1 col-span-3 flex items-center justify-center border-x border-t border-current ${blockColor}`}
        >
          <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
            自動車（バイク）
          </div>
          <div className="flex items-center h-12 py-1 px-2">
            {editMode ? (
              <CustomerBooleanRadioForm
                keyName="have_vehicle"
                customer={editCustomer}
                setCustomer={setEditCustomer}
              />
            ) : (
              customerView.have_vehicle
            )}
          </div>
        </div>
        <div
          className={`relative col-start-1 col-span-3 flex items-center justify-center border-x border-t border-current ${blockColor}`}
        >
          <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
            駐車場代
          </div>
          <div className="flex items-center h-12 py-1 px-2">
            {editMode ? (
              <NumberInputForm
                keyName="parking_fee"
                customer={editCustomer}
                setCustomer={setEditCustomer}
                classString="w-16"
              />
            ) : (
              <div className="h-6 w-20 flex items-center justify-center">
                {customerView.parking_fee}
              </div>
            )}
          </div>
          <div className="absolute bottom-0 right-0 px-1 font-semibold text-xs">
            円
          </div>
        </div>
        <div
          className={`relative col-start-1 col-span-3 flex items-center justify-center border border-current ${blockColor}`}
        >
          <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
            自転車
          </div>
          <div className="flex items-center h-12 py-1 px-2">
            {editMode ? (
              <CustomerBooleanRadioForm
                keyName="have_bicycle"
                customer={editCustomer}
                setCustomer={setEditCustomer}
              />
            ) : (
              customerView.have_bicycle
            )}
          </div>
        </div>
      </div>
    </>
  )
}
