import React from 'react'
import { TextField } from '@mui/material'
import type { SxProps } from '@mui/material'
import type {
  Customer,
  CustomerTextHeaderKey,
  ParentTextHeaderKey,
  ParentInfoRootKey,
  ChildTextHeaderKey,
  ChildInfoRootKey,
  FamilyMemberInfoRootKey,
  FamilyMemberTextHeaderKey,
} from '../../../../models/Customer'

interface TextFieldFormCommonProps {
  label?: string
  customer: Customer
  setCustomer: React.Dispatch<React.SetStateAction<Customer>>
  sx?: SxProps
}

interface TextFieldFormKeyProps {
  keyName: CustomerTextHeaderKey
}

interface TextFieldParentInfoKeyProps {
  keyName1: ParentInfoRootKey
  keyName2: ParentTextHeaderKey
}

interface TextFieldChildInfoKeyProps {
  keyName1: ChildInfoRootKey
  keyName2: ChildTextHeaderKey
  childIndex: number
}

interface TextFieldFamilyMemberKeyProps {
  keyName1: FamilyMemberInfoRootKey
  keyName2: FamilyMemberTextHeaderKey
  familyMemberIndex: number
}

type TextFieldFormProps = TextFieldFormCommonProps & TextFieldFormKeyProps

type TextFieldParentInfoFormProps = TextFieldFormCommonProps &
  TextFieldParentInfoKeyProps

type TextFieldChildInfoFormProps = TextFieldFormCommonProps &
  TextFieldChildInfoKeyProps

type TextFieldFamilyMemberInfoFormProps = TextFieldFormCommonProps &
  TextFieldFamilyMemberKeyProps

export const TextFieldForm: React.FC<TextFieldFormProps> = (
  props: TextFieldFormProps
) => {
  const { label, keyName, customer, setCustomer, sx } = props

  const initTextValue = customer[keyName] ?? ''
  const [inputTextValue, setInputTextValue] = React.useState<
    string | undefined
  >('')

  React.useEffect(() => {
    if (inputTextValue !== initTextValue) {
      setInputTextValue(initTextValue)
    }
  }, [initTextValue])

  const onBlur = (): void => {
    if (initTextValue !== inputTextValue && setCustomer) {
      setCustomer({ ...customer, [keyName]: inputTextValue })
    }
  }

  return (
    <>
      <TextField
        fullWidth
        id={keyName}
        label={label}
        variant="standard"
        value={inputTextValue}
        sx={sx}
        onChange={(event) => {
          setInputTextValue(event.target.value)
        }}
        onBlur={onBlur}
      />
    </>
  )
}

export const TextFieldParentInfoForm: React.FC<TextFieldParentInfoFormProps> = (
  props: TextFieldParentInfoFormProps
) => {
  const { label, keyName1, keyName2, customer, setCustomer, sx } = props

  let textValue
  const parentInfo = customer[keyName1]
  if (parentInfo != null && !parentInfo[keyName2] != null) {
    textValue = parentInfo[keyName2]
  }
  const initTextValue = textValue ?? ''
  const [inputTextValue, setInputTextValue] = React.useState<string>('')

  React.useEffect(() => {
    if (inputTextValue !== initTextValue) {
      setInputTextValue(initTextValue)
    }
  }, [initTextValue])

  const onBlur = (): void => {
    if (initTextValue !== inputTextValue && setCustomer) {
      const parentInfo = customer[keyName1]
      if (parentInfo != null) {
        parentInfo[keyName2] = inputTextValue
        customer[keyName1] = parentInfo
      } else {
        customer[keyName1] = { [keyName2]: inputTextValue }
      }
      setCustomer({ ...customer })
    }
  }

  return (
    <>
      <TextField
        fullWidth
        id={keyName2}
        label={label}
        variant="standard"
        value={inputTextValue}
        sx={sx}
        onChange={(event) => {
          setInputTextValue(event.target.value)
        }}
        onBlur={onBlur}
      />
    </>
  )
}

export const TextFieldChildInfoForm: React.FC<TextFieldChildInfoFormProps> = (
  props: TextFieldChildInfoFormProps
) => {
  const { label, keyName1, keyName2, childIndex, customer, setCustomer, sx } =
    props

  let textValue
  const childrenInfo = customer[keyName1]
  if (
    childrenInfo != null &&
    !childrenInfo[childIndex] != null &&
    childrenInfo[childIndex][keyName2] != null
  ) {
    textValue = childrenInfo[childIndex][keyName2]
  }
  const initTextValue = textValue ?? ''
  const [inputTextValue, setInputTextValue] = React.useState<string>('')

  React.useEffect(() => {
    if (inputTextValue !== initTextValue) {
      setInputTextValue(initTextValue)
    }
  }, [initTextValue])

  const onBlur = (): void => {
    if (initTextValue !== inputTextValue && setCustomer) {
      const childInfo = customer[keyName1]
      if (childInfo != null) {
        childInfo[childIndex][keyName2] = inputTextValue
        customer[keyName1] = childInfo
      }
      setCustomer({ ...customer })
    }
  }

  return (
    <>
      <TextField
        fullWidth
        id={keyName2}
        label={label}
        variant="standard"
        value={inputTextValue}
        sx={sx}
        onChange={(event) => {
          setInputTextValue(event.target.value)
        }}
        onBlur={onBlur}
      />
    </>
  )
}

export const TextFieldFamilyMemberInfoForm: React.FC<
  TextFieldFamilyMemberInfoFormProps
> = (props: TextFieldFamilyMemberInfoFormProps) => {
  const {
    label,
    keyName1,
    keyName2,
    familyMemberIndex,
    customer,
    setCustomer,
    sx,
  } = props

  let textValue
  const childrenInfo = customer[keyName1]
  if (
    childrenInfo != null &&
    !childrenInfo[familyMemberIndex] != null &&
    childrenInfo[familyMemberIndex][keyName2] != null
  ) {
    textValue = childrenInfo[familyMemberIndex][keyName2]
  }
  const initTextValue = textValue ?? ''
  const [inputTextValue, setInputTextValue] = React.useState<string>('')

  React.useEffect(() => {
    if (inputTextValue !== initTextValue) {
      setInputTextValue(initTextValue)
    }
  }, [initTextValue])

  const onBlur = (): void => {
    if (initTextValue !== inputTextValue && setCustomer) {
      const familyMemberInfo = customer[keyName1]
      if (familyMemberInfo != null) {
        familyMemberInfo[familyMemberIndex][keyName2] = inputTextValue
        customer[keyName1] = familyMemberInfo
      }
      setCustomer({ ...customer })
    }
  }

  return (
    <>
      <TextField
        id={keyName2}
        label={label}
        variant="standard"
        value={inputTextValue}
        sx={sx}
        onChange={(event) => {
          setInputTextValue(event.target.value)
        }}
        onBlur={onBlur}
      />
    </>
  )
}
