import React from 'react'

export const IndentText: React.FC<string | undefined> = (
  text: string | undefined
) => {
  const indentedText = (text ?? '').split(/(\n)/).map((item, index) => {
    return (
      <React.Fragment key={index}>
        {item.match(/\n/) ? <br /> : item}
      </React.Fragment>
    )
  })
  return <div>{indentedText}</div>
}
