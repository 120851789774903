import type { Dayjs } from 'dayjs'
import type { SelectChangeEvent } from '@mui/material'
import type {
  Customer,
  ParentDateHeaderKey,
  ParentSelectHeaderKey,
  CustomerDateHeaderKey,
  CustomerBoolHeaderKey,
  CustomerSelectHeaderKey,
  CustomerCheckBoxHeaderKey,
  HandleChangeCustomerProps,
  HandleChangeCustomerParentInfoProps,
  HandleChangeCustomerChildProps,
  HandleChangeCustomerFamilyMemberProps,
  HandleChangeCustomerCompromisePointProps,
  ParentInfoRootKey,
  ChildDateHeaderKey,
  ChildSelectHeaderKey,
  ChildInfoRootKey,
  FamilyMemberInfoRootKey,
  FamilyMemberDateHeaderKey,
} from '../../models/Customer'
import type { Gender } from '../../models/SelectCandidate'
import { dateFormat } from '../../fixture/common/formOptions'

// Date
export const handleChangeDate =
  (props: HandleChangeCustomerProps<CustomerDateHeaderKey>) =>
  (newValue: Dayjs | null): void => {
    const { customer, key, setCustomer } = props
    const dateValue = newValue?.format(dateFormat)
    setCustomer({ ...customer, [key]: dateValue })
  }

export const handleChangeParentInfoDate =
  (
    props: HandleChangeCustomerParentInfoProps<
      ParentInfoRootKey,
      ParentDateHeaderKey
    >
  ) =>
  (newValue: Dayjs | null): void => {
    const { customer, key1, key2, setCustomer } = props
    const dateValue = newValue?.format(dateFormat)
    const parentInfo = customer[key1]
    if (parentInfo != null) {
      parentInfo[key2] = dateValue
      customer[key1] = parentInfo
    } else {
      customer[key1] = { [key2]: dateValue }
    }
    setCustomer({ ...customer })
  }

export const handleChangeChildInfoDate =
  (
    props: HandleChangeCustomerChildProps<ChildInfoRootKey, ChildDateHeaderKey>
  ) =>
  (newValue: Dayjs | null): void => {
    const { key1, key2, childIndex, customer, setCustomer } = props
    const dateValue = newValue?.format(dateFormat)
    const childrenInfo = customer[key1]
    if (childrenInfo != null) {
      childrenInfo[childIndex][key2] = dateValue
      customer[key1] = childrenInfo
    }
    setCustomer({ ...customer })
  }

export const handleChangeFamilyMemberInfoDate =
  (
    props: HandleChangeCustomerFamilyMemberProps<
      FamilyMemberInfoRootKey,
      FamilyMemberDateHeaderKey
    >
  ) =>
  (newValue: Dayjs | null): void => {
    const { key1, key2, familyMemberIndex, customer, setCustomer } = props
    const dateValue = newValue?.format(dateFormat)
    const familyMemberInfo = customer[key1]
    if (familyMemberInfo != null) {
      familyMemberInfo[familyMemberIndex][key2] = dateValue
      customer[key1] = familyMemberInfo
    }
    setCustomer({ ...customer })
  }

// Select
export const handleChangeSelect =
  (props: HandleChangeCustomerProps<CustomerSelectHeaderKey>) =>
  (event: SelectChangeEvent) => {
    const { customer, key, setCustomer } = props
    setCustomer({ ...customer, [key]: event.target.value })
  }

export const handleChangeParentInfoSelect =
  (
    props: HandleChangeCustomerParentInfoProps<
      ParentInfoRootKey,
      ParentSelectHeaderKey
    >
  ) =>
  (event: SelectChangeEvent) => {
    const { customer, key1, key2, setCustomer } = props
    const parentInfo = customer[key1]
    if (parentInfo != null) {
      customer[key1] = { ...parentInfo, [key2]: event.target.value }
    } else {
      customer[key1] = { [key2]: event.target.value }
    }
    setCustomer({ ...customer })
  }

export const handleChangeChildInfoSelect =
  (
    props: HandleChangeCustomerChildProps<
      ChildInfoRootKey,
      ChildSelectHeaderKey
    >
  ) =>
  (event: SelectChangeEvent) => {
    const { customer, key1, key2, childIndex, setCustomer } = props
    const childInfo = customer[key1]
    if (childInfo != null) {
      childInfo[childIndex][key2] = event.target.value as Gender
      customer[key1] = childInfo
    }
    setCustomer({ ...customer })
  }

// Boolean
export const handleChangeBool =
  (props: HandleChangeCustomerProps<CustomerBoolHeaderKey>) =>
  (event: React.ChangeEvent<HTMLInputElement>) => {
    const { customer, key, setCustomer } = props
    const incomming = event.target.value
    let newCustomer: Customer = {}
    if (incomming === '') {
      delete customer[key]
      newCustomer = { ...customer }
    } else if (incomming === 'true') {
      newCustomer = { ...customer, [key]: true }
    } else if (incomming === 'false') {
      newCustomer = { ...customer, [key]: false }
    }
    setCustomer(newCustomer)
  }

// CheckBox
export const handleChangeCheckbox =
  (
    props: HandleChangeCustomerCompromisePointProps<CustomerCheckBoxHeaderKey>
  ) =>
  (event: React.ChangeEvent<HTMLInputElement>) => {
    const { customer, key1, key2, setCustomer } = props
    const item = customer[key1]
    if (item != null) {
      item[key2] = event.target.checked
      customer[key1] = item
    } else {
      customer[key1] = { [key2]: event.target.checked }
    }
    setCustomer({ ...customer })
  }
