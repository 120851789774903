import type { Appointment } from '../models/Appointment'
import dayjs from '../vendors/Dayjs'
import { datetimeFormat } from '../fixture/common/formOptions'

const sampleDate = dayjs().add(-2, 'day')
const formattedSampleDate = sampleDate.format(datetimeFormat)

export const mockAppointments: Appointment[] = [
  {
    id: '1:1',
    meta: 'Appointment',
    customer_id: '1',
    appointment_status: 'application',
    appointment_date: formattedSampleDate,
    rank: 'a',
    appointment_family_number: '4/4',
    inquiry_price: 80000000,
    floor_plan: 'one_dk',
    monthly_repayment_amount: '10万以内',
    annual_income: 5000000,
    own_resources: '100',
    appointment_count: 1,
    media: 'SUMO',
    negotiation_person: '渡辺',
    remarks: 'ハチ公前',
    preparation: 'done',
    preparation_check: true,
    neck: '主人来ない',
    appointment_result: 'yes',
    appointment_result_reason:
      '他社にかなり問い合わせをしている、抜けなかった。年収、条件的に都内だと厳しい。問物予算オーバー。',
    family_name: '山田',
    name: '太郎',
    family_name_kana: 'ヤマダ',
    name_kana: 'タロウ',
    line_resistration: true,
    birth_date: '1988-11-25',
    gender: 'male',
    address_ward: '台東区',
    created_at: 1234567890,
    updated_at: 1234567890,
  },
  {
    id: '1:2',
    meta: 'Appointment',
    customer_id: '1',
    appointment_status: 'contracted',
    appointment_date: formattedSampleDate,
    rank: 'a',
    appointment_family_number: '4/4',
    inquiry_price: 70000000,
    floor_plan: 'one_dk',
    monthly_repayment_amount: '20万以内',
    annual_income: 5000000,
    own_resources: '売却益',
    appointment_count: 2,
    media: 'SUMO',
    negotiation_person: '渡辺',
    remarks: 'ハチ公前',
    preparation: 'done',
    preparation_check: true,
    neck: '主人来ない',
    appointment_result: 'yes',
    appointment_result_reason:
      '他社にかなり問い合わせをしている、抜けなかった。年収、条件的に都内だと厳しい。問物予算オーバー。',
    family_name: '山田',
    name: '太郎',
    family_name_kana: 'ヤマダ',
    name_kana: 'タロウ',
    line_resistration: true,
    // birth_date: '1988-11-25',
    gender: 'male',
    address_ward: '台東区',
    created_at: 1234567890,
    updated_at: 1234567890,
  },
  {
    id: '2:1',
    meta: 'Appointment',
    customer_id: '2',
    appointment_status: 'canceled_appointment',
    appointment_date: formattedSampleDate,
    rank: 'b',
    appointment_family_number: '4/4',
    // inquiry_price: 70000000,
    floor_plan: 'one_dk',
    monthly_repayment_amount: '20万以内',
    annual_income: 5000000,
    own_resources: '未',
    appointment_count: 2,
    media: 'SUMO',
    negotiation_person: '渡辺',
    remarks: 'ハチ公前',
    preparation: 'done',
    preparation_check: true,
    neck: '主人来ない',
    appointment_result: 'yes',
    appointment_result_reason:
      '他社にかなり問い合わせをしている、抜けなかった。年収、条件的に都内だと厳しい。問物予算オーバー。',
    family_name: '田中',
    name: '花子',
    family_name_kana: 'タナカ',
    name_kana: 'ハナコ',
    line_resistration: true,
    birth_date: '1985-10-5 00:00:00',
    // gender: 'female',
    address_ward: '台東区',
    created_at: 1234567890,
    updated_at: 1234567890,
  },
  {
    id: '3:1',
    meta: 'Appointment',
    customer_id: '3',
    appointment_status: 'after_apo_chasing',
    appointment_date: '2022-12-11T00:00:00+09:00',
    rank: 'c',
    appointment_family_number: '4/4',
    inquiry_price: 70000000,
    // floor_plan: 'one_dk',
    monthly_repayment_amount: '20万以内',
    annual_income: 5000000,
    own_resources: '100',
    appointment_count: 2,
    media: 'SUMO',
    negotiation_person: '渡辺',
    remarks: 'ハチ公前',
    preparation: 'done',
    preparation_check: true,
    neck: '主人来ない',
    appointment_result: 'yes',
    appointment_result_reason:
      '他社にかなり問い合わせをしている、抜けなかった。年収、条件的に都内だと厳しい。問物予算オーバー。',
    family_name: '犬塚',
    name: '四郎',
    family_name_kana: 'イヌツカ',
    name_kana: 'シロウ',
    line_resistration: true,
    birth_date: '1995-5-2 00:00:00',
    // gender: 'male',
    // address_ward: '台東区',
    created_at: 1234567890,
    updated_at: 1234567890,
  },
  {
    id: '4:1',
    meta: 'Appointment',
    customer_id: '4',
    appointment_status: 'not_chasing',
    appointment_date: '2022-12-11T00:00:00+09:00',
    rank: 'a',
    appointment_family_number: '4/4',
    // inquiry_price: 70000000,
    // floor_plan: 'one_dk',
    monthly_repayment_amount: '20万以内',
    annual_income: 5000000,
    own_resources: '100',
    appointment_count: 2,
    media: 'SUMO',
    negotiation_person: '渡辺',
    remarks: 'ハチ公前',
    preparation: 'done',
    preparation_check: true,
    neck: '主人来ない',
    appointment_result: 'yes',
    appointment_result_reason:
      '他社にかなり問い合わせをしている、抜けなかった。年収、条件的に都内だと厳しい。問物予算オーバー。',
    family_name: '猫田',
    name: '健二',
    family_name_kana: 'ネコタ',
    name_kana: 'ケンジ',
    line_resistration: true,
    birth_date: '1990-12-25',
    gender: 'male',
    address_ward: '台東区',
    created_at: 1234567890,
    updated_at: 1234567890,
  },
]
