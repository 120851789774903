import React from 'react'
import { TextField } from '@mui/material'
import {
  DateTimePicker,
  DatePicker,
  MobileDateTimePicker,
  MobileDatePicker,
} from '@mui/x-date-pickers'
import dayjs from '../../../../vendors/Dayjs'
import type {
  Customer,
  CustomerDateHeaderKey,
  ParentDateHeaderKey,
  ParentInfoRootKey,
  ChildDateHeaderKey,
  FamilyMemberInfoRootKey,
  FamilyMemberDateHeaderKey,
} from '../../../../models/Customer'
import {
  handleChangeDate,
  handleChangeParentInfoDate,
  handleChangeChildInfoDate,
  handleChangeFamilyMemberInfoDate,
} from '../../../../utils/customer/customerStateHandler'

interface DateTimePickerIconProps {
  label?: string
  keyName: CustomerDateHeaderKey
  customer: Customer
  setCustomer: React.Dispatch<React.SetStateAction<Customer>>
}

interface DatePickerParentInfoIconProps {
  label?: string
  keyName1: ParentInfoRootKey
  keyName2: ParentDateHeaderKey
  customer: Customer
  setCustomer: React.Dispatch<React.SetStateAction<Customer>>
}

interface DatePickerChildInfoIconProps {
  label?: string
  keyName1: 'children_info'
  keyName2: ChildDateHeaderKey
  childIndex: number
  customer: Customer
  setCustomer: React.Dispatch<React.SetStateAction<Customer>>
}

interface DatePickerFamilyMemberInfoIconProps {
  label?: string
  keyName1: FamilyMemberInfoRootKey
  keyName2: FamilyMemberDateHeaderKey
  familyMemberIndex: number
  customer: Customer
  setCustomer: React.Dispatch<React.SetStateAction<Customer>>
}

export const DateTimePickerIcon: React.FC<DateTimePickerIconProps> = (
  props: DateTimePickerIconProps
) => {
  const { label, keyName, customer, setCustomer } = props

  return (
    <>
      <DateTimePicker
        label={label}
        openTo="day"
        value={customer[keyName] != null ? dayjs(customer[keyName]) : null}
        onChange={handleChangeDate({
          key: keyName,
          customer,
          setCustomer,
        })}
        renderInput={({ inputRef, inputProps, InputProps }) => (
          <div className="flex items-center">
            <input ref={inputRef} {...inputProps} className="w-0" />
            {InputProps?.endAdornment}
          </div>
        )}
        closeOnSelect={true}
        disableMaskedInput={true}
      />
    </>
  )
}

export const DatePickerIcon: React.FC<DateTimePickerIconProps> = (
  props: DateTimePickerIconProps
) => {
  const { label, keyName, customer, setCustomer } = props

  return (
    <>
      <DatePicker
        label={label}
        openTo="year"
        value={customer[keyName] != null ? dayjs(customer[keyName]) : null}
        onChange={handleChangeDate({
          key: keyName,
          customer,
          setCustomer,
        })}
        renderInput={({ inputRef, inputProps, InputProps }) => (
          <div className="flex items-center">
            <input ref={inputRef} {...inputProps} className="w-0" />
            {InputProps?.endAdornment}
          </div>
        )}
        closeOnSelect={true}
        disableMaskedInput={true}
      />
    </>
  )
}

export const DatePickerCustomerInfoIcon: React.FC<
  DatePickerParentInfoIconProps
> = (props: DatePickerParentInfoIconProps) => {
  const { label, keyName1, keyName2, customer, setCustomer } = props
  let dateValue
  const parentInfo = customer[keyName1]
  if (parentInfo != null && !parentInfo[keyName2] != null) {
    dateValue = parentInfo[keyName2]
  }
  return (
    <>
      <DatePicker
        label={label}
        openTo="year"
        value={dateValue != null ? dayjs(dateValue) : null}
        onChange={handleChangeParentInfoDate({
          key1: keyName1,
          key2: keyName2,
          customer,
          setCustomer,
        })}
        renderInput={({ inputRef, inputProps, InputProps }) => (
          <div className="flex items-center">
            <input ref={inputRef} {...inputProps} className="w-0" />
            {InputProps?.endAdornment}
          </div>
        )}
        closeOnSelect={true}
        disableMaskedInput={true}
      />
    </>
  )
}

export const DatePickerChildInfoIcon: React.FC<DatePickerChildInfoIconProps> = (
  props: DatePickerChildInfoIconProps
) => {
  const { label, keyName1, keyName2, customer, setCustomer, childIndex } = props
  let dateValue
  const childrenInfo = customer[keyName1]
  if (
    childrenInfo != null &&
    !childrenInfo[childIndex] != null &&
    childrenInfo[childIndex][keyName2] != null
  ) {
    dateValue = childrenInfo[childIndex][keyName2]
  }
  return (
    <>
      <DatePicker
        label={label}
        openTo="year"
        value={dateValue != null ? dayjs(dateValue) : null}
        onChange={handleChangeChildInfoDate({
          key1: keyName1,
          key2: keyName2,
          childIndex,
          customer,
          setCustomer,
        })}
        renderInput={({ inputRef, inputProps, InputProps }) => (
          <div className="flex items-center">
            <input ref={inputRef} {...inputProps} className="w-0" />
            {InputProps?.endAdornment}
          </div>
        )}
        closeOnSelect={true}
        disableMaskedInput={true}
      />
    </>
  )
}

export const DatePickerFamilyMemberInfoIcon: React.FC<
  DatePickerFamilyMemberInfoIconProps
> = (props: DatePickerFamilyMemberInfoIconProps) => {
  const {
    label,
    keyName1,
    keyName2,
    customer,
    setCustomer,
    familyMemberIndex,
  } = props
  let dateValue
  const familyMemberInfo = customer[keyName1]
  if (
    familyMemberInfo != null &&
    !familyMemberInfo[familyMemberIndex] != null &&
    familyMemberInfo[familyMemberIndex][keyName2] != null
  ) {
    dateValue = familyMemberInfo[familyMemberIndex][keyName2]
  }
  return (
    <>
      <DatePicker
        label={label}
        openTo="year"
        value={dateValue != null ? dayjs(dateValue) : null}
        onChange={handleChangeFamilyMemberInfoDate({
          key1: keyName1,
          key2: keyName2,
          familyMemberIndex,
          customer,
          setCustomer,
        })}
        renderInput={({ inputRef, inputProps, InputProps }) => (
          <div className="flex items-center">
            <input ref={inputRef} {...inputProps} className="w-0" />
            {InputProps?.endAdornment}
          </div>
        )}
        closeOnSelect={true}
        disableMaskedInput={true}
      />
    </>
  )
}

export const MobileDateTimePickerForm: React.FC<DateTimePickerIconProps> = (
  props: DateTimePickerIconProps
) => {
  const { label, keyName, customer, setCustomer } = props

  return (
    <>
      <MobileDateTimePicker
        label={label}
        openTo="day"
        value={customer[keyName] != null ? dayjs(customer[keyName]) : null}
        onChange={handleChangeDate({
          key: keyName,
          customer,
          setCustomer,
        })}
        renderInput={(params) => <TextField {...params} />}
        closeOnSelect={true}
        disableMaskedInput={true}
      />
    </>
  )
}

export const MobileDatePickerForm: React.FC<DateTimePickerIconProps> = (
  props: DateTimePickerIconProps
) => {
  const { label, keyName, customer, setCustomer } = props

  return (
    <>
      <MobileDatePicker
        label={label}
        openTo="year"
        value={customer[keyName] != null ? dayjs(customer[keyName]) : null}
        onChange={handleChangeDate({
          key: keyName,
          customer,
          setCustomer,
        })}
        renderInput={(params) => <TextField {...params} />}
        closeOnSelect={true}
        disableMaskedInput={true}
      />
    </>
  )
}

export const MobileDatePickerCustomerInfoForm: React.FC<
  DatePickerParentInfoIconProps
> = (props: DatePickerParentInfoIconProps) => {
  const { label, keyName1, keyName2, customer, setCustomer } = props
  let dateValue
  const parentInfo = customer[keyName1]
  if (parentInfo != null && !parentInfo[keyName2] != null) {
    dateValue = parentInfo[keyName2]
  }
  return (
    <>
      <MobileDatePicker
        label={label}
        openTo="year"
        value={dateValue != null ? dayjs(dateValue) : null}
        onChange={handleChangeParentInfoDate({
          key1: keyName1,
          key2: keyName2,
          customer,
          setCustomer,
        })}
        renderInput={(params) => <TextField {...params} />}
        closeOnSelect={true}
        disableMaskedInput={true}
      />
    </>
  )
}

export const MobileDatePickerChildInfoForm: React.FC<
  DatePickerChildInfoIconProps
> = (props: DatePickerChildInfoIconProps) => {
  const { label, keyName1, keyName2, customer, setCustomer, childIndex } = props
  let dateValue
  const childrenInfo = customer[keyName1]
  if (
    childrenInfo != null &&
    !childrenInfo[childIndex] != null &&
    childrenInfo[childIndex][keyName2] != null
  ) {
    dateValue = childrenInfo[childIndex][keyName2]
  }
  return (
    <>
      <MobileDatePicker
        label={label}
        openTo="year"
        value={dateValue != null ? dayjs(dateValue) : null}
        onChange={handleChangeChildInfoDate({
          key1: keyName1,
          key2: keyName2,
          childIndex,
          customer,
          setCustomer,
        })}
        renderInput={(params) => <TextField {...params} />}
        closeOnSelect={true}
        disableMaskedInput={true}
      />
    </>
  )
}

export const MobileDatePickerFamilyMemberInfoForm: React.FC<
  DatePickerFamilyMemberInfoIconProps
> = (props: DatePickerFamilyMemberInfoIconProps) => {
  const {
    label,
    keyName1,
    keyName2,
    customer,
    setCustomer,
    familyMemberIndex,
  } = props
  let dateValue
  const familyMemberInfo = customer[keyName1]
  if (
    familyMemberInfo != null &&
    !familyMemberInfo[familyMemberIndex] != null &&
    familyMemberInfo[familyMemberIndex][keyName2] != null
  ) {
    dateValue = familyMemberInfo[familyMemberIndex][keyName2]
  }
  return (
    <>
      <MobileDatePicker
        label={label}
        openTo="year"
        value={dateValue != null ? dayjs(dateValue) : null}
        onChange={handleChangeFamilyMemberInfoDate({
          key1: keyName1,
          key2: keyName2,
          familyMemberIndex,
          customer,
          setCustomer,
        })}
        renderInput={(params) => <TextField {...params} />}
        closeOnSelect={true}
        disableMaskedInput={true}
      />
    </>
  )
}
