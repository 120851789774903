import React from 'react'
import { TextField } from '@mui/material'
import type {
  Inquiry,
  InquiryNumberHeaderKey,
} from '../../../../models/Inquiry'
import { handleChangeNumber } from '../../../../utils/inquiry/inquiryStateHandler'

interface NumberInputFormProps {
  label?: string
  keyName: InquiryNumberHeaderKey
  inquiry: Inquiry
  setInquiry: React.Dispatch<React.SetStateAction<Inquiry>>
  classString?: string
  disabled?: boolean
}

export const NumberInputForm: React.FC<NumberInputFormProps> = (
  props: NumberInputFormProps
) => {
  const { label, keyName, inquiry, setInquiry, classString, disabled } = props

  return (
    <>
      <TextField
        id={keyName}
        label={label}
        variant="standard"
        value={inquiry[keyName] ?? ''}
        className={classString}
        onChange={handleChangeNumber({
          key: keyName,
          inquiry,
          setInquiry,
        })}
        inputProps={{
          inputMode: 'numeric',
          pattern: '[0-9]*',
        }}
        disabled={disabled}
      />
    </>
  )
}
