import type { Inquiry } from '../../models/Inquiry'
import type { SearchCustomerQuery } from '../../vendors/graphql/generated'

export const graphqlCustomersToInquiriesConverter = (props: {
  data: SearchCustomerQuery
}): Inquiry[] => {
  const { data } = props

  const customers = data.searchCustomer?.customers
  let inquiries: Inquiry[]
  if (customers) {
    inquiries = customers.map((customer) => {
      const item: Inquiry = {}
      if (customer == null) {
        return item
      }
      if (customer.id) {
        item.id = customer.id
      }
      if (customer.meta) {
        item.meta = customer.meta
      }
      if (customer.inquiry_date) {
        item.inquiry_date = customer.inquiry_date
      }
      if (customer.customer_info) {
        const customerInfo = customer.customer_info
        if (customerInfo.family_name) {
          item.family_name = customerInfo.family_name
        }
        if (customerInfo.name) {
          item.name = customerInfo.name
        }
        if (customerInfo.family_name_kana) {
          item.family_name_kana = customerInfo.family_name_kana
        }
        if (customerInfo.name_kana) {
          item.name_kana = customerInfo.name_kana
        }
      }
      if (customer.nearest_station) {
        item.nearest_station = customer.nearest_station
      }
      if (customer.inquiry_property_name) {
        item.property_name = customer.inquiry_property_name
      }
      if (customer.year_of_construction) {
        item.year_of_construction = customer.year_of_construction
      }
      if (customer.inquiry_property_price) {
        item.price = customer.inquiry_property_price
      }
      if (customer.inquiry_property_floor_plan) {
        item.floor_plan = customer.inquiry_property_floor_plan
      }
      if (customer.media) {
        item.media = customer.media
      }
      if (customer.line_resistration) {
        item.line_resistration = customer.line_resistration
      }
      if (customer.memo) {
        item.memo = customer.memo
      }
      if (customer.reaction) {
        item.reaction = customer.reaction
      }
      if (customer.appointment_status) {
        item.appointment_status = customer.appointment_status
      }
      return item
    })
  } else {
    inquiries = []
  }
  return inquiries
}
