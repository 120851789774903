import React from 'react'
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from '@mui/material'
import type { SxProps } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import ModeEditIcon from '@mui/icons-material/ModeEdit'
import AddBoxIcon from '@mui/icons-material/AddBox'
import EditOffIcon from '@mui/icons-material/EditOff'
import SendIcon from '@mui/icons-material/Send'
import LaunchIcon from '@mui/icons-material/Launch'
import { CustomerAppointmentModal } from '../../components/customer/CustomerAppointmentModal'
import type { Appointment } from '../../../models/Appointment'
import type { ModalType } from '../../../models/Common'
import type { Customer } from '../../../models/Customer'
import { appointmentHeaders } from '../../../fixture/appointment/formOptions'
import { appointmentsParser } from '../../../utils/appointment/appointmentViewParser'
import { TableCellAppointmentFields } from '../../components/appointment/TableCellAppointmentFields'
import { IndentText } from '../../components/common/IndentText'
import { appointmentStatusColorSelector } from '../../../utils/common/appointmentStatusColorSelector'
import {
  useSearchAppointmentQuery,
  usePutAppointmentMutation,
  useUpdateCustomerMutation,
} from '../../../vendors/graphql/generated'
import { appointmentGraphqlConverter } from '../../../utils/appointment/appointmentGraphqlConverter'
import { appointmentInputConverter } from '../../../utils/appointment/appointmentInputConverter'
import { appoToUpCusInputConverter } from '../../../utils/customer/appoToUpCusInputConverter'

interface CustomerChartAppointmentTableProps {
  title: string
  customer: Customer
}

export const CustomerChartAppointmentTable: React.FC<
  CustomerChartAppointmentTableProps
> = (props: CustomerChartAppointmentTableProps) => {
  const { title, customer } = props

  const { data, refetch } = useSearchAppointmentQuery({
    variables: {
      conditions: {
        customer_id: customer.id ?? '',
      },
    },
  })
  const [putAppointment] = usePutAppointmentMutation()
  const [updateCustomer] = useUpdateCustomerMutation()

  const handleRefetch = async (): Promise<void> => {
    await refetch()
  }

  const [appointments, setAppointments] = React.useState([] as Appointment[])

  const initAppointment = {}

  // modalモード
  const [inputCustomerAppointment, setInputCustomerAppointment] =
    React.useState<Appointment>(initAppointment)
  const [openAppointmentModal, setOpenAppointmentModal] = React.useState(false)
  const [appointmentModalType, setAppointmentModalType] =
    React.useState<ModalType>('create')

  // editモード
  const [inputEditAppointment, setInputEditAppointment] =
    React.useState<Appointment>(initAppointment)
  const [editModes, setEditModes] = React.useState<boolean[]>([])

  const openAppointmentModalHandler = (
    appointment: Appointment,
    type: ModalType
  ): void => {
    console.log('appointment', appointment)
    setInputCustomerAppointment(appointment)
    setOpenAppointmentModal(true)
    setAppointmentModalType(type)
  }
  const handleAppointmentModalClose = (): void => {
    setOpenAppointmentModal(false)
  }

  const toggleEditHandler = (
    appointment: Appointment,
    index: number,
    editModes: boolean[]
  ): void => {
    setInputEditAppointment(appointment)
    resetEditModes(index, editModes)
  }
  const handleUpdateAppointment = async (
    index: number,
    editModes: boolean[]
  ): Promise<void> => {
    console.log('updateAppointment param: ', inputEditAppointment)
    const inputAppointment = appointmentInputConverter({
      appointment: inputEditAppointment,
    })
    const key = {
      id: inputEditAppointment.id ?? '',
      meta: inputEditAppointment.meta ?? '',
    }
    const inputUpdateCustomer = appoToUpCusInputConverter({
      appointment: inputEditAppointment,
    })
    const updateCustomerKey = {
      id: inputEditAppointment.customer_id ?? '',
      meta: 'Customer',
    }
    await putAppointment({ variables: { input: inputAppointment, key } })
    await updateCustomer({
      variables: {
        input: inputUpdateCustomer,
        key: updateCustomerKey,
        type: 'appointment',
      },
    })
    setInputEditAppointment(initAppointment)
    resetEditModes(index, editModes)
    setTimeout(handleRefetch, 500)
  }

  const resetEditModes = (index: number, editModes: boolean[]): void => {
    const toggleEditBool = !editModes[index]
    editModes.fill(false)
    editModes[index] = toggleEditBool
    setEditModes([...editModes])
  }
  const appointmentViews = appointmentsParser({
    appointments,
  })

  React.useEffect(() => {
    if (data) {
      const appointments = appointmentGraphqlConverter({
        data,
      })
      setAppointments(appointments)
      setEditModes(Array(appointments.length).fill(false))
    }
  }, [data, setAppointments, setEditModes])

  return (
    <div>
      <CustomerAppointmentModal
        title={title}
        open={openAppointmentModal}
        handleClose={handleAppointmentModalClose}
        appointment={inputCustomerAppointment}
        customer={customer}
        type={appointmentModalType}
        handleRefetch={handleRefetch}
      />
      <div className="flex justify-start mb-4">
        <Typography className="pt-4" variant="subtitle1">
          {`${title}一覧`}
        </Typography>
        <div className="pt-2 pl-2">
          <IconButton
            onClick={() => {
              openAppointmentModalHandler(initAppointment, 'create')
            }}
          >
            <AddBoxIcon sx={{}} />
          </IconButton>
        </div>
      </div>

      <TableContainer className="max-w-full max-h-full">
        <Table size="small" aria-label="simple table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ border: 0 }} align="center"></TableCell>
              {appointmentHeaders.map((header, headerIndex) => {
                let headerStyle: SxProps = {
                  ...header.headSx,
                  borderTop: 1,
                  borderRight: 1,
                  borderBottom: 1,
                }
                if (headerIndex === 0) {
                  headerStyle = {
                    ...headerStyle,
                    borderLeft: 1,
                  }
                } else {
                  headerStyle = {
                    ...headerStyle,
                    borderLeft: 0,
                  }
                }
                return (
                  <TableCell sx={headerStyle} align="center" key={headerIndex}>
                    {IndentText(header.name)}
                  </TableCell>
                )
              })}
              <TableCell sx={{ border: 0 }} align="center"></TableCell>
              <TableCell sx={{ border: 0 }} align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              {appointments.map((appointment, appointmentIndex) => (
                <TableRow key={appointmentIndex}>
                  <TableCell sx={{ px: 0, border: 0 }} align="center">
                    <div className="flex">
                      <IconButton
                        onClick={() => {
                          toggleEditHandler(
                            appointment,
                            appointmentIndex,
                            editModes
                          )
                        }}
                      >
                        {editModes[appointmentIndex] ? (
                          <EditOffIcon />
                        ) : (
                          <ModeEditIcon />
                        )}
                      </IconButton>
                      {editModes[appointmentIndex] && (
                        <IconButton
                          onClick={async () => {
                            await handleUpdateAppointment(
                              appointmentIndex,
                              editModes
                            )
                          }}
                        >
                          <SendIcon />
                        </IconButton>
                      )}
                    </div>
                  </TableCell>
                  {appointmentHeaders.map((header, bodyIndex) => {
                    const appointmentView = appointmentViews[appointmentIndex]
                    let bodyStyle: SxProps = {
                      whiteSpace: 'nowrap',
                      borderBottom: 1,
                      borderRight: 1,
                    }
                    if (bodyIndex === 0) {
                      bodyStyle = {
                        ...bodyStyle,
                        borderLeft: 1,
                      }
                    } else {
                      bodyStyle = {
                        ...bodyStyle,
                        borderLeft: 0,
                      }
                    }
                    return (
                      <TableCell
                        sx={bodyStyle}
                        align="center"
                        key={`${appointmentIndex}-${header.key}`}
                        className={appointmentStatusColorSelector(
                          appointment.appointment_status
                        )}
                        onDoubleClick={() => {
                          !editModes[appointmentIndex] &&
                            toggleEditHandler(
                              appointment,
                              appointmentIndex,
                              editModes
                            )
                        }}
                      >
                        {editModes[appointmentIndex] ? (
                          <TableCellAppointmentFields
                            header={header}
                            appointment={inputEditAppointment}
                            setAppointment={setInputEditAppointment}
                          />
                        ) : (
                          <div>{IndentText(appointmentView[header.key])}</div>
                        )}
                      </TableCell>
                    )
                  })}
                  <TableCell sx={{ border: 0 }} align="center">
                    <IconButton
                      onClick={() => {
                        openAppointmentModalHandler(appointment, 'update')
                      }}
                    >
                      <LaunchIcon sx={{}} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </LocalizationProvider>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
