import { Select, MenuItem, FormControl, InputLabel } from '@mui/material'
import type {
  Customer,
  CustomerSelectHeaderKey,
  ParentInfoRootKey,
  ParentSelectHeaderKey,
  ChildSelectHeaderKey,
  ChildInfoRootKey,
} from '../../../../models/Customer'
import { menuItemsDict } from '../../../../fixture/common/formOptions'
import {
  handleChangeSelect,
  handleChangeParentInfoSelect,
  handleChangeChildInfoSelect,
} from '../../../../utils/customer/customerStateHandler'

interface SelectFormProps {
  label?: string
  keyName: CustomerSelectHeaderKey
  customer: Customer
  setCustomer: React.Dispatch<React.SetStateAction<Customer>>
  classString?: string
}

interface SelectParentInfoFormProps {
  label?: string
  keyName1: ParentInfoRootKey
  keyName2: ParentSelectHeaderKey
  customer: Customer
  setCustomer: React.Dispatch<React.SetStateAction<Customer>>
  classString?: string
}

interface SelectChildInfoFormProps {
  label?: string
  keyName1: ChildInfoRootKey
  keyName2: ChildSelectHeaderKey
  childIndex: number
  customer: Customer
  setCustomer: React.Dispatch<React.SetStateAction<Customer>>
  classString?: string
}

export const SelectForm: React.FC<SelectFormProps> = (
  props: SelectFormProps
) => {
  const { label, keyName, customer, setCustomer, classString } = props
  return (
    <div className={classString}>
      <FormControl>
        <InputLabel id={`select-${keyName}-labelId`}>{label}</InputLabel>
        <Select
          id={`select-${keyName}-id`}
          labelId={`select-${keyName}-labelId`}
          variant="standard"
          value={customer[keyName] != null ? (customer[keyName] as string) : ''}
          onChange={handleChangeSelect({
            key: keyName,
            customer,
            setCustomer,
          })}
        >
          <MenuItem value="">
            <em>未選択</em>
          </MenuItem>
          {menuItemsDict[keyName].map((menuItem, menui) => (
            <MenuItem value={menuItem.key} key={`menui-${menui}`}>
              {menuItem.value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}

export const SelectParentInfoForm: React.FC<SelectParentInfoFormProps> = (
  props: SelectParentInfoFormProps
) => {
  const { label, keyName1, keyName2, customer, setCustomer, classString } =
    props
  let selectValue
  const parentInfo = customer[keyName1]
  if (parentInfo != null && !parentInfo[keyName2] != null) {
    selectValue = parentInfo[keyName2]
  }
  return (
    <div className={classString}>
      <FormControl>
        <InputLabel id={`select-${keyName2}-labelId`}>{label}</InputLabel>
        <Select
          id={`select-${keyName2}-id`}
          labelId={`select-${keyName2}-labelId`}
          variant="standard"
          value={selectValue != null ? selectValue : ''}
          onChange={handleChangeParentInfoSelect({
            key1: keyName1,
            key2: keyName2,
            customer,
            setCustomer,
          })}
        >
          <MenuItem value="">
            <em>未選択</em>
          </MenuItem>
          {menuItemsDict[keyName2].map((menuItem, menui) => (
            <MenuItem value={menuItem.key} key={`menui-${menui}`}>
              {menuItem.value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}

export const SelectChildInfoForm: React.FC<SelectChildInfoFormProps> = (
  props: SelectChildInfoFormProps
) => {
  const {
    label,
    keyName1,
    keyName2,
    childIndex,
    customer,
    setCustomer,
    classString,
  } = props
  let selectValue
  const childrenInfo = customer[keyName1]
  if (
    childrenInfo != null &&
    !childrenInfo[childIndex] != null &&
    childrenInfo[childIndex][keyName2] != null
  ) {
    selectValue = childrenInfo[childIndex][keyName2]
  }
  return (
    <div className={classString}>
      <FormControl>
        <InputLabel id={`select-${keyName2}-labelId`}>{label}</InputLabel>
        <Select
          id={`select-${keyName2}-id`}
          labelId={`select-${keyName2}-labelId`}
          variant="standard"
          value={selectValue != null ? selectValue : ''}
          onChange={handleChangeChildInfoSelect({
            key1: keyName1,
            key2: keyName2,
            childIndex,
            customer,
            setCustomer,
          })}
        >
          <MenuItem value="">
            <em>未選択</em>
          </MenuItem>
          {menuItemsDict[keyName2].map((menuItem, menui) => (
            <MenuItem value={menuItem.key} key={`menui-${menui}`}>
              {menuItem.value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}
