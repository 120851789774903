import { SelectForm } from '../../common/editForms/SelectForm'
import type { Customer, CustomerView } from '../../../../models/Customer'

interface CustomerChartAppointmentBlockProps {
  editCustomer: Customer
  customerView: CustomerView
  setEditCustomer: React.Dispatch<React.SetStateAction<Customer>>
  editMode: boolean
}

export const CustomerChartAppointmentBlock: React.FC<
  CustomerChartAppointmentBlockProps
> = (props: CustomerChartAppointmentBlockProps) => {
  const { customerView, editCustomer, setEditCustomer, editMode } = props

  const blockColor = 'bg-emerald-100'
  return (
    <>
      <div className="grid grid-cols-12 rounded-none">
        <div
          className={`relative col-start-1 col-span-2 flex items-center justify-center border border-current ${blockColor}`}
        >
          <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
            案内後ステータス
          </div>
          <div className="flex items-center h-14 justify-center">
            {editMode ? (
              <SelectForm
                keyName="appointment_status"
                customer={editCustomer}
                setCustomer={setEditCustomer}
                classString="pb-3"
              />
            ) : (
              customerView.appointment_status
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export const SmCustomerChartAppointmentBlock: React.FC<
  CustomerChartAppointmentBlockProps
> = (props: CustomerChartAppointmentBlockProps) => {
  const { customerView, editCustomer, setEditCustomer, editMode } = props

  const blockColor = 'bg-emerald-100'
  return (
    <>
      <div className="grid grid-cols-4 rounded-none">
        <div
          className={`relative col-start-1 col-span-2 flex items-center justify-center border border-current ${blockColor}`}
        >
          <div className="absolute top-0 left-0 px-2 font-semibold text-xs">
            案内後ステータス
          </div>
          <div className="flex items-center h-14 justify-center">
            {editMode ? (
              <SelectForm
                keyName="appointment_status"
                customer={editCustomer}
                setCustomer={setEditCustomer}
                classString="pb-3"
              />
            ) : (
              customerView.appointment_status
            )}
          </div>
        </div>
      </div>
    </>
  )
}
