import type { Customer, FamilyMemberView } from '../../../../models/Customer'
import { TextFieldFamilyMemberInfoForm } from '../../common/editForms/TextFieldForm'
import {
  DatePickerFamilyMemberInfoIcon,
  MobileDatePickerFamilyMemberInfoForm,
} from '../../common/editForms/DatePickersIcon'
import AddBoxIcon from '@mui/icons-material/AddBox'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import { IconButton } from '@mui/material'

interface FamilyMembersFormProps {
  familyMemberView: FamilyMemberView[] | undefined
  customerChart: Customer
  setCustomer: React.Dispatch<React.SetStateAction<Customer>>
  editMode: boolean
}

interface FamilyMemberFormProps {
  familyMemberView: FamilyMemberView
  customerChart: Customer
  setCustomer: React.Dispatch<React.SetStateAction<Customer>>
  editMode: boolean
  familyMemberIndex: number
}

interface handleAddRemoveFamilyMemberProps {
  customerChart: Customer
  setCustomer: React.Dispatch<React.SetStateAction<Customer>>
  familyMemberIndex: number
}

const handleAddFamilyMember = (
  props: handleAddRemoveFamilyMemberProps
): void => {
  const { customerChart, setCustomer, familyMemberIndex } = props
  const family = customerChart.family_member_info
  if (family) {
    family?.splice(familyMemberIndex + 1, 0, {})
  } else {
    customerChart.family_member_info = [{}]
  }
  setCustomer({ ...customerChart })
}

const handleRemoveFamilyMember = (
  props: handleAddRemoveFamilyMemberProps
): void => {
  const { customerChart, setCustomer, familyMemberIndex } = props
  const children = customerChart.family_member_info
  children?.splice(familyMemberIndex, 1)
  setCustomer({ ...customerChart })
}

export const FamilyMembersForm: React.FC<FamilyMembersFormProps> = (
  props: FamilyMembersFormProps
) => {
  const { familyMemberView, customerChart, setCustomer, editMode } = props
  const familyMemberInfo = customerChart.family_member_info
  return (
    <>
      {familyMemberInfo &&
        familyMemberView &&
        familyMemberInfo.map((_, index) => (
          <FamilyMemberForm
            key={index}
            familyMemberView={familyMemberView[index]}
            customerChart={customerChart}
            setCustomer={setCustomer}
            editMode={editMode}
            familyMemberIndex={index}
          />
        ))}
      {editMode &&
        (familyMemberInfo == null ||
          familyMemberView == null ||
          familyMemberInfo.length === 0) && (
          <>
            <div className="col-start-1 col-span-1 my-2 flex items-center justify-center">
              <IconButton
                onClick={() => {
                  handleAddFamilyMember({
                    customerChart,
                    setCustomer,
                    familyMemberIndex: 0,
                  })
                }}
              >
                <AddBoxIcon />
              </IconButton>
            </div>
            <div className="col-span-2 my-2 flex items-center justify-center">
              <div>家族を追加する</div>
            </div>
          </>
        )}
    </>
  )
}

const FamilyMemberForm: React.FC<FamilyMemberFormProps> = (
  props: FamilyMemberFormProps
) => {
  const {
    familyMemberView,
    customerChart,
    setCustomer,
    editMode,
    familyMemberIndex,
  } = props

  return (
    <>
      <div
        className={
          'col-start-1 col-span-2 relative flex items-center justify-center border-x border-b border-current bg-indigo-50'
        }
      >
        <div className="absolute top-1 left-1 py-1 px-2 font-semibold text-xs">
          続柄
        </div>
        <div className="py-4 px-4 text-base">
          {editMode ? (
            <TextFieldFamilyMemberInfoForm
              keyName1="family_member_info"
              keyName2="relationship"
              familyMemberIndex={familyMemberIndex}
              customer={customerChart}
              setCustomer={setCustomer}
              sx={{ px: 4 }}
            />
          ) : (
            familyMemberView.relationship
          )}
        </div>
      </div>
      <div
        className={
          'relative col-span-2 flex items-center justify-center border-r border-b border-current bg-indigo-50'
        }
      >
        <div className="py-1 px-4 text-base">{familyMemberView.age}</div>
        <div className="absolute flex bottom-0 right-1 py-1 px-2 font-semibold text-xs">
          歳
        </div>
        <div className="absolute flex bottom-1 left-1 py-1 px-2 font-semibold text-xs">
          {editMode && (
            <DatePickerFamilyMemberInfoIcon
              keyName1="family_member_info"
              keyName2="birth_date"
              familyMemberIndex={familyMemberIndex}
              customer={customerChart}
              setCustomer={setCustomer}
            />
          )}
        </div>
      </div>
      {editMode && (
        <div className="col-span-1 flex items-center justify-center">
          <IconButton
            onClick={() => {
              handleAddFamilyMember({
                customerChart,
                setCustomer,
                familyMemberIndex,
              })
            }}
          >
            <AddBoxIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              handleRemoveFamilyMember({
                customerChart,
                setCustomer,
                familyMemberIndex,
              })
            }}
          >
            <RemoveCircleOutlineIcon />
          </IconButton>
        </div>
      )}
    </>
  )
}

export const SmFamilyMembersForm: React.FC<FamilyMembersFormProps> = (
  props: FamilyMembersFormProps
) => {
  const { familyMemberView, customerChart, setCustomer, editMode } = props
  const familyMemberInfo = customerChart.family_member_info
  return (
    <>
      {familyMemberInfo &&
        familyMemberView &&
        familyMemberInfo.map((_, index) => (
          <SmFamilyMemberForm
            key={index}
            familyMemberView={familyMemberView[index]}
            customerChart={customerChart}
            setCustomer={setCustomer}
            editMode={editMode}
            familyMemberIndex={index}
          />
        ))}
      {editMode &&
        (familyMemberInfo == null ||
          familyMemberView == null ||
          familyMemberInfo.length === 0) && (
          <>
            <div className="col-start-1 col-span-1 my-2 flex items-center justify-center">
              <IconButton
                onClick={() => {
                  handleAddFamilyMember({
                    customerChart,
                    setCustomer,
                    familyMemberIndex: 0,
                  })
                }}
              >
                <AddBoxIcon />
              </IconButton>
            </div>
            <div className="col-span-2 my-2 flex items-center justify-center">
              <div>家族を追加する</div>
            </div>
          </>
        )}
    </>
  )
}

const SmFamilyMemberForm: React.FC<FamilyMemberFormProps> = (
  props: FamilyMemberFormProps
) => {
  const {
    familyMemberView,
    customerChart,
    setCustomer,
    editMode,
    familyMemberIndex,
  } = props

  return (
    <>
      <div
        className={
          'col-start-1 col-span-1 relative flex items-center justify-center border-x border-b border-current bg-indigo-50'
        }
      >
        <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
          続柄
        </div>
        <div className="py-3 px-4 text-base">
          {editMode ? (
            <TextFieldFamilyMemberInfoForm
              keyName1="family_member_info"
              keyName2="relationship"
              familyMemberIndex={familyMemberIndex}
              customer={customerChart}
              setCustomer={setCustomer}
              sx={{ px: 1 }}
            />
          ) : (
            <div className="h-6">{familyMemberView.relationship}</div>
          )}
        </div>
      </div>
      <div
        className={
          'relative col-span-2 flex items-center justify-center border-r border-b border-current bg-indigo-50'
        }
      >
        {editMode ? (
          <>
            <MobileDatePickerFamilyMemberInfoForm
              keyName1="family_member_info"
              keyName2="birth_date"
              familyMemberIndex={familyMemberIndex}
              customer={customerChart}
              setCustomer={setCustomer}
            />
            <div className="absolute flex bottom-0 right-0 px-1 font-semibold text-xs">
              生年月日
            </div>
          </>
        ) : (
          <>
            <div className="py-1 px-4 text-base">{familyMemberView.age}</div>
            <div className="absolute flex bottom-0 right-0 px-1 font-semibold text-xs">
              歳
            </div>
          </>
        )}
      </div>
      {editMode && (
        <div className="col-span-1 flex items-center justify-center">
          <IconButton
            onClick={() => {
              handleAddFamilyMember({
                customerChart,
                setCustomer,
                familyMemberIndex,
              })
            }}
          >
            <AddBoxIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              handleRemoveFamilyMember({
                customerChart,
                setCustomer,
                familyMemberIndex,
              })
            }}
          >
            <RemoveCircleOutlineIcon />
          </IconButton>
        </div>
      )}
    </>
  )
}
