import type { Appointment } from '../../models/Appointment'
import type { SearchAppointmentQuery } from '../../vendors/graphql/generated'

export const appointmentGraphqlConverter = (props: {
  data: SearchAppointmentQuery
}): Appointment[] => {
  /*
  __typenameを除く、null要素は除く
  */
  const { data } = props

  const dataAppointments = data.searchAppointment?.appointments
  let appointments: Appointment[]
  if (dataAppointments) {
    appointments = dataAppointments.map((appointment) => {
      const item: Appointment = {}
      if (appointment == null) {
        return item
      }
      if (appointment.id) {
        item.id = appointment.id
      }

      if (appointment.meta) {
        item.meta = appointment.meta
      }

      if (appointment.customer_id) {
        item.customer_id = appointment.customer_id
      }

      if (appointment.created_at) {
        item.created_at = appointment.created_at
      }

      if (appointment.updated_at) {
        item.updated_at = appointment.updated_at
      }

      if (appointment.appointment_status) {
        item.appointment_status = appointment.appointment_status
      }

      if (appointment.appointment_date) {
        item.appointment_date = appointment.appointment_date
      }

      if (appointment.rank) {
        item.rank = appointment.rank
      }

      if (appointment.appointment_family_number) {
        item.appointment_family_number = appointment.appointment_family_number
      }

      if (appointment.inquiry_price) {
        item.inquiry_price = appointment.inquiry_price
      }

      if (appointment.floor_plan) {
        item.floor_plan = appointment.floor_plan
      }

      if (appointment.monthly_repayment_amount) {
        item.monthly_repayment_amount = appointment.monthly_repayment_amount
      }

      if (appointment.annual_income) {
        item.annual_income = appointment.annual_income
      }

      if (appointment.own_resources) {
        item.own_resources = appointment.own_resources
      }

      if (appointment.appointment_count) {
        item.appointment_count = appointment.appointment_count
      }

      if (appointment.media) {
        item.media = appointment.media
      }

      if (appointment.negotiation_person) {
        item.negotiation_person = appointment.negotiation_person
      }

      if (appointment.memo) {
        item.memo = appointment.memo
      }

      if (appointment.remarks) {
        item.remarks = appointment.remarks
      }

      if (appointment.neck) {
        item.neck = appointment.neck
      }

      if (appointment.preparation) {
        item.preparation = appointment.preparation
      }

      if (appointment.preparation_check) {
        item.preparation_check = appointment.preparation_check
      }

      if (appointment.appointment_result) {
        item.appointment_result = appointment.appointment_result
      }

      if (appointment.appointment_result_reason) {
        item.appointment_result_reason = appointment.appointment_result_reason
      }

      if (appointment.family_name) {
        item.family_name = appointment.family_name
      }

      if (appointment.name) {
        item.name = appointment.name
      }

      if (appointment.family_name_kana) {
        item.family_name_kana = appointment.family_name_kana
      }

      if (appointment.name_kana) {
        item.name_kana = appointment.name_kana
      }

      if (appointment.name) {
        item.name = appointment.name
      }

      if (appointment.line_resistration) {
        item.line_resistration = appointment.line_resistration
      }

      if (appointment.birth_date) {
        item.birth_date = appointment.birth_date
      }

      if (appointment.gender) {
        item.gender = appointment.gender
      }

      if (appointment.address_ward) {
        item.address_ward = appointment.address_ward
      }
      return item
    })
  } else {
    appointments = []
  }
  return appointments
}
