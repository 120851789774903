import type {
  CustomerHistory,
  CustomerHistoryView,
} from '../../models/CustomerHistory'
import { dateViewJPConverter } from '../common/stringParser'

export const customerHistoryViewParser = (props: {
  history: CustomerHistory
}): CustomerHistoryView => {
  const { history } = props

  const initHistoryView: CustomerHistoryView = {
    id: '',
    meta: '',
    customer_id: '',
    memo: '',
    history_date: '',
  }

  if (history.memo) {
    initHistoryView.memo = history.memo
  }

  if (history.history_date) {
    initHistoryView.history_date = dateViewJPConverter(history.history_date)
  }

  return initHistoryView
}

export const customerHistoriesViewParser = (props: {
  histories: CustomerHistory[]
}): CustomerHistoryView[] => {
  const { histories } = props

  return histories.map((history) => {
    return customerHistoryViewParser({ history })
  })
}
