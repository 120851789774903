import React from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import type { Inquiry } from '../../../models/Inquiry'
import { BooleanRadioForm } from './editForms/BooleanRadioForm'
import {
  DateTimePickerIcon,
  MobileDateTimePickerForm,
} from './editForms/DatePickersIcon'
import { NumberInputForm } from './editForms/NumberInputForm'
import { SelectForm } from './editForms/SelectForm'
import { TextFieldForm } from './editForms/TextFieldForm'
import { datetimeViewJPConverter } from '../../../utils/common/stringParser'

interface InquirySaveFormProps {
  inputEditInquiry: Inquiry
  setInputEditInquiry: React.Dispatch<React.SetStateAction<Inquiry>>
}

export const InquirySaveForm: React.FC<InquirySaveFormProps> = (
  props: InquirySaveFormProps
) => {
  const { inputEditInquiry, setInputEditInquiry } = props

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className="grid grid-cols-10 rounded-none">
          <div className="col-start-1 col-span-2 flex items-center justify-center">
            <div className="py-1.5">
              <TextFieldForm
                label="名字（フリガナ）"
                keyName="family_name_kana"
                inquiry={inputEditInquiry}
                setInquiry={setInputEditInquiry}
              />
            </div>
          </div>
          <div className="col-span-2 flex items-center justify-center">
            <div className="py-1.5">
              <TextFieldForm
                label="名前（フリガナ）"
                keyName="name_kana"
                inquiry={inputEditInquiry}
                setInquiry={setInputEditInquiry}
              />
            </div>
          </div>
          <div className="relative col-span-4 flex items-center justify-start">
            <div className="absolute top-0 left-0 py-1 px-2 text-xs">
              問い合わせ日時
            </div>
            <div className="flex items-center py-2 pl-10">
              {datetimeViewJPConverter(inputEditInquiry.inquiry_date)}
              <DateTimePickerIcon
                keyName="inquiry_date"
                inquiry={inputEditInquiry}
                setInquiry={setInputEditInquiry}
              />
            </div>
          </div>
          <div className="col-start-1 col-span-2 flex items-center justify-center">
            <div className="py-1.5">
              <TextFieldForm
                label="名字"
                keyName="family_name"
                inquiry={inputEditInquiry}
                setInquiry={setInputEditInquiry}
              />
            </div>
          </div>
          <div className="col-span-2 flex items-center justify-center">
            <div className="py-1.5">
              <TextFieldForm
                label="名前"
                keyName="name"
                inquiry={inputEditInquiry}
                setInquiry={setInputEditInquiry}
              />
            </div>
          </div>
          <div className="col-start-1 col-span-2 flex items-center justify-center">
            <div className="py-1.5">
              <TextFieldForm
                label="最寄駅"
                keyName="nearest_station"
                inquiry={inputEditInquiry}
                setInquiry={setInputEditInquiry}
              />
            </div>
          </div>
          <div className="col-span-2 flex items-center justify-center">
            <div className="py-1.5">
              <TextFieldForm
                label="物件名"
                keyName="property_name"
                inquiry={inputEditInquiry}
                setInquiry={setInputEditInquiry}
              />
            </div>
          </div>
          <div className="col-span-2 flex items-center justify-center">
            <div className="py-1.5">
              <TextFieldForm
                label="築年"
                keyName="year_of_construction"
                inquiry={inputEditInquiry}
                setInquiry={setInputEditInquiry}
              />
            </div>
          </div>
          <div className="col-span-2 flex items-center justify-center">
            <div className="py-1.5">
              <NumberInputForm
                label="物件価格(円)"
                keyName="price"
                inquiry={inputEditInquiry}
                setInquiry={setInputEditInquiry}
              />
            </div>
          </div>
          <div className="col-span-2 flex items-center justify-center">
            <div className="py-1.5">
              <SelectForm
                label="間取り"
                keyName="floor_plan"
                inquiry={inputEditInquiry}
                setInquiry={setInputEditInquiry}
                sx={{ minWidth: 150 }}
                classString="pt-1"
              />
            </div>
          </div>
          <div className="col-start-1 col-span-2 flex items-center justify-center">
            <div className="py-1.5">
              <TextFieldForm
                label="媒体"
                keyName="media"
                inquiry={inputEditInquiry}
                setInquiry={setInputEditInquiry}
              />
            </div>
          </div>
          <div className="col-span-2 flex items-center justify-center">
            <div className="py-1.5">
              <BooleanRadioForm
                label="ライン"
                keyName="line_resistration"
                inquiry={inputEditInquiry}
                setInquiry={setInputEditInquiry}
              />
            </div>
          </div>
          <div className="col-span-2 flex items-center justify-center">
            <div className="py-1.5">
              <TextFieldForm
                label="タッチ"
                keyName="memo"
                inquiry={inputEditInquiry}
                setInquiry={setInputEditInquiry}
              />
            </div>
          </div>
          <div className="col-span-2 flex items-center justify-center">
            <div className="py-1.5">
              <SelectForm
                label="リアクション"
                keyName="reaction"
                inquiry={inputEditInquiry}
                setInquiry={setInputEditInquiry}
                sx={{ minWidth: 150 }}
                classString="pt-1"
              />
            </div>
          </div>
          <div className="col-span-2 flex items-center justify-center">
            <div className="py-1.5">
              <SelectForm
                label="案内済客フラグ"
                keyName="appointment_status"
                inquiry={inputEditInquiry}
                setInquiry={setInputEditInquiry}
                sx={{ minWidth: 150 }}
                classString="pt-1"
              />
            </div>
          </div>
        </div>
      </LocalizationProvider>
    </>
  )
}

export const SmInquirySaveForm: React.FC<InquirySaveFormProps> = (
  props: InquirySaveFormProps
) => {
  const { inputEditInquiry, setInputEditInquiry } = props

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className="grid grid-cols-4 rounded-none">
          <div className="col-start-1 col-span-2 flex items-center justify-center">
            <div className="py-1.5 pr-1">
              <TextFieldForm
                label="名字（フリガナ）"
                keyName="family_name_kana"
                inquiry={inputEditInquiry}
                setInquiry={setInputEditInquiry}
              />
            </div>
          </div>
          <div className="col-span-2 flex items-center justify-center">
            <div className="py-1.5">
              <TextFieldForm
                label="名前（フリガナ）"
                keyName="name_kana"
                inquiry={inputEditInquiry}
                setInquiry={setInputEditInquiry}
              />
            </div>
          </div>
          <div className="col-start-1 col-span-2 flex items-center justify-center">
            <div className="py-1.5 pr-1">
              <TextFieldForm
                label="名字"
                keyName="family_name"
                inquiry={inputEditInquiry}
                setInquiry={setInputEditInquiry}
              />
            </div>
          </div>
          <div className="col-span-2 flex items-center justify-center">
            <div className="py-1.5">
              <TextFieldForm
                label="名前"
                keyName="name"
                inquiry={inputEditInquiry}
                setInquiry={setInputEditInquiry}
              />
            </div>
          </div>
          <div className="col-start-1 col-span-4">
            <div className="py-6">
              <MobileDateTimePickerForm
                label="問い合わせ日時"
                keyName="inquiry_date"
                inquiry={inputEditInquiry}
                setInquiry={setInputEditInquiry}
              />
            </div>
          </div>

          <div className="col-start-1 col-span-2 flex items-center justify-center">
            <div className="py-1.5 pr-1">
              <TextFieldForm
                label="最寄駅"
                keyName="nearest_station"
                inquiry={inputEditInquiry}
                setInquiry={setInputEditInquiry}
              />
            </div>
          </div>
          <div className="col-span-2 flex items-center justify-center">
            <div className="py-1.5">
              <TextFieldForm
                label="物件名"
                keyName="property_name"
                inquiry={inputEditInquiry}
                setInquiry={setInputEditInquiry}
              />
            </div>
          </div>
          <div className="col-span-2 flex items-center justify-center">
            <div className="py-1.5 pr-1">
              <TextFieldForm
                label="築年"
                keyName="year_of_construction"
                inquiry={inputEditInquiry}
                setInquiry={setInputEditInquiry}
              />
            </div>
          </div>
          <div className="col-span-2 flex items-center justify-center">
            <div className="py-1.5">
              <NumberInputForm
                label="物件価格(円)"
                keyName="price"
                inquiry={inputEditInquiry}
                setInquiry={setInputEditInquiry}
              />
            </div>
          </div>
          <div className="col-span-2 flex items-center justify-center">
            <div className="py-1.5 pr-1">
              <SelectForm
                label="間取り"
                keyName="floor_plan"
                inquiry={inputEditInquiry}
                setInquiry={setInputEditInquiry}
                sx={{ minWidth: 150 }}
                classString="pt-1"
              />
            </div>
          </div>
          <div className="col-span-2 flex items-center justify-center">
            <div className="py-1.5">
              <TextFieldForm
                label="媒体"
                keyName="media"
                inquiry={inputEditInquiry}
                setInquiry={setInputEditInquiry}
              />
            </div>
          </div>
          <div className="col-start-1 col-span-2 flex items-center justify-center">
            <div className="py-1.5">
              <BooleanRadioForm
                label="ライン"
                keyName="line_resistration"
                inquiry={inputEditInquiry}
                setInquiry={setInputEditInquiry}
              />
            </div>
          </div>
          <div className="col-start-1 col-span-2 flex items-center justify-center">
            <div className="py-1.5 pr-1">
              <TextFieldForm
                label="タッチ"
                keyName="memo"
                inquiry={inputEditInquiry}
                setInquiry={setInputEditInquiry}
              />
            </div>
          </div>
          <div className="col-start-1 col-span-2 flex items-center justify-center">
            <div className="py-1.5">
              <SelectForm
                label="リアクション"
                keyName="reaction"
                inquiry={inputEditInquiry}
                setInquiry={setInputEditInquiry}
                sx={{ minWidth: 150 }}
                classString="pt-1"
              />
            </div>
          </div>
          <div className="col-span-2 flex items-center justify-center">
            <div className="py-1.5">
              <SelectForm
                label="案内済客フラグ"
                keyName="appointment_status"
                inquiry={inputEditInquiry}
                setInquiry={setInputEditInquiry}
                sx={{ minWidth: 150 }}
                classString="pt-1"
              />
            </div>
          </div>
        </div>
      </LocalizationProvider>
    </>
  )
}
