import React from 'react'
import { Modal, Box, TextField, Typography, Button } from '@mui/material'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import type { Dayjs } from 'dayjs'
import dayjs from '../../../vendors/Dayjs'
import type { CustomerHistory } from '../../../models/CustomerHistory'
import { datetimeFormat } from '../../../fixture/common/formOptions'
import {
  useCreateCustomerHistoryMutation,
  useUpdateCustomerHistoryMutation,
  useDeleteCustomerHistoryMutation,
} from '../../../vendors/graphql/generated'
import { customerHistoryInputConverter } from '../../../utils/customer/customerHistoryInputConverter'
import { datetimeViewJPConverter } from '../../../utils/common/stringParser'

export type CustomerHistoryModalType = 'create' | 'update'

interface CustomerHistoryModalProps {
  title: string
  open: boolean
  handleClose: () => void
  history: CustomerHistory
  type: CustomerHistoryModalType
  handleRefetch?: () => void
  customerId: string
}

interface DateChangeHandlerProps {
  history: CustomerHistory
  key: string
}

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: 'w-auto',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
}

export const CustomerHistoryModal: React.FC<CustomerHistoryModalProps> = (
  props: CustomerHistoryModalProps
) => {
  const { title, open, handleClose, history, type, handleRefetch, customerId } =
    props
  const [createCustomerHistory] = useCreateCustomerHistoryMutation()
  const [updateCustomerHistory] = useUpdateCustomerHistoryMutation()
  const [deleteCustomerHistory] = useDeleteCustomerHistoryMutation()

  const initCustomerHistory = {}
  const [inputEditCustomerHistory, setInputEditCustomerHistory] =
    React.useState<CustomerHistory>(initCustomerHistory)

  React.useEffect(() => {
    if (history != null && type === 'update') {
      setInputEditCustomerHistory(history)
    } else if (type === 'create') {
      const emptyCustomerHistory = {
        memo: '',
        history_date: '',
      }
      setInputEditCustomerHistory(emptyCustomerHistory)
    }
  }, [setInputEditCustomerHistory, history, type])

  const handleChangeInput =
    (input: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setInputEditCustomerHistory({
        ...inputEditCustomerHistory,
        [input]: event.target.value,
      })
    }
  let modalTitle: string
  if (type === 'create') {
    modalTitle = title + '新規'
  } else {
    modalTitle = title + '更新'
  }

  const dateChangeHandler =
    (props: DateChangeHandlerProps) =>
    (newValue: Dayjs | null): void => {
      const { history, key } = props
      const dateValue = newValue?.format(datetimeFormat)
      setInputEditCustomerHistory({ ...history, [key]: dateValue })
    }

  const createHandler = async (): Promise<void> => {
    console.log('createHandler1!!', inputEditCustomerHistory)
    const inputCustomerHistory = customerHistoryInputConverter({
      customerHistory: inputEditCustomerHistory,
      customerId,
    })
    console.log('createHandler2!!', inputCustomerHistory)
    await createCustomerHistory({ variables: { input: inputCustomerHistory } })
    if (handleRefetch != null) {
      handleRefetch()
    }
    handleClose()
  }

  const updateHandler = async (): Promise<void> => {
    console.log(
      'updateHandler: inputEditCustomerHistory!!',
      inputEditCustomerHistory
    )
    const inputCustomerHistory = customerHistoryInputConverter({
      customerHistory: inputEditCustomerHistory,
    })
    const key = {
      id: inputEditCustomerHistory.id ?? '',
      meta: inputEditCustomerHistory.meta ?? '',
    }
    await updateCustomerHistory({
      variables: { input: inputCustomerHistory, key },
    })
    if (handleRefetch != null) {
      handleRefetch()
    }
    handleClose()
  }

  const deleteHandler = async (): Promise<void> => {
    const key = {
      id: inputEditCustomerHistory.id ?? '',
      meta: inputEditCustomerHistory.meta ?? '',
    }
    await deleteCustomerHistory({
      variables: {
        key,
      },
    })
    if (handleRefetch != null) {
      handleRefetch()
    }
    handleClose()
  }

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle} className="w-4/6 overflow-y-scroll">
          <div className="ml-10 flex items-center justify-start">
            <Typography className="px-6" variant="h5">
              {modalTitle}
            </Typography>
            <Button variant="outlined" onClick={handleClose}>
              閉じる
            </Button>
            {type === 'create' ? (
              <Button
                sx={{ ml: 2 }}
                variant="contained"
                onClick={async () => {
                  await createHandler()
                }}
              >
                保存
              </Button>
            ) : (
              <>
                <Button
                  sx={{ ml: 2 }}
                  variant="contained"
                  onClick={async () => {
                    await updateHandler()
                  }}
                >
                  更新
                </Button>
                <Button
                  sx={{ ml: 2 }}
                  variant="contained"
                  onClick={async () => {
                    await deleteHandler()
                  }}
                >
                  削除
                </Button>
              </>
            )}
          </div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className="grid grid-cols-7 mt-6">
              <div className="col-start-1 col-span-4 w-5/6">
                <TextField
                  fullWidth
                  id="memo"
                  label="memo"
                  variant="outlined"
                  value={inputEditCustomerHistory.memo}
                  onChange={handleChangeInput('memo')}
                  multiline
                  minRows="5"
                />
              </div>
              <div className="col-span-3">
                <div className="flex items-center">
                  <div>対応日：</div>
                  {inputEditCustomerHistory.history_date &&
                    datetimeViewJPConverter(
                      inputEditCustomerHistory.history_date
                    )}
                  <DateTimePicker
                    label="対応日時"
                    openTo="day"
                    value={
                      inputEditCustomerHistory.history_date !== ''
                        ? dayjs(inputEditCustomerHistory.history_date)
                        : null
                    }
                    onChange={dateChangeHandler({
                      history: inputEditCustomerHistory,
                      key: 'history_date',
                    })}
                    renderInput={({ inputRef, inputProps, InputProps }) => (
                      <div className="flex items-center">
                        <input ref={inputRef} {...inputProps} className="w-0" />
                        {InputProps?.endAdornment}
                      </div>
                    )}
                    closeOnSelect={true}
                    disableMaskedInput={true}
                  />
                </div>
              </div>
            </div>
          </LocalizationProvider>
        </Box>
      </Modal>
    </>
  )
}
