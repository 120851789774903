import {
  DateTimePickerIcon,
  DatePickerIcon,
  DatePickerCustomerInfoIcon,
  MobileDateTimePickerForm,
  MobileDatePickerForm,
  MobileDatePickerCustomerInfoForm,
} from '../../common/editForms/DatePickersIcon'
import {
  TextFieldForm,
  TextFieldParentInfoForm,
} from '../../common/editForms/TextFieldForm'
import { NumberInputForm } from '../../common/editForms/NumberInputForm'
import { CustomerBooleanRadioForm } from '../../common/editForms/BooleanRadioForm'
import { SelectParentInfoForm } from '../../common/editForms/SelectForm'
import type { Customer, CustomerView } from '../../../../models/Customer'
import { genderColorStringCreater } from '../../../../utils/customer/customerChartModules'

interface CustomerChartInquiryBlockProps {
  editCustomer: Customer
  customerView: CustomerView
  setEditCustomer: React.Dispatch<React.SetStateAction<Customer>>
  editMode: boolean
}

export const CustomerChartInquiryBlock: React.FC<
  CustomerChartInquiryBlockProps
> = (props: CustomerChartInquiryBlockProps) => {
  const { customerView, editCustomer, setEditCustomer, editMode } = props
  const cutomerGenderColor = genderColorStringCreater(
    editCustomer.customer_info?.gender
  )
  const consortGenderColor = genderColorStringCreater(
    editCustomer.consort_info?.gender
  )
  return (
    <>
      <div>{editCustomer.media}</div>
      <div className="grid grid-cols-12 rounded-none">
        <div className="col-start-1 col-span-1 flex items-center justify-center border-t border-l border-current">
          <div className="py-1.5 px-3 border-r border-slate-100 font-semibold">
            問合日
          </div>
        </div>
        <div className="col-span-4 flex items-center justify-center border-t border-r border-current">
          <div className="flex items-center py-2 px-4">
            {customerView.inquiry_date}
            {editMode && (
              <DateTimePickerIcon
                keyName="inquiry_date"
                customer={editCustomer}
                setCustomer={setEditCustomer}
              />
            )}
          </div>
        </div>
        <div className="col-span-1 flex items-center justify-center border-t border-current">
          <div className="py-1.5 px-6 border-r border-slate-100 font-semibold text-xs">
            媒体
          </div>
        </div>
        <div className="col-span-2 flex items-center justify-center border-t border-r border-current">
          <div className="py-1.5">
            {editMode ? (
              <TextFieldForm
                keyName="media"
                customer={editCustomer}
                setCustomer={setEditCustomer}
                sx={{ px: 2 }}
              />
            ) : (
              customerView.media
            )}
          </div>
        </div>
        <div className="relative col-start-1 col-span-2 flex items-center justify-center border border-current bg-cyan-100">
          <div className="absolute top-0 right-0 py-1 px-2 font-semibold text-xs">
            (市区町村)
          </div>
          <div className="py-4 px-4 h-16 text-xl">
            {editMode ? (
              <TextFieldForm
                keyName="inquiry_address_ward"
                customer={editCustomer}
                setCustomer={setEditCustomer}
                sx={{ px: 4 }}
              />
            ) : (
              customerView.inquiry_address_ward.replace(/[市区町村]$/, '')
            )}
          </div>
          {!editMode && (
            <div className="absolute bottom-0 right-0 py-1 px-2 font-semibold text-xs">
              {customerView.inquiry_address_ward.match(/[市区町村]$/)}
            </div>
          )}
        </div>
        <div className="relative col-span-8 flex items-center justify-center border-r border-y border-current bg-cyan-100">
          <div>
            <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
              物件
            </div>
            <div className="py-4 text-xl">
              {editMode ? (
                <TextFieldForm
                  keyName="inquiry_property_name"
                  customer={editCustomer}
                  setCustomer={setEditCustomer}
                  sx={{ px: 2 }}
                />
              ) : (
                customerView.inquiry_property_name
              )}
            </div>
            <div className="absolute bottom-0 right-0 py-1 px-2 flex">
              <div className="flex items-end mr-10">
                {editMode ? (
                  <>
                    <NumberInputForm
                      keyName="inquiry_property_price"
                      customer={editCustomer}
                      setCustomer={setEditCustomer}
                      classString="w-24"
                    />
                    <div className="font-semibold text-xs ml-1">円</div>
                  </>
                ) : (
                  <>
                    <div>{customerView.inquiry_property_price}</div>
                    <div className="font-semibold text-xs ml-1">万円</div>
                  </>
                )}
              </div>
              <div className="flex items-end">
                <div>
                  {editMode ? (
                    <NumberInputForm
                      keyName="inquiry_property_width"
                      customer={editCustomer}
                      setCustomer={setEditCustomer}
                      classString="w-6"
                    />
                  ) : (
                    customerView.inquiry_property_width
                  )}
                </div>
                <div className="font-semibold text-xs ml-1">㎡</div>
              </div>
            </div>
          </div>
        </div>
        <div className="relative col-start-1 col-span-2 flex items-center justify-center border-b border-x border-current bg-cyan-100">
          <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
            居住人数
          </div>
          <div className="flex items-center h-12 py-1 px-2 text-base">
            {editMode ? (
              <NumberInputForm
                keyName="residents"
                customer={editCustomer}
                setCustomer={setEditCustomer}
                classString="w-6"
              />
            ) : (
              customerView.residents
            )}
          </div>
          <div className="absolute bottom-0 right-0 py-1 px-2 font-semibold text-xs">
            人
          </div>
        </div>
        <div className="relative col-span-8 flex items-center justify-center border-b border-r border-current bg-cyan-100">
          <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
            家族構成
          </div>
          <div className="flex items-center h-12 py-1 px-2">
            {customerView.family_structure}
          </div>
        </div>
        <div className="relative col-start-1 col-span-2 flex items-center justify-center border-x border-current bg-cyan-100">
          <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
            国籍
          </div>
          <div className="py-3 px-2 text-base">
            {editMode ? (
              <TextFieldForm
                keyName="nationality"
                customer={editCustomer}
                setCustomer={setEditCustomer}
                sx={{ px: 6 }}
              />
            ) : (
              customerView.nationality
            )}
          </div>
        </div>
        <div className="relative col-span-3 flex items-center justify-center border-r border-current bg-cyan-100">
          <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
            永住権
          </div>
          <div className="py-3 px-2 text-base">
            {editMode ? (
              <CustomerBooleanRadioForm
                keyName="have_permanent_residency"
                customer={editCustomer}
                setCustomer={setEditCustomer}
              />
            ) : (
              customerView.have_permanent_residency
            )}
          </div>
        </div>
        <div className="col-span-2 flex items-center justify-center border-current">
          <div className="py-1.5 px-10 border-r border-slate-100 font-semibold text-xs">
            取得予定日
          </div>
        </div>
        <div className="col-span-3 flex items-center justify-center border-r border-current">
          <div className="flex items-center py-2 px-4">
            {customerView.acquisition_expected_date}
            {editMode && (
              <DatePickerIcon
                keyName="acquisition_expected_date"
                customer={editCustomer}
                setCustomer={setEditCustomer}
              />
            )}
          </div>
        </div>
        <div
          className={`relative col-start-1 col-span-5 flex items-center justify-center border border-current ${cutomerGenderColor}`}
        >
          <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
            名前(本人)
          </div>
          <div className="pb-2 px-4 text-xl">
            {editMode ? (
              <div>
                <div className="flex items-center justify-center">
                  <TextFieldParentInfoForm
                    keyName1="customer_info"
                    keyName2="family_name_kana"
                    customer={editCustomer}
                    setCustomer={setEditCustomer}
                    sx={{ ml: 11 }}
                    label="名字カナ"
                  />
                  <TextFieldParentInfoForm
                    keyName1="customer_info"
                    keyName2="name_kana"
                    customer={editCustomer}
                    setCustomer={setEditCustomer}
                    sx={{ ml: 2, mr: 11 }}
                    label="名前カナ"
                  />
                </div>
                <div className="flex items-center justify-center">
                  <TextFieldParentInfoForm
                    keyName1="customer_info"
                    keyName2="family_name"
                    customer={editCustomer}
                    setCustomer={setEditCustomer}
                    sx={{ ml: 11 }}
                    label="名字"
                  />
                  <TextFieldParentInfoForm
                    keyName1="customer_info"
                    keyName2="name"
                    customer={editCustomer}
                    setCustomer={setEditCustomer}
                    sx={{ ml: 2, mr: 11 }}
                    label="名前"
                  />
                </div>
              </div>
            ) : (
              <div>
                <div className="flex text-xs">
                  <div> {customerView.customer_info?.family_name_kana}</div>
                  <div className="ml-1">
                    {customerView.customer_info?.name_kana}
                  </div>
                </div>
                <div className="flex">
                  <div> {customerView.customer_info?.family_name}</div>
                  <div className="ml-1">{customerView.customer_info?.name}</div>
                </div>
              </div>
            )}
          </div>
          <div className="absolute flex bottom-1 right-1 py-1 px-2 font-semibold text-xs">
            <div className="flex items-center">
              （<p className="w-5">{customerView.customer_info?.age}</p> 歳 ）
              {editMode && (
                <DatePickerCustomerInfoIcon
                  keyName1="customer_info"
                  keyName2="birth_date"
                  customer={editCustomer}
                  setCustomer={setEditCustomer}
                />
              )}
            </div>
          </div>
        </div>
        {editMode && (
          <div
            className={`relative col-span-1 flex items-center justify-center border-y border-r border-current ${cutomerGenderColor}`}
          >
            <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
              性別
            </div>
            <div className="flex items-center justify-center">
              <SelectParentInfoForm
                keyName1="customer_info"
                keyName2="gender"
                customer={editCustomer}
                setCustomer={setEditCustomer}
                classString="pb-3"
              />
            </div>
          </div>
        )}
        <div
          className={
            'col-span-5 border-r border-y border-current' +
            ' ' +
            cutomerGenderColor
          }
        >
          <div className="relative py-1 px-4 border-b border-current">
            <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
              TELL
            </div>
            <div className="h-12 flex items-center justify-center">
              {editMode ? (
                <TextFieldParentInfoForm
                  keyName1="customer_info"
                  keyName2="phone_number"
                  customer={editCustomer}
                  setCustomer={setEditCustomer}
                  sx={{ px: 16 }}
                />
              ) : (
                <div>{customerView.customer_info?.phone_number}</div>
              )}
            </div>
          </div>
          <div className="relative py-1 px-4">
            <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
              メール
            </div>
            <div className="h-12 flex items-center justify-center">
              {editMode ? (
                <TextFieldParentInfoForm
                  keyName1="customer_info"
                  keyName2="email"
                  customer={editCustomer}
                  setCustomer={setEditCustomer}
                  sx={{ px: 12 }}
                />
              ) : (
                <p>{customerView.customer_info?.email}</p>
              )}
            </div>
          </div>
        </div>
        <div
          className={`relative col-start-1 col-span-5 flex items-center justify-center border-x border-b border-current ${consortGenderColor}`}
        >
          <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
            名前(配偶者)
          </div>
          <div className="py-4 px-4 text-xl">
            {editMode ? (
              <div>
                <div className="flex items-center justify-center">
                  <TextFieldParentInfoForm
                    keyName1="consort_info"
                    keyName2="family_name_kana"
                    customer={editCustomer}
                    setCustomer={setEditCustomer}
                    sx={{ ml: 11 }}
                    label="名字カナ"
                  />
                  <TextFieldParentInfoForm
                    keyName1="consort_info"
                    keyName2="name_kana"
                    customer={editCustomer}
                    setCustomer={setEditCustomer}
                    sx={{ ml: 2, mr: 11 }}
                    label="名前カナ"
                  />
                </div>
                <div className="flex">
                  <TextFieldParentInfoForm
                    keyName1="consort_info"
                    keyName2="family_name"
                    customer={editCustomer}
                    setCustomer={setEditCustomer}
                    sx={{ ml: 11 }}
                    label="名字"
                  />
                  <TextFieldParentInfoForm
                    keyName1="consort_info"
                    keyName2="name"
                    customer={editCustomer}
                    setCustomer={setEditCustomer}
                    sx={{ ml: 2, mr: 11 }}
                    label="名前"
                  />
                </div>
              </div>
            ) : (
              <div>
                <div className="flex text-xs">
                  <div> {customerView.consort_info?.family_name_kana}</div>
                  <div className="ml-1">
                    {customerView.consort_info?.name_kana}
                  </div>
                </div>
                <div className="flex">
                  <div> {customerView.consort_info?.family_name}</div>
                  <div className="ml-1">{customerView.consort_info?.name}</div>
                </div>
              </div>
            )}
          </div>
          <div className="absolute flex bottom-1 right-1 py-1 px-2 font-semibold text-xs">
            <div className="flex items-center">
              （<p className="w-5">{customerView.consort_info?.age}</p> 歳 ）
              {editMode && (
                <DatePickerCustomerInfoIcon
                  keyName1="consort_info"
                  keyName2="birth_date"
                  customer={editCustomer}
                  setCustomer={setEditCustomer}
                />
              )}
            </div>
          </div>
        </div>
        {editMode && (
          <div
            className={`relative col-span-1 flex items-center justify-center border-b border-r border-current ${consortGenderColor}`}
          >
            <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
              性別
            </div>
            <div className="flex items-center justify-center">
              <SelectParentInfoForm
                keyName1="consort_info"
                keyName2="gender"
                customer={editCustomer}
                setCustomer={setEditCustomer}
                classString="pb-3"
              />
            </div>
          </div>
        )}
        <div
          className={`col-span-5 border-r border-b border-current ${consortGenderColor}`}
        >
          <div className="relative py-1 px-4 border-b border-current">
            <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
              TELL
            </div>
            <div className="h-12 flex items-center justify-center">
              {editMode ? (
                <TextFieldParentInfoForm
                  keyName1="consort_info"
                  keyName2="phone_number"
                  customer={editCustomer}
                  setCustomer={setEditCustomer}
                  sx={{ px: 16 }}
                />
              ) : (
                <div>{customerView.consort_info?.phone_number}</div>
              )}
            </div>
          </div>
          <div className="relative py-1 px-4">
            <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
              メール
            </div>
            <div className="h-12 flex items-center justify-center">
              {editMode ? (
                <TextFieldParentInfoForm
                  keyName1="consort_info"
                  keyName2="email"
                  customer={editCustomer}
                  setCustomer={setEditCustomer}
                  sx={{ px: 12 }}
                />
              ) : (
                <p>{customerView.consort_info?.email}</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export const SmCustomerChartInquiryBlock: React.FC<
  CustomerChartInquiryBlockProps
> = (props: CustomerChartInquiryBlockProps) => {
  const { customerView, editCustomer, setEditCustomer, editMode } = props
  const cutomerGenderColor = genderColorStringCreater(
    editCustomer.customer_info?.gender
  )
  const consortGenderColor = genderColorStringCreater(
    editCustomer.consort_info?.gender
  )
  return (
    <>
      <div className="grid grid-cols-4 rounded-none">
        <div className="relative col-start-1 col-span-3 flex items-center justify-center border-y border-x border-current">
          <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
            問合日
          </div>
          <div className="flex items-center py-3 px-4">
            {editMode ? (
              <MobileDateTimePickerForm
                keyName="inquiry_date"
                customer={editCustomer}
                setCustomer={setEditCustomer}
              />
            ) : (
              customerView.inquiry_date
            )}
          </div>
        </div>
        <div className="relative col-span-1 flex items-center justify-center border-y border-r border-current">
          <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
            媒体
          </div>
          <div className="py-1.5">
            {editMode ? (
              <TextFieldForm
                keyName="media"
                customer={editCustomer}
                setCustomer={setEditCustomer}
                sx={{ px: 2 }}
              />
            ) : (
              customerView.media
            )}
          </div>
        </div>
        <div className="relative col-start-1 col-span-1 flex items-center justify-center border-b border-x border-current bg-cyan-100">
          <div className="absolute top-0 right-0 px-1 font-semibold text-xs">
            (市区町村)
          </div>
          <div className="py-4 px-4 h-16 text-xl">
            {editMode ? (
              <TextFieldForm
                keyName="inquiry_address_ward"
                customer={editCustomer}
                setCustomer={setEditCustomer}
                sx={{ px: 4 }}
              />
            ) : (
              customerView.inquiry_address_ward.replace(/[市区町村]$/, '')
            )}
          </div>
          {!editMode && (
            <div className="absolute bottom-0 right-0 px-1 font-semibold text-xs">
              {customerView.inquiry_address_ward.match(/[市区町村]$/)}
            </div>
          )}
        </div>
        <div className="relative col-span-3 flex items-center justify-center border-r border-b border-current bg-cyan-100">
          <div>
            <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
              物件
            </div>
            <div className="py-3 text-xl">
              {editMode ? (
                <TextFieldForm
                  keyName="inquiry_property_name"
                  customer={editCustomer}
                  setCustomer={setEditCustomer}
                  sx={{ px: 2 }}
                />
              ) : (
                customerView.inquiry_property_name
              )}
            </div>
          </div>
        </div>
        <div className="relative col-span-2 flex items-center justify-center border-x border-b border-current bg-cyan-100">
          <div>
            <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
              価格
            </div>
            <div className="py-3 flex items-center justify-center">
              {editMode ? (
                <>
                  <NumberInputForm
                    keyName="inquiry_property_price"
                    customer={editCustomer}
                    setCustomer={setEditCustomer}
                    classString="w-24"
                  />
                  <div className="font-semibold text-xs ml-1">円</div>
                </>
              ) : (
                <>
                  <div>{customerView.inquiry_property_price}</div>
                  <div className="font-semibold text-xs ml-1">万円</div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="relative col-span-2 flex items-center justify-center border-r border-b border-current bg-cyan-100">
          <div>
            <div className="absolute top-0 left-0 px-2 font-semibold text-xs">
              広さ
            </div>
            <div className="py-3 flex items-center justify-center">
              <div>
                {editMode ? (
                  <NumberInputForm
                    keyName="inquiry_property_width"
                    customer={editCustomer}
                    setCustomer={setEditCustomer}
                    classString="w-24"
                  />
                ) : (
                  customerView.inquiry_property_width
                )}
              </div>
              <div className="font-semibold text-xs ml-1">㎡</div>
            </div>
          </div>
        </div>
        <div className="relative col-start-1 col-span-2 flex items-center justify-center border-b border-x border-current bg-cyan-100">
          <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
            居住人数
          </div>
          <div className="flex items-center h-10 py-1 px-2 text-base">
            {editMode ? (
              <NumberInputForm
                keyName="residents"
                customer={editCustomer}
                setCustomer={setEditCustomer}
                classString="w-6"
              />
            ) : (
              customerView.residents
            )}
          </div>
          <div className="absolute bottom-0 right-0 px-1 font-semibold text-xs">
            人
          </div>
        </div>
        <div className="relative col-span-2 flex items-center justify-center border-b border-r border-current bg-cyan-100">
          <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
            家族構成
          </div>
          <div className="flex items-center h-10 py-1 px-2">
            {customerView.family_structure}
          </div>
        </div>
        <div className="relative col-start-1 col-span-2 flex items-center justify-center border-b border-x border-current bg-cyan-100">
          <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
            国籍
          </div>
          <div className="py-3 px-2 text-base">
            {editMode ? (
              <TextFieldForm
                keyName="nationality"
                customer={editCustomer}
                setCustomer={setEditCustomer}
                sx={{ px: 6 }}
              />
            ) : (
              customerView.nationality
            )}
          </div>
        </div>
        <div className="relative col-span-2 flex items-center justify-center border-b border-r border-current bg-cyan-100">
          <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
            永住権
          </div>
          <div className="py-3 ml-2">
            {editMode ? (
              <CustomerBooleanRadioForm
                keyName="have_permanent_residency"
                customer={editCustomer}
                setCustomer={setEditCustomer}
              />
            ) : (
              customerView.have_permanent_residency
            )}
          </div>
        </div>
        <div className="relative col-span-3 flex items-center justify-center border-x border-current">
          <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
            取得予定日
          </div>
          <div className="flex items-center h-24 py-1 px-2">
            {editMode ? (
              <MobileDatePickerForm
                keyName="acquisition_expected_date"
                customer={editCustomer}
                setCustomer={setEditCustomer}
              />
            ) : (
              customerView.acquisition_expected_date
            )}
          </div>
        </div>
        <div
          className={`relative col-start-1 col-span-4 flex items-center justify-center border border-current ${cutomerGenderColor}`}
        >
          <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
            名前(本人)
          </div>
          <div className="py-4 px-4 text-xl">
            {editMode ? (
              <div>
                <div className="flex items-center justify-center">
                  <TextFieldParentInfoForm
                    keyName1="customer_info"
                    keyName2="family_name_kana"
                    customer={editCustomer}
                    setCustomer={setEditCustomer}
                    sx={{ ml: 11 }}
                    label="名字カナ"
                  />
                  <TextFieldParentInfoForm
                    keyName1="customer_info"
                    keyName2="name_kana"
                    customer={editCustomer}
                    setCustomer={setEditCustomer}
                    sx={{ ml: 2, mr: 11 }}
                    label="名前カナ"
                  />
                </div>
                <div className="flex items-center justify-center">
                  <TextFieldParentInfoForm
                    keyName1="customer_info"
                    keyName2="family_name"
                    customer={editCustomer}
                    setCustomer={setEditCustomer}
                    sx={{ ml: 11 }}
                    label="名字"
                  />
                  <TextFieldParentInfoForm
                    keyName1="customer_info"
                    keyName2="name"
                    customer={editCustomer}
                    setCustomer={setEditCustomer}
                    sx={{ ml: 2, mr: 11 }}
                    label="名前"
                  />
                </div>
              </div>
            ) : (
              <div>
                <div className="flex items-center h-4 text-xs">
                  <div> {customerView.customer_info?.family_name_kana}</div>
                  <div className="ml-1">
                    {customerView.customer_info?.name_kana}
                  </div>
                </div>
                <div className="flex items-center h-8">
                  <div> {customerView.customer_info?.family_name}</div>
                  <div className="ml-1">{customerView.customer_info?.name}</div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div
          className={`relative col-start-1 col-span-2 flex items-center justify-center border-b border-x border-current ${cutomerGenderColor}`}
        >
          <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
            {editMode ? '生年月日' : '年齢'}
          </div>
          <div className="flex items-center py-3 px-4">
            {editMode ? (
              <MobileDatePickerCustomerInfoForm
                keyName1="customer_info"
                keyName2="birth_date"
                customer={editCustomer}
                setCustomer={setEditCustomer}
              />
            ) : (
              <p className="">{customerView.customer_info?.age}歳</p>
            )}
          </div>
        </div>
        <div
          className={`relative col-span-2 flex items-center justify-center border-b border-r border-current ${cutomerGenderColor}`}
        >
          <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
            性別
          </div>
          <div className="flex items-center py-3 px-4">
            {editMode ? (
              <SelectParentInfoForm
                keyName1="consort_info"
                keyName2="gender"
                customer={editCustomer}
                setCustomer={setEditCustomer}
                classString="pb-3"
              />
            ) : (
              customerView.customer_info?.gender
            )}
          </div>
        </div>
        <div
          className={`relative col-start-1 col-span-2 flex items-center justify-center border-b border-x border-current ${cutomerGenderColor}`}
        >
          <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
            TELL
          </div>
          <div className="flex items-center h-10 py-1 px-4">
            {editMode ? (
              <TextFieldParentInfoForm
                keyName1="customer_info"
                keyName2="phone_number"
                customer={editCustomer}
                setCustomer={setEditCustomer}
              />
            ) : (
              customerView.customer_info?.phone_number
            )}
          </div>
        </div>
        <div
          className={`relative col-span-2 flex items-center justify-center border-b border-r border-current ${cutomerGenderColor}`}
        >
          <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
            メール
          </div>
          <div className="flex items-center h-10 py-1 px-4">
            {editMode ? (
              <TextFieldParentInfoForm
                keyName1="customer_info"
                keyName2="email"
                customer={editCustomer}
                setCustomer={setEditCustomer}
              />
            ) : (
              customerView.customer_info?.email
            )}
          </div>
        </div>
        <div
          className={`relative col-start-1 col-span-4 flex items-center justify-center border-b border-x border-current ${consortGenderColor}`}
        >
          <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
            名前(配偶者)
          </div>
          <div className="py-4 px-4 text-xl">
            {editMode ? (
              <div>
                <div className="flex items-center justify-center">
                  <TextFieldParentInfoForm
                    keyName1="consort_info"
                    keyName2="family_name_kana"
                    customer={editCustomer}
                    setCustomer={setEditCustomer}
                    sx={{ ml: 11 }}
                    label="名字カナ"
                  />
                  <TextFieldParentInfoForm
                    keyName1="consort_info"
                    keyName2="name_kana"
                    customer={editCustomer}
                    setCustomer={setEditCustomer}
                    sx={{ ml: 2, mr: 11 }}
                    label="名前カナ"
                  />
                </div>
                <div className="flex items-center justify-center">
                  <TextFieldParentInfoForm
                    keyName1="consort_info"
                    keyName2="family_name"
                    customer={editCustomer}
                    setCustomer={setEditCustomer}
                    sx={{ ml: 11 }}
                    label="名字"
                  />
                  <TextFieldParentInfoForm
                    keyName1="consort_info"
                    keyName2="name"
                    customer={editCustomer}
                    setCustomer={setEditCustomer}
                    sx={{ ml: 2, mr: 11 }}
                    label="名前"
                  />
                </div>
              </div>
            ) : (
              <div>
                <div className="flex items-center h-4 text-xs">
                  <div> {customerView.consort_info?.family_name_kana}</div>
                  <div className="ml-1">
                    {customerView.consort_info?.name_kana}
                  </div>
                </div>
                <div className="flex items-center h-8">
                  <div> {customerView.consort_info?.family_name}</div>
                  <div className="ml-1">{customerView.consort_info?.name}</div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div
          className={`relative col-start-1 col-span-2 flex items-center justify-center border-b border-x border-current ${consortGenderColor}`}
        >
          <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
            {editMode ? '生年月日' : '年齢'}
          </div>
          <div className="flex items-center py-3 px-4">
            {editMode ? (
              <MobileDatePickerCustomerInfoForm
                keyName1="consort_info"
                keyName2="birth_date"
                customer={editCustomer}
                setCustomer={setEditCustomer}
              />
            ) : (
              <p className="">{customerView.consort_info?.age}歳</p>
            )}
          </div>
        </div>
        <div
          className={`relative col-span-2 flex items-center justify-center border-b border-r border-current ${consortGenderColor}`}
        >
          <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
            性別
          </div>
          <div className="flex items-center py-3 px-4">
            {editMode ? (
              <SelectParentInfoForm
                keyName1="consort_info"
                keyName2="gender"
                customer={editCustomer}
                setCustomer={setEditCustomer}
                classString="pb-3"
              />
            ) : (
              customerView.consort_info?.gender
            )}
          </div>
        </div>
        <div
          className={`relative col-start-1 col-span-2 flex items-center justify-center border-b border-x border-current ${consortGenderColor}`}
        >
          <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
            TELL
          </div>
          <div className="flex items-center h-10 py-1 px-4">
            {editMode ? (
              <TextFieldParentInfoForm
                keyName1="consort_info"
                keyName2="phone_number"
                customer={editCustomer}
                setCustomer={setEditCustomer}
              />
            ) : (
              customerView.consort_info?.phone_number
            )}
          </div>
        </div>
        <div
          className={`relative col-span-2 flex items-center justify-center border-b border-r border-current ${consortGenderColor}`}
        >
          <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
            メール
          </div>
          <div className="flex items-center h-10 py-1 px-4">
            {editMode ? (
              <TextFieldParentInfoForm
                keyName1="consort_info"
                keyName2="email"
                customer={editCustomer}
                setCustomer={setEditCustomer}
              />
            ) : (
              customerView.consort_info?.email
            )}
          </div>
        </div>
      </div>
    </>
  )
}
