import React from 'react'
import { Modal, Box, Button, Typography } from '@mui/material'
import type { Inquiry } from '../../../models/Inquiry'
import type { ModalType } from '../../../models/Common'
import { InquirySaveForm, SmInquirySaveForm } from './InquirySaveForm'
import {
  usePutCustomerMutation,
  useUpdateCustomerMutation,
  useDeleteCustomerMutation,
} from '../../../vendors/graphql/generated'
import { inquiryToCustomerTranslator } from '../../../utils/inquiry/InquiryToCustomerTranslator'

interface InquiryModalProps {
  open: boolean
  handleClose: () => void
  inquiry: Inquiry
  type: ModalType
  handleRefetch: () => void
}

interface InquiryModalChildProps {
  handleClose: () => void
  type: ModalType
  createHandler: () => Promise<void>
  updateHandler: () => Promise<void>
  deleteHandler: () => Promise<void>
  inputEditInquiry: Inquiry
  setInputEditInquiry: React.Dispatch<React.SetStateAction<Inquiry>>
}

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: 'w-auto',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
}

export const InquiryModal: React.FC<InquiryModalProps> = (
  props: InquiryModalProps
) => {
  const { open, handleClose, inquiry, type, handleRefetch } = props
  const [putCustomer] = usePutCustomerMutation()
  const [updateCustomer] = useUpdateCustomerMutation()
  const [deleteCustomer] = useDeleteCustomerMutation()

  const initInquiry = {}
  const [inputEditInquiry, setInputEditInquiry] =
    React.useState<Inquiry>(initInquiry)

  React.useEffect(() => {
    if (type === 'update') {
      setInputEditInquiry(inquiry)
    }
  }, [setInputEditInquiry, inquiry])

  const createHandler = async (): Promise<void> => {
    const inputCustomer = inquiryToCustomerTranslator({
      inquiry: inputEditInquiry,
    })
    await putCustomer({ variables: { input: inputCustomer } })
    if (handleRefetch != null) {
      handleRefetch()
    }
    handleClose()
  }

  const updateHandler = async (): Promise<void> => {
    const inputCustomer = inquiryToCustomerTranslator({
      inquiry: inputEditInquiry,
    })
    const key = {
      id: inputEditInquiry.id ?? '',
      meta: inputEditInquiry.meta ?? '',
    }
    await updateCustomer({
      variables: { input: inputCustomer, key, type: 'inquiry' },
    })
    handleRefetch()
    handleClose()
  }

  const deleteHandler = async (): Promise<void> => {
    await deleteCustomer({
      variables: { key: { id: inputEditInquiry.id ?? '', meta: 'Customer' } },
    })
    handleRefetch()
    handleClose()
  }

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>
          {/* {loading && <p>Submitting...</p>}
          {error && <p>Submission error! {error.message}</p>} */}
          <div className="md:block hidden">
            <MdInquiryModal
              handleClose={handleClose}
              type={type}
              createHandler={createHandler}
              updateHandler={updateHandler}
              deleteHandler={deleteHandler}
              inputEditInquiry={inputEditInquiry}
              setInputEditInquiry={setInputEditInquiry}
            />
          </div>
          <div className="md:hidden">
            <SmInquiryModal
              handleClose={handleClose}
              type={type}
              createHandler={createHandler}
              updateHandler={updateHandler}
              deleteHandler={deleteHandler}
              inputEditInquiry={inputEditInquiry}
              setInputEditInquiry={setInputEditInquiry}
            />
          </div>
        </div>
      </Modal>
    </>
  )
}

const MdInquiryModal: React.FC<InquiryModalChildProps> = (
  props: InquiryModalChildProps
) => {
  const {
    handleClose,
    type,
    createHandler,
    updateHandler,
    deleteHandler,
    inputEditInquiry,
    setInputEditInquiry,
  } = props
  return (
    <>
      <Box sx={modalStyle} className="w-11/12 overflow-y-scroll">
        <div className="flex items-center justify-start">
          <Typography className="px-6" variant="h5">
            {type === 'create' ? '反響新規作成' : '反響更新'}
          </Typography>

          <Box sx={{ ml: 6, display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="outlined"
              onClick={() => {
                handleClose()
              }}
            >
              閉じる
            </Button>
            {type === 'create' ? (
              <Button
                sx={{ ml: 2 }}
                variant="contained"
                onClick={async () => {
                  await createHandler()
                }}
              >
                保存
              </Button>
            ) : (
              <>
                <Button
                  sx={{ ml: 2 }}
                  variant="contained"
                  onClick={async () => {
                    await updateHandler()
                  }}
                >
                  更新
                </Button>
                <Button
                  sx={{ ml: 2 }}
                  variant="contained"
                  onClick={async () => {
                    await deleteHandler()
                  }}
                >
                  削除
                </Button>
              </>
            )}
          </Box>
        </div>

        <InquirySaveForm
          inputEditInquiry={inputEditInquiry}
          setInputEditInquiry={setInputEditInquiry}
        />
      </Box>
    </>
  )
}

const SmInquiryModal: React.FC<InquiryModalChildProps> = (
  props: InquiryModalChildProps
) => {
  const {
    handleClose,
    type,
    createHandler,
    updateHandler,
    deleteHandler,
    inputEditInquiry,
    setInputEditInquiry,
  } = props
  return (
    <>
      <Box sx={modalStyle} className="h-full w-11/12 overflow-y-scroll">
        <div className="flex items-center justify-start mb-2">
          <div className="text-base">
            {type === 'create' ? '反響新規作成' : '反響更新'}
          </div>

          <Box sx={{ ml: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="outlined"
              onClick={() => {
                handleClose()
              }}
            >
              閉じる
            </Button>
            {type === 'create' ? (
              <Button
                sx={{ ml: 1 }}
                variant="contained"
                onClick={async () => {
                  await createHandler()
                }}
              >
                保存
              </Button>
            ) : (
              <>
                <Button
                  sx={{ ml: 1 }}
                  variant="contained"
                  onClick={async () => {
                    await updateHandler()
                  }}
                >
                  更新
                </Button>
                <Button
                  sx={{ ml: 1 }}
                  variant="contained"
                  onClick={async () => {
                    await deleteHandler()
                  }}
                >
                  削除
                </Button>
              </>
            )}
          </Box>
        </div>

        <SmInquirySaveForm
          inputEditInquiry={inputEditInquiry}
          setInputEditInquiry={setInputEditInquiry}
        />
      </Box>
    </>
  )
}
