import React from 'react'
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material'
import type { SelectChangeEvent } from '@mui/material'
import dayjs from '../../../vendors/Dayjs'
import type { Dayjs } from 'dayjs'

interface Candidate {
  key: string
  value: string
}

interface SelectorProps {
  candidates: Candidate[]
  selectValue: string
  setSelectValue: React.Dispatch<React.SetStateAction<string>>
  label: string
}

export const SearchSelector: React.FC<SelectorProps> = (
  props: SelectorProps
) => {
  const { candidates, selectValue, setSelectValue, label } = props
  const handleSelect = (event: SelectChangeEvent): void => {
    setSelectValue(event.target.value)
  }
  return (
    <>
      <FormControl sx={{ minWidth: 200 }}>
        <InputLabel id="select-label">{label}</InputLabel>
        <Select
          labelId="select-label"
          label={label}
          variant="standard"
          value={selectValue}
          onChange={handleSelect}
        >
          <MenuItem value="">
            <em>未選択</em>
          </MenuItem>
          {candidates.map((candidate, cdIndex) => (
            <MenuItem
              value={candidate.key}
              key={`select-candidates-${cdIndex}`}
            >
              {candidate.value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  )
}

interface SearchWeekCandidateSelectorProps {
  candidates: Candidate[]
  setStartDate: React.Dispatch<React.SetStateAction<Dayjs | null>>
  setEndDate: React.Dispatch<React.SetStateAction<Dayjs | null>>
  dateCandidate: string
  setDateCandidate: React.Dispatch<React.SetStateAction<string>>
  label: string
}

export const SearchWeekCandidateSelector: React.FC<
  SearchWeekCandidateSelectorProps
> = (props: SearchWeekCandidateSelectorProps) => {
  const {
    candidates,
    setStartDate,
    setEndDate,
    label,
    dateCandidate,
    setDateCandidate,
  } = props

  const handleSelect = (event: SelectChangeEvent): void => {
    setDateCandidate(event.target.value)
    if (event.target.value === 'none') {
      setStartDate(null)
      setEndDate(null)
    } else {
      setStartDate(dayjs(event.target.value))
      setEndDate(dayjs(event.target.value).add(6, 'day'))
    }
  }

  return (
    <>
      <FormControl sx={{ minWidth: 200 }}>
        <InputLabel id="select-label">{label}</InputLabel>
        <Select
          labelId="select-label"
          label={label}
          variant="standard"
          value={dateCandidate}
          onChange={handleSelect}
        >
          <MenuItem value="none">未選択</MenuItem>
          {candidates.map((candidate, cdIndex) => (
            <MenuItem
              value={candidate.key}
              key={`select-candidates-${cdIndex}`}
            >
              {candidate.value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  )
}
