import React from 'react'
import { TextField } from '@mui/material'
import {
  DateTimePicker,
  DatePicker,
  MobileDateTimePicker,
  MobileDatePicker,
} from '@mui/x-date-pickers'
import dayjs from '../../../../vendors/Dayjs'
import type {
  Appointment,
  AppointmentDateHeaderKey,
} from '../../../../models/Appointment'
import {
  handleChangeDateTime,
  handleChangeDate,
} from '../../../../utils/appointment/appointmentStateHandler'

interface DateTimePickerIconProps {
  label?: string
  keyName: AppointmentDateHeaderKey
  appointment: Appointment
  setAppointment: React.Dispatch<React.SetStateAction<Appointment>>
}

export const DateTimePickerIcon: React.FC<DateTimePickerIconProps> = (
  props: DateTimePickerIconProps
) => {
  const { label, keyName, appointment, setAppointment } = props

  return (
    <>
      <DateTimePicker
        label={label}
        openTo="day"
        value={
          appointment[keyName] != null ? dayjs(appointment[keyName]) : null
        }
        onChange={handleChangeDateTime({
          key: keyName,
          appointment,
          setAppointment,
        })}
        renderInput={({ inputRef, inputProps, InputProps }) => (
          <div className="flex items-center">
            <input ref={inputRef} {...inputProps} className="w-0" />
            {InputProps?.endAdornment}
          </div>
        )}
        closeOnSelect={true}
        disableMaskedInput={true}
      />
    </>
  )
}

export const DatePickerIcon: React.FC<DateTimePickerIconProps> = (
  props: DateTimePickerIconProps
) => {
  const { label, keyName, appointment, setAppointment } = props

  return (
    <>
      <DatePicker
        label={label}
        openTo="year"
        value={
          appointment[keyName] != null ? dayjs(appointment[keyName]) : null
        }
        onChange={handleChangeDate({
          key: keyName,
          appointment,
          setAppointment,
        })}
        renderInput={({ inputRef, inputProps, InputProps }) => (
          <div className="flex items-center">
            <input ref={inputRef} {...inputProps} className="w-0" />
            {InputProps?.endAdornment}
          </div>
        )}
        closeOnSelect={true}
        disableMaskedInput={true}
      />
    </>
  )
}

export const MobileDateTimePickerForm: React.FC<DateTimePickerIconProps> = (
  props: DateTimePickerIconProps
) => {
  const { label, keyName, appointment, setAppointment } = props

  return (
    <>
      <MobileDateTimePicker
        label={label}
        openTo="day"
        value={
          appointment[keyName] != null ? dayjs(appointment[keyName]) : null
        }
        onChange={handleChangeDate({
          key: keyName,
          appointment,
          setAppointment,
        })}
        renderInput={(params) => <TextField {...params} />}
        closeOnSelect={true}
        disableMaskedInput={true}
      />
    </>
  )
}

export const MobileDatePickerForm: React.FC<DateTimePickerIconProps> = (
  props: DateTimePickerIconProps
) => {
  const { label, keyName, appointment, setAppointment } = props

  return (
    <>
      <MobileDatePicker
        label={label}
        openTo="year"
        value={
          appointment[keyName] != null ? dayjs(appointment[keyName]) : null
        }
        onChange={handleChangeDate({
          key: keyName,
          appointment,
          setAppointment,
        })}
        renderInput={(params) => <TextField {...params} />}
        closeOnSelect={true}
        disableMaskedInput={true}
      />
    </>
  )
}
