import { TextFieldForm } from '../../common/editForms/TextFieldForm'
import { NumberInputForm } from '../../common/editForms/NumberInputForm'
import { SelectForm } from '../../common/editForms/SelectForm'
import type { Customer, CustomerView } from '../../../../models/Customer'

interface CustomerChartDesireBlockProps {
  editCustomer: Customer
  customerView: CustomerView
  setEditCustomer: React.Dispatch<React.SetStateAction<Customer>>
  editMode: boolean
}

export const CustomerChartDesireBlock: React.FC<
  CustomerChartDesireBlockProps
> = (props: CustomerChartDesireBlockProps) => {
  const { customerView, editCustomer, setEditCustomer, editMode } = props

  const blockColor = 'bg-cyan-100'
  return (
    <>
      <div className="grid grid-cols-12 rounded-none">
        <div
          className={`col-start-1 col-span-2 relative flex items-center justify-center border-l border-y border-current ${blockColor}`}
        >
          <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
            希望の広さ
          </div>
          <div className="py-5 px-4 text-base">
            {editMode ? (
              <NumberInputForm
                keyName="width_of_desire"
                customer={editCustomer}
                setCustomer={setEditCustomer}
                classString="w-10"
              />
            ) : (
              customerView.width_of_desire
            )}
          </div>
          <div className="absolute flex bottom-0 right-0 py-1 px-2 font-semibold text-xs">
            ㎡
          </div>
        </div>
        <div
          className={`col-span-2 relative flex items-center justify-center border-r border-y border-current ${blockColor}`}
        >
          <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
            最低
          </div>
          <div className="py-5 px-4 text-base">
            {editMode ? (
              <NumberInputForm
                keyName="width_of_min_desire"
                customer={editCustomer}
                setCustomer={setEditCustomer}
                classString="w-10"
              />
            ) : (
              customerView.width_of_min_desire
            )}
          </div>
          <div className="absolute flex bottom-0 right-0 py-1 px-2 font-semibold text-xs">
            ㎡
          </div>
        </div>
        <div
          className={`col-span-2 relative flex items-center justify-center border-r border-y border-current ${blockColor}`}
        >
          <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
            希望間取り
          </div>
          <div className="py-4 px-4 text-base">
            {editMode ? (
              <SelectForm
                keyName="desired_floor_plan"
                customer={editCustomer}
                setCustomer={setEditCustomer}
                classString="pb-3"
              />
            ) : (
              customerView.desired_floor_plan
            )}
          </div>
        </div>
        <div
          className={`col-start-1 col-span-2 relative flex items-center justify-center border-l border-current ${blockColor}`}
        >
          <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
            支払額
          </div>
          <div className="absolute bottom-0 left-0 py-1 px-2 font-semibold text-xs">
            月々
          </div>
          <div className="py-5 px-4 text-base">
            {editMode ? (
              <div className="flex items-center justify-center">
                <NumberInputForm
                  keyName="monthly_payment"
                  customer={editCustomer}
                  setCustomer={setEditCustomer}
                  classString="w-20"
                />
                <div className="font-semibold text-sm ml-1">円</div>
              </div>
            ) : (
              customerView.monthly_payment
            )}
          </div>
          {!editMode && (
            <div className="absolute flex bottom-0 right-0 py-1 px-2 font-semibold text-xs">
              万円
            </div>
          )}
        </div>
        <div
          className={`col-span-2 relative flex items-center justify-center border-r border-current ${blockColor}`}
        >
          <div className="absolute bottom-0 left-0 py-1 px-2 font-semibold text-xs">
            ボーナス
          </div>
          <div className="py-5 px-4 text-base">
            {editMode ? (
              <div className="flex items-center justify-center">
                <NumberInputForm
                  keyName="bonus_payment"
                  customer={editCustomer}
                  setCustomer={setEditCustomer}
                  classString="w-20"
                />
                <div className="font-semibold text-sm ml-1">円</div>
              </div>
            ) : (
              customerView.bonus_payment
            )}
          </div>
          {!editMode && (
            <div className="absolute flex bottom-0 right-0 py-1 px-2 font-semibold text-xs">
              万円
            </div>
          )}
        </div>
        <div
          className={`col-span-2 relative flex items-center justify-center border-r border-current ${blockColor}`}
        >
          <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
            予算MAX
          </div>
          <div className="py-5 px-4 text-base">
            {editMode ? (
              <div className="flex items-center justify-center">
                <NumberInputForm
                  keyName="budget_max"
                  customer={editCustomer}
                  setCustomer={setEditCustomer}
                  classString="w-20"
                />
                <div className="font-semibold text-sm ml-1">円</div>
              </div>
            ) : (
              customerView.budget_max
            )}
          </div>
          {!editMode && (
            <div className="absolute flex bottom-0 right-0 py-1 px-2 font-semibold text-xs">
              万円（諸費用込）
            </div>
          )}
        </div>
        <div
          className={`col-start-1 col-span-12 relative flex items-center justify-center border border-current ${blockColor}`}
        >
          <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
            ネット検索条件
          </div>
          <div className="pt-6 pb-4 px-4 text-base w-full">
            {editMode ? (
              <TextFieldForm
                keyName="internet_search_conditions"
                customer={editCustomer}
                setCustomer={setEditCustomer}
              />
            ) : (
              customerView.internet_search_conditions
            )}
          </div>
        </div>
        <div
          className={`col-start-1 col-span-12 relative flex items-center justify-center border-x border-b border-current ${blockColor}`}
        >
          <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
            希望エリア・駅
          </div>
          <div className="pt-6 pb-4 px-4 text-base w-full">
            {editMode ? (
              <TextFieldForm
                keyName="desired_area_and_station"
                customer={editCustomer}
                setCustomer={setEditCustomer}
              />
            ) : (
              customerView.desired_area_and_station
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export const SmCustomerChartDesireBlock: React.FC<
  CustomerChartDesireBlockProps
> = (props: CustomerChartDesireBlockProps) => {
  const { customerView, editCustomer, setEditCustomer, editMode } = props

  const blockColor = 'bg-cyan-100'
  return (
    <>
      <div className="grid grid-cols-4 rounded-none">
        <div
          className={`col-start-1 col-span-2 relative flex items-center justify-center border-l border-y border-current ${blockColor}`}
        >
          <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
            希望の広さ
          </div>
          <div className="flex items-center h-10 py-1 px-4 text-base">
            {editMode ? (
              <NumberInputForm
                keyName="width_of_desire"
                customer={editCustomer}
                setCustomer={setEditCustomer}
                classString="w-10"
              />
            ) : (
              customerView.width_of_desire
            )}
          </div>
          <div className="absolute flex bottom-0 right-0 px-1 font-semibold text-xs">
            ㎡
          </div>
        </div>
        <div
          className={`col-span-2 relative flex items-center justify-center border-r border-y border-current ${blockColor}`}
        >
          <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
            最低
          </div>
          <div className="flex items-center h-10 py-1 px-4 text-base">
            {editMode ? (
              <NumberInputForm
                keyName="width_of_min_desire"
                customer={editCustomer}
                setCustomer={setEditCustomer}
                classString="w-10"
              />
            ) : (
              customerView.width_of_min_desire
            )}
          </div>
          <div className="absolute flex bottom-0 right-0 px-1 font-semibold text-xs">
            ㎡
          </div>
        </div>
        <div
          className={`col-span-2 relative flex items-center justify-center border-x border-current ${blockColor}`}
        >
          <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
            希望間取り
          </div>
          <div className="flex items-center h-10 py-1 px-4 text-base">
            {editMode ? (
              <SelectForm
                keyName="desired_floor_plan"
                customer={editCustomer}
                setCustomer={setEditCustomer}
                classString="pb-3"
              />
            ) : (
              customerView.desired_floor_plan
            )}
          </div>
        </div>
        <div
          className={`col-start-1 col-span-2 relative flex items-center justify-center border-l border-y border-current ${blockColor}`}
        >
          <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
            支払額
          </div>
          <div className="absolute bottom-0 left-0 px-1 font-semibold text-xs">
            月々
          </div>
          <div className="flex items-center h-10 py-1 pl-8 text-base">
            {editMode ? (
              <div className="flex items-center justify-center">
                <NumberInputForm
                  keyName="monthly_payment"
                  customer={editCustomer}
                  setCustomer={setEditCustomer}
                  classString="w-20"
                />
                <div className="font-semibold text-sm ml-1">円</div>
              </div>
            ) : (
              customerView.monthly_payment
            )}
          </div>
          {!editMode && (
            <div className="absolute flex bottom-0 right-0 px-1 font-semibold text-xs">
              万円
            </div>
          )}
        </div>
        <div
          className={`col-span-2 relative flex items-center justify-center border-y border-r border-current ${blockColor}`}
        >
          <div className="absolute bottom-0 left-0 px-1 font-semibold text-xs">
            ボーナス
          </div>
          <div className="flex items-center h-12 py-1 pl-10 pr-4 text-base">
            {editMode ? (
              <div className="flex items-center justify-center">
                <NumberInputForm
                  keyName="bonus_payment"
                  customer={editCustomer}
                  setCustomer={setEditCustomer}
                  classString="w-20"
                />
                <div className="font-semibold text-sm ml-1">円</div>
              </div>
            ) : (
              customerView.bonus_payment
            )}
          </div>
          {!editMode && (
            <div className="absolute flex bottom-0 right-0 px-1 font-semibold text-xs">
              万円
            </div>
          )}
        </div>
        <div
          className={`col-span-3 relative flex items-center justify-center border-x border-current ${blockColor}`}
        >
          <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
            予算MAX
          </div>
          <div className="flex items-center h-12 py-1 px-4 text-base">
            {editMode ? (
              <div className="flex items-center justify-center">
                <NumberInputForm
                  keyName="budget_max"
                  customer={editCustomer}
                  setCustomer={setEditCustomer}
                  classString="w-20"
                />
                <div className="font-semibold text-sm ml-1">円</div>
              </div>
            ) : (
              customerView.budget_max
            )}
          </div>
          {!editMode && (
            <div className="absolute flex bottom-0 right-0 px-1 font-semibold text-xs">
              万円（諸費用込）
            </div>
          )}
        </div>
        <div
          className={`col-start-1 col-span-4 relative flex items-center justify-center border border-current ${blockColor}`}
        >
          <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
            ネット検索条件
          </div>
          <div className="flex items-center h-12 py-1 px-4 text-base w-full">
            {editMode ? (
              <TextFieldForm
                keyName="internet_search_conditions"
                customer={editCustomer}
                setCustomer={setEditCustomer}
              />
            ) : (
              customerView.internet_search_conditions
            )}
          </div>
        </div>
        <div
          className={`col-start-1 col-span-4 relative flex items-center justify-center border-x border-b border-current ${blockColor}`}
        >
          <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
            希望エリア・駅
          </div>
          <div className="flex items-center h-12 py-1 px-4 text-base w-full">
            {editMode ? (
              <TextFieldForm
                keyName="desired_area_and_station"
                customer={editCustomer}
                setCustomer={setEditCustomer}
              />
            ) : (
              customerView.desired_area_and_station
            )}
          </div>
        </div>
      </div>
    </>
  )
}
