import React from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import type { Appointment } from '../../../models/Appointment'
import type { ModalType } from '../../../models/Common'
import { BooleanRadioForm } from './editForms/BooleanRadioForm'
import {
  DateTimePickerIcon,
  DatePickerIcon,
  MobileDateTimePickerForm,
  MobileDatePickerForm,
} from './editForms/DatePickersIcon'
import { NumberInputForm } from './editForms/NumberInputForm'
import { SelectForm } from './editForms/SelectForm'
import { TextFieldForm } from './editForms/TextFieldForm'
import {
  dateViewJPConverter,
  datetimeViewJPConverter,
} from '../../../utils/common/stringParser'

interface AppointmentSaveFormProps {
  inputEditAppointment: Appointment
  setInputEditAppointment: React.Dispatch<React.SetStateAction<Appointment>>
  type: ModalType
}

export const AppointmentSaveForm: React.FC<AppointmentSaveFormProps> = (
  props: AppointmentSaveFormProps
) => {
  const { inputEditAppointment, setInputEditAppointment, type } = props
  const isCreate = type === 'create'

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className="grid grid-cols-10 rounded-none">
          <div className="col-start-1 col-span-2 flex items-center justify-center">
            <div className="py-1.5">
              <TextFieldForm
                label="名字（フリガナ）"
                keyName="family_name_kana"
                appointment={inputEditAppointment}
                setAppointment={setInputEditAppointment}
                disabled={isCreate}
              />
            </div>
          </div>
          <div className="col-span-2 flex items-center justify-center">
            <div className="py-1.5">
              <TextFieldForm
                label="名前（フリガナ）"
                keyName="name_kana"
                appointment={inputEditAppointment}
                setAppointment={setInputEditAppointment}
                disabled={isCreate}
              />
            </div>
          </div>
          <div className="col-span-2 flex items-center justify-center">
            <div className="py-1.5">
              <SelectForm
                label="性別"
                keyName="gender"
                appointment={inputEditAppointment}
                setAppointment={setInputEditAppointment}
                sx={{ minWidth: 100 }}
                classString="pt-1"
                disabled={isCreate}
              />
            </div>
          </div>
          <div className="relative col-span-3 flex items-center justify-start">
            <div className="absolute top-0 left-0 py-1 px-2 text-xs">
              生年月日
            </div>
            <div className="flex items-center py-2 pl-10">
              {dateViewJPConverter(inputEditAppointment.birth_date)}
              {!isCreate && (
                <DatePickerIcon
                  keyName="birth_date"
                  appointment={inputEditAppointment}
                  setAppointment={setInputEditAppointment}
                />
              )}
            </div>
          </div>
          <div className="col-start-1 col-span-2 flex items-center justify-center">
            <div className="py-1.5">
              <TextFieldForm
                label="名字"
                keyName="family_name"
                appointment={inputEditAppointment}
                setAppointment={setInputEditAppointment}
                disabled={isCreate}
              />
            </div>
          </div>
          <div className="col-span-2 flex items-center justify-center">
            <div className="py-1.5">
              <TextFieldForm
                label="名前"
                keyName="name"
                appointment={inputEditAppointment}
                setAppointment={setInputEditAppointment}
                disabled={isCreate}
              />
            </div>
          </div>
          <div className="col-span-2 flex items-center justify-center">
            <div className="py-1.5">
              <SelectForm
                label="案内後ステータス"
                keyName="appointment_status"
                appointment={inputEditAppointment}
                setAppointment={setInputEditAppointment}
                sx={{ minWidth: 180 }}
                classString="pt-1"
                disabled={isCreate}
              />
            </div>
          </div>
          <div className="relative col-span-4 flex items-center justify-start">
            <div className="absolute top-0 left-0 py-1 px-2 text-xs">
              案内日時
            </div>
            <div className="flex items-center py-2 pl-10">
              {datetimeViewJPConverter(inputEditAppointment.appointment_date)}
              <DateTimePickerIcon
                keyName="appointment_date"
                appointment={inputEditAppointment}
                setAppointment={setInputEditAppointment}
              />
            </div>
          </div>
          <div className="col-start-1 col-span-2 flex items-center justify-center">
            <div className="py-1.5">
              <SelectForm
                label="ランク"
                keyName="rank"
                appointment={inputEditAppointment}
                setAppointment={setInputEditAppointment}
                sx={{ minWidth: 150 }}
                classString="pt-1"
              />
            </div>
          </div>
          <div className="col-span-2 flex items-center justify-center">
            <div className="py-1.5">
              <TextFieldForm
                label="案内家族数"
                keyName="appointment_family_number"
                appointment={inputEditAppointment}
                setAppointment={setInputEditAppointment}
              />
            </div>
          </div>
          <div className="col-span-2 flex items-center justify-center">
            <div className="py-1.5">
              <TextFieldForm
                label="区"
                keyName="address_ward"
                appointment={inputEditAppointment}
                setAppointment={setInputEditAppointment}
              />
            </div>
          </div>
          <div className="col-span-2 flex items-center justify-center">
            <div className="py-1.5">
              <NumberInputForm
                label="問い合わせ価格"
                keyName="inquiry_price"
                appointment={inputEditAppointment}
                setAppointment={setInputEditAppointment}
              />
            </div>
          </div>
          <div className="col-span-2 flex items-center justify-center">
            <div className="py-1.5">
              <SelectForm
                label="間取り"
                keyName="floor_plan"
                appointment={inputEditAppointment}
                setAppointment={setInputEditAppointment}
                sx={{ minWidth: 150 }}
                classString="pt-1"
              />
            </div>
          </div>
          <div className="col-start-1 col-span-2 flex items-center justify-center">
            <div className="py-1.5">
              <TextFieldForm
                label="毎月返済額"
                keyName="monthly_repayment_amount"
                appointment={inputEditAppointment}
                setAppointment={setInputEditAppointment}
              />
            </div>
          </div>
          <div className="col-span-2 flex items-center justify-center">
            <div className="py-1.5">
              <NumberInputForm
                label="年収（円）"
                keyName="annual_income"
                appointment={inputEditAppointment}
                setAppointment={setInputEditAppointment}
                disabled={isCreate}
              />
            </div>
          </div>
          <div className="col-span-2 flex items-center justify-center">
            <div className="py-1.5">
              <TextFieldForm
                label="自己資金"
                keyName="own_resources"
                appointment={inputEditAppointment}
                setAppointment={setInputEditAppointment}
              />
            </div>
          </div>
          <div className="col-span-2 flex items-center justify-center">
            <div className="py-1.5">
              <NumberInputForm
                label="回数"
                keyName="appointment_count"
                appointment={inputEditAppointment}
                setAppointment={setInputEditAppointment}
              />
            </div>
          </div>
          <div className="col-span-2 flex items-center justify-center">
            <div className="py-1.5">
              <TextFieldForm
                label="媒体"
                keyName="media"
                appointment={inputEditAppointment}
                setAppointment={setInputEditAppointment}
              />
            </div>
          </div>
          <div className="col-start-1 col-span-2 flex items-center justify-center">
            <div className="py-1.5">
              <BooleanRadioForm
                label="ライン"
                keyName="line_resistration"
                appointment={inputEditAppointment}
                setAppointment={setInputEditAppointment}
                disabled={isCreate}
              />
            </div>
          </div>
          <div className="col-span-2 flex items-center justify-center">
            <div className="py-1.5">
              <TextFieldForm
                label="交渉ヘルプ"
                keyName="negotiation_person"
                appointment={inputEditAppointment}
                setAppointment={setInputEditAppointment}
              />
            </div>
          </div>
          <div className="col-span-2 flex items-center justify-center">
            <div className="py-1.5">
              <TextFieldForm
                label="タッチ"
                keyName="memo"
                appointment={inputEditAppointment}
                setAppointment={setInputEditAppointment}
              />
            </div>
          </div>
          <div className="col-span-2 flex items-center justify-center">
            <div className="py-1.5">
              <TextFieldForm
                label="待ち合わせ場所等"
                keyName="remarks"
                appointment={inputEditAppointment}
                setAppointment={setInputEditAppointment}
              />
            </div>
          </div>
          <div className="col-span-2 flex items-center justify-center">
            <div className="py-1.5">
              <TextFieldForm
                label="ネック"
                keyName="neck"
                appointment={inputEditAppointment}
                setAppointment={setInputEditAppointment}
              />
            </div>
          </div>
          <div className="col-start-1 col-span-2 flex items-center justify-center">
            <div className="py-1.5">
              <SelectForm
                label="準備"
                keyName="preparation"
                appointment={inputEditAppointment}
                setAppointment={setInputEditAppointment}
                sx={{ minWidth: 150 }}
                classString="pt-1"
              />
            </div>
          </div>
          <div className="col-span-2 flex items-center justify-center">
            <div className="py-1.5">
              <BooleanRadioForm
                label="準備チェック"
                keyName="preparation_check"
                appointment={inputEditAppointment}
                setAppointment={setInputEditAppointment}
                trueValue="済"
                falseValue="未"
              />
            </div>
          </div>
        </div>
      </LocalizationProvider>
    </>
  )
}

export const SmAppointmentSaveForm: React.FC<AppointmentSaveFormProps> = (
  props: AppointmentSaveFormProps
) => {
  const { inputEditAppointment, setInputEditAppointment, type } = props
  const isCreate = type === 'create'

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className="grid grid-cols-4 rounded-none">
          <div className="col-start-1 col-span-2 flex items-center justify-center">
            <div className="py-1.5 pr-1">
              <TextFieldForm
                label="名字（フリガナ）"
                keyName="family_name_kana"
                appointment={inputEditAppointment}
                setAppointment={setInputEditAppointment}
                disabled={isCreate}
              />
            </div>
          </div>
          <div className="col-span-2 flex items-center justify-center">
            <div className="py-1.5">
              <TextFieldForm
                label="名前（フリガナ）"
                keyName="name_kana"
                appointment={inputEditAppointment}
                setAppointment={setInputEditAppointment}
                disabled={isCreate}
              />
            </div>
          </div>
          <div className="col-start-1 col-span-2 flex items-center justify-center">
            <div className="py-1.5 pr-1">
              <TextFieldForm
                label="名字"
                keyName="family_name"
                appointment={inputEditAppointment}
                setAppointment={setInputEditAppointment}
                disabled={isCreate}
              />
            </div>
          </div>
          <div className="col-span-2 flex items-center justify-center">
            <div className="py-1.5">
              <TextFieldForm
                label="名前"
                keyName="name"
                appointment={inputEditAppointment}
                setAppointment={setInputEditAppointment}
                disabled={isCreate}
              />
            </div>
          </div>
          <div className="col-start-1 col-span-2 flex items-center justify-start">
            <div className="py-1.5">
              <SelectForm
                label="性別"
                keyName="gender"
                appointment={inputEditAppointment}
                setAppointment={setInputEditAppointment}
                sx={{ minWidth: 100 }}
                classString=""
                disabled={isCreate}
              />
            </div>
          </div>
          <div className="col-span-2 flex items-center justify-start">
            <div className="flex items-center pt-2">
              {!isCreate && (
                <MobileDatePickerForm
                  label="生年月日"
                  keyName="birth_date"
                  appointment={inputEditAppointment}
                  setAppointment={setInputEditAppointment}
                />
              )}
            </div>
          </div>
          <div className="col-span-2 flex items-center justify-center">
            <div className="py-1.5">
              <SelectForm
                label="案内後ステータス"
                keyName="appointment_status"
                appointment={inputEditAppointment}
                setAppointment={setInputEditAppointment}
                sx={{ minWidth: 180 }}
                classString=""
                disabled={isCreate}
              />
            </div>
          </div>
          <div className="col-span-3 flex items-center justify-start">
            <div className="flex items-center pt-2">
              <MobileDateTimePickerForm
                label="案内日時"
                keyName="appointment_date"
                appointment={inputEditAppointment}
                setAppointment={setInputEditAppointment}
              />
            </div>
          </div>
          <div className="col-start-1 col-span-2 flex items-center justify-center">
            <div className="py-1.5 pr-1">
              <SelectForm
                label="ランク"
                keyName="rank"
                appointment={inputEditAppointment}
                setAppointment={setInputEditAppointment}
                sx={{ minWidth: 140 }}
                classString="pt-1"
              />
            </div>
          </div>
          <div className="col-span-2 flex items-center justify-center">
            <div className="py-1.5">
              <TextFieldForm
                label="案内家族数"
                keyName="appointment_family_number"
                appointment={inputEditAppointment}
                setAppointment={setInputEditAppointment}
              />
            </div>
          </div>
          <div className="col-span-2 flex items-center justify-center">
            <div className="py-1.5 pr-1">
              <TextFieldForm
                label="区"
                keyName="address_ward"
                appointment={inputEditAppointment}
                setAppointment={setInputEditAppointment}
              />
            </div>
          </div>
          <div className="col-span-2 flex items-center justify-center">
            <div className="py-1.5">
              <NumberInputForm
                label="問い合わせ価格"
                keyName="inquiry_price"
                appointment={inputEditAppointment}
                setAppointment={setInputEditAppointment}
              />
            </div>
          </div>
          <div className="col-span-2 flex items-center justify-center">
            <div className="py-1.5">
              <SelectForm
                label="間取り"
                keyName="floor_plan"
                appointment={inputEditAppointment}
                setAppointment={setInputEditAppointment}
                sx={{ minWidth: 150 }}
                classString="pt-1"
              />
            </div>
          </div>
          <div className="col-start-1 col-span-2 flex items-center justify-center">
            <div className="py-1.5 pr-1">
              <TextFieldForm
                label="毎月返済額"
                keyName="monthly_repayment_amount"
                appointment={inputEditAppointment}
                setAppointment={setInputEditAppointment}
              />
            </div>
          </div>
          <div className="col-span-2 flex items-center justify-center">
            <div className="py-1.5">
              <NumberInputForm
                label="年収（円）"
                keyName="annual_income"
                appointment={inputEditAppointment}
                setAppointment={setInputEditAppointment}
                disabled={isCreate}
              />
            </div>
          </div>
          <div className="col-span-2 flex items-center justify-center">
            <div className="py-1.5 pr-1">
              <TextFieldForm
                label="自己資金"
                keyName="own_resources"
                appointment={inputEditAppointment}
                setAppointment={setInputEditAppointment}
              />
            </div>
          </div>
          <div className="col-span-2 flex items-center justify-center">
            <div className="py-1.5">
              <NumberInputForm
                label="回数"
                keyName="appointment_count"
                appointment={inputEditAppointment}
                setAppointment={setInputEditAppointment}
              />
            </div>
          </div>
          <div className="col-span-2 flex items-center justify-center">
            <div className="py-1.5">
              <TextFieldForm
                label="媒体"
                keyName="media"
                appointment={inputEditAppointment}
                setAppointment={setInputEditAppointment}
              />
            </div>
          </div>
          <div className="col-start-1 col-span-2 flex items-center justify-center">
            <div className="py-1.5">
              <BooleanRadioForm
                label="ライン"
                keyName="line_resistration"
                appointment={inputEditAppointment}
                setAppointment={setInputEditAppointment}
                disabled={isCreate}
              />
            </div>
          </div>
          <div className="col-span-2 flex items-center justify-center">
            <div className="py-1.5 pl-2">
              <TextFieldForm
                label="交渉ヘルプ"
                keyName="negotiation_person"
                appointment={inputEditAppointment}
                setAppointment={setInputEditAppointment}
              />
            </div>
          </div>
          <div className="col-span-2 flex items-center justify-center">
            <div className="py-1.5 pr-1">
              <TextFieldForm
                label="タッチ"
                keyName="memo"
                appointment={inputEditAppointment}
                setAppointment={setInputEditAppointment}
              />
            </div>
          </div>
          <div className="col-span-2 flex items-center justify-center">
            <div className="py-1.5">
              <TextFieldForm
                label="待ち合わせ場所等"
                keyName="remarks"
                appointment={inputEditAppointment}
                setAppointment={setInputEditAppointment}
              />
            </div>
          </div>
          <div className="col-span-2 flex items-center justify-center">
            <div className="py-1.5">
              <TextFieldForm
                label="ネック"
                keyName="neck"
                appointment={inputEditAppointment}
                setAppointment={setInputEditAppointment}
              />
            </div>
          </div>
          <div className="col-start-1 col-span-2 flex items-center justify-center">
            <div className="py-1.5">
              <SelectForm
                label="準備"
                keyName="preparation"
                appointment={inputEditAppointment}
                setAppointment={setInputEditAppointment}
                sx={{ minWidth: 150 }}
                classString="pt-1"
              />
            </div>
          </div>
          <div className="col-start-1 col-span-2 flex items-center justify-center">
            <div className="py-1.5">
              <BooleanRadioForm
                label="準備チェック"
                keyName="preparation_check"
                appointment={inputEditAppointment}
                setAppointment={setInputEditAppointment}
                trueValue="済"
                falseValue="未"
              />
            </div>
          </div>
        </div>
      </LocalizationProvider>
    </>
  )
}
