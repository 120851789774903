import { Select, MenuItem, FormControl, InputLabel } from '@mui/material'
import type { SxProps } from '@mui/material'
import type {
  Appointment,
  AppointmentSelectHeaderKey,
} from '../../../../models/Appointment'
import { menuItemsDict } from '../../../../fixture/common/formOptions'
import { handleChangeSelect } from '../../../../utils/appointment/appointmentStateHandler'

interface SelectFormProps {
  label?: string
  keyName: AppointmentSelectHeaderKey
  appointment: Appointment
  setAppointment: React.Dispatch<React.SetStateAction<Appointment>>
  sx?: SxProps
  classString?: string
  disabled?: boolean
}

export const SelectForm: React.FC<SelectFormProps> = (
  props: SelectFormProps
) => {
  const {
    label,
    keyName,
    appointment,
    setAppointment,
    classString,
    sx,
    disabled,
  } = props
  return (
    <div className={classString}>
      <FormControl sx={sx} disabled={disabled}>
        <InputLabel id={`select-${keyName}-labelId`}>{label}</InputLabel>
        <Select
          id={`select-${keyName}-id`}
          labelId={`select-${keyName}-labelId`}
          variant="standard"
          value={
            appointment[keyName] != null ? (appointment[keyName] as string) : ''
          }
          onChange={handleChangeSelect({
            key: keyName,
            appointment,
            setAppointment,
          })}
        >
          <MenuItem value="">
            <em>未選択</em>
          </MenuItem>
          {menuItemsDict[keyName].map((menuItem, menui) => (
            <MenuItem value={menuItem.key} key={`menui-${menui}`}>
              {menuItem.value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}
