import React from 'react'
import { TextField } from '@mui/material'
import type {
  Customer,
  CustomerNumberHeaderKey,
  ParentNumberHeaderKey,
  ParentInfoRootKey,
} from '../../../../models/Customer'
// import {
//   handleChangeNumber,
//   handleChangeParentInfoNumber,
// } from '../../../../utils/customer/customerStateHandler'

interface NumberInputFormProps {
  label?: string
  keyName: CustomerNumberHeaderKey
  customer: Customer
  setCustomer: React.Dispatch<React.SetStateAction<Customer>>
  classString: string
}

interface NumberInputParentInfoFormProps {
  label?: string
  keyName1: ParentInfoRootKey
  keyName2: ParentNumberHeaderKey
  customer: Customer
  setCustomer: React.Dispatch<React.SetStateAction<Customer>>
  classString: string
}

interface HandleChangeNumberProps {
  setInputTextValue: React.Dispatch<React.SetStateAction<number | undefined>>
}

const handleChangeNumber =
  (props: HandleChangeNumberProps) =>
  (event: React.ChangeEvent<HTMLInputElement>) => {
    const { setInputTextValue } = props
    const result = Math.abs(Number(event.target.value)).toString()

    if (result === 'NaN') {
      setInputTextValue(0)
    } else {
      setInputTextValue(Number(result))
    }
  }

export const NumberInputForm: React.FC<NumberInputFormProps> = (
  props: NumberInputFormProps
) => {
  const { label, keyName, customer, setCustomer, classString } = props

  const initTextValue = customer[keyName]
  const [inputTextValue, setInputTextValue] = React.useState<
    number | undefined
  >(undefined)

  React.useEffect(() => {
    if (inputTextValue !== initTextValue) {
      setInputTextValue(initTextValue)
    }
  }, [initTextValue])

  const onBlur = (): void => {
    if (initTextValue !== inputTextValue && setCustomer) {
      setCustomer({ ...customer, [keyName]: inputTextValue })
    }
  }

  return (
    <>
      <TextField
        id={keyName}
        label={label}
        variant="standard"
        value={inputTextValue ?? ''}
        className={classString}
        onChange={handleChangeNumber({
          setInputTextValue,
        })}
        onBlur={onBlur}
        inputProps={{
          inputMode: 'numeric',
          pattern: '[0-9]*',
        }}
      />
    </>
  )
}

// export const NumberInputForm: React.FC<NumberInputFormProps> = (
//   props: NumberInputFormProps
// ) => {
//   const { label, keyName, customer, setCustomer, classString } = props

//   return (
//     <>
//       <TextField
//         id={keyName}
//         label={label}
//         variant="standard"
//         value={customer[keyName] ?? ''}
//         className={classString}
//         onChange={handleChangeNumber({
//           key: keyName,
//           customer,
//           setCustomer,
//         })}
//         inputProps={{
//           inputMode: 'numeric',
//           pattern: '[0-9]*',
//         }}
//       />
//     </>
//   )
// }

export const NumberInputParentInfoForm: React.FC<
  NumberInputParentInfoFormProps
> = (props: NumberInputParentInfoFormProps) => {
  const { label, keyName1, keyName2, customer, setCustomer, classString } =
    props
  let initTextValue: number | undefined
  const parentInfo = customer[keyName1]
  if (parentInfo != null && !parentInfo[keyName2] != null) {
    initTextValue = parentInfo[keyName2]
  }

  const [inputTextValue, setInputTextValue] = React.useState<
    number | undefined
  >(undefined)

  React.useEffect(() => {
    if (inputTextValue !== initTextValue) {
      setInputTextValue(initTextValue)
    }
  }, [initTextValue])

  const onBlur = (): void => {
    if (initTextValue !== inputTextValue && setCustomer) {
      const parentInfo = customer[keyName1]
      if (parentInfo != null) {
        parentInfo[keyName2] = inputTextValue
        customer[keyName1] = parentInfo
      } else {
        customer[keyName1] = { [keyName2]: inputTextValue }
      }
      setCustomer({ ...customer })
    }
  }
  return (
    <>
      <TextField
        id={keyName2}
        label={label}
        variant="standard"
        value={inputTextValue ?? ''}
        className={classString}
        onChange={handleChangeNumber({
          setInputTextValue,
        })}
        onBlur={onBlur}
        inputProps={{
          inputMode: 'numeric',
          pattern: '[0-9]*',
        }}
      />
    </>
  )
}
