import type { CustomerHistory } from '../../models/CustomerHistory'
import type { QueryCustomerHistoryQuery } from '../../vendors/graphql/generated'

export const customerHistoryGraphqlConverter = (props: {
  data: QueryCustomerHistoryQuery
}): CustomerHistory[] => {
  /*
  __typenameを除く、null要素は除く
  */
  const { data } = props

  const dataCustomerHistories = data.queryCustomerHistory
  let customerHistories: CustomerHistory[]
  if (dataCustomerHistories) {
    customerHistories = dataCustomerHistories.map((customerHistory) => {
      const item: CustomerHistory = {}
      if (customerHistory == null) {
        return item
      }
      if (customerHistory.id) {
        item.id = customerHistory.id
      }

      if (customerHistory.meta) {
        item.meta = customerHistory.meta
      }

      if (customerHistory.customer_id) {
        item.customer_id = customerHistory.customer_id
      }

      if (customerHistory.created_at) {
        item.created_at = customerHistory.created_at
      }

      if (customerHistory.updated_at) {
        item.updated_at = customerHistory.updated_at
      }

      if (customerHistory.memo) {
        item.memo = customerHistory.memo
      }

      if (customerHistory.history_date) {
        item.history_date = customerHistory.history_date
      }

      return item
    })
  } else {
    customerHistories = []
  }
  return customerHistories
}
