import React from 'react'
import { TextField } from '@mui/material'
// import SearchIcon from '@mui/icons-material/Search'

interface SearchTextFieldProps {
  label: string
  textValue?: string
  setTextValue?: React.Dispatch<React.SetStateAction<string>>
}

export const SearchTextField: React.FC<SearchTextFieldProps> = (
  props: SearchTextFieldProps
) => {
  const { label, textValue, setTextValue } = props
  if (textValue == null || setTextValue == null) {
    return <></>
  }

  return (
    <div className="flex items-end">
      {/* <SearchIcon
          sx={{ color: 'ActiveCaption.active', mx: 1, mt: 1.4, mb: 1 }}
        /> */}
      <TextField
        className="w-full"
        id="search-input"
        label={label}
        variant="standard"
        value={textValue}
        onChange={(e) => {
          setTextValue(e.target.value)
        }}
      />
    </div>
  )
}
