import {
  Checkbox,
  FormGroup,
  FormControlLabel,
  FormControl,
} from '@mui/material'
import React from 'react'
import type {
  Customer,
  CustomerCheckBoxHeaderKey,
} from '../../../../models/Customer'
import {
  compromisePointCandidates as candidates,
  compromisePointDict,
} from '../../../../fixture/common/formOptions'
import { handleChangeCheckbox } from '../../../../utils/customer/customerStateHandler'

interface CompromisePointCheckBoxFormProps {
  label?: string
  keyName: CustomerCheckBoxHeaderKey
  customer: Customer
  setCustomer: React.Dispatch<React.SetStateAction<Customer>>
  classString?: string
}

export const CompromisePointCheckBoxForm: React.FC<
  CompromisePointCheckBoxFormProps
> = (props: CompromisePointCheckBoxFormProps) => {
  const { keyName, customer, setCustomer, classString } = props

  const compromisePoint = customer.compromise_point
    ? customer.compromise_point
    : {}

  return (
    <div className={classString}>
      <FormControl>
        <FormGroup>
          {candidates.map((itemKey, index) => {
            return (
              <FormControlLabel
                key={`compromisePointFormGroupKey-${index}`}
                control={
                  <Checkbox
                    checked={Boolean(compromisePoint[itemKey])}
                    onChange={handleChangeCheckbox({
                      key1: keyName,
                      key2: itemKey,
                      customer,
                      setCustomer,
                    })}
                    name={itemKey}
                  />
                }
                label={compromisePointDict[itemKey]}
              />
            )
          })}
        </FormGroup>
      </FormControl>
    </div>
  )
}
