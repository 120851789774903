import React from 'react'
import { TextField } from '@mui/material'
import type {
  Inquiry,
  InquiryHeader,
  InquiryTextHeaderKey,
  InquiryNumberHeaderKey,
  InquiryDateHeaderKey,
  InquirySelectHeaderKey,
  InquiryBoolHeaderKey,
} from '../../../models/Inquiry'
import { DateTimePicker } from '@mui/x-date-pickers'
import dayjs from '../../../vendors/Dayjs'
import { datetimeViewJPConverter } from '../../../utils/common/stringParser'
import {
  numberInputs,
  selectInputs,
  dateInputs,
  cellTextInputs,
  boolInputs,
} from '../../../fixture/inquiry/formOptions'
import {
  handleChangeText,
  handleChangeNumber,
  handleChangeDateTime,
} from '../../../utils/inquiry/inquiryStateHandler'
import { BooleanRadioForm } from './editForms/BooleanRadioForm'
import { SelectForm } from './editForms/SelectForm'

interface TableCellInquiryFieldsProps {
  header: InquiryHeader
  inquiry: Inquiry
  setInquiry: React.Dispatch<React.SetStateAction<Inquiry>>
}

export const TableCellInquiryFields: React.FC<TableCellInquiryFieldsProps> = (
  props: TableCellInquiryFieldsProps
) => {
  const { header, inquiry, setInquiry } = props

  return (
    <>
      {header.key === 'full_name' && (
        <div className="flex justify-start items-center">
          <div>
            <div className="w-36 flex">
              <TextField
                id={'family_name'}
                label={'名字'}
                variant="standard"
                value={inquiry.family_name ?? ''}
                sx={{
                  mr: 1,
                }}
                onChange={handleChangeText({
                  key: 'family_name',
                  inquiry,
                  setInquiry,
                })}
              />
              <TextField
                id={'name'}
                label={'名前'}
                variant="standard"
                value={inquiry.name ?? ''}
                onChange={handleChangeText({
                  key: 'name',
                  inquiry,
                  setInquiry,
                })}
              />
            </div>
            <div className="w-36 flex">
              <TextField
                id={'family_name_kana'}
                label={'名字カナ'}
                variant="standard"
                value={inquiry.family_name_kana ?? ''}
                sx={{
                  mr: 1,
                }}
                onChange={handleChangeText({
                  key: 'family_name_kana',
                  inquiry,
                  setInquiry,
                })}
              />
              <TextField
                id={'name_kana'}
                label={'名前カナ'}
                variant="standard"
                value={inquiry.name_kana ?? ''}
                onChange={handleChangeText({
                  key: 'name_kana',
                  inquiry,
                  setInquiry,
                })}
              />
            </div>
          </div>
          <div className="ml-2">
            <SelectForm
              label="案内済客フラグ"
              keyName="appointment_status"
              inquiry={inquiry}
              setInquiry={setInquiry}
              sx={{ minWidth: 150 }}
            />
          </div>
        </div>
      )}
      {header.key === 'price_and_floor_plan' && (
        <div>
          <div className="">
            <TextField
              id={'price'}
              label={'　価格(円)'}
              variant="standard"
              value={inquiry.price != null ? inquiry.price : ''}
              onChange={handleChangeNumber({
                key: 'price',
                inquiry,
                setInquiry,
              })}
              inputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
              }}
            />
          </div>
          <div className="mt-2">
            <SelectForm
              label="間取り"
              keyName="floor_plan"
              inquiry={inquiry}
              setInquiry={setInquiry}
              sx={{ minWidth: 150 }}
            />
          </div>
        </div>
      )}
      {cellTextInputs.includes(header.key) && (
        <TextField
          sx={{ width: 150 }}
          id={header.key}
          label={header.name}
          variant="standard"
          value={inquiry[header.key] != null ? inquiry[header.key] : ''}
          onChange={handleChangeText({
            key: `${header.key}` as InquiryTextHeaderKey,
            inquiry,
            setInquiry,
          })}
        />
      )}
      {dateInputs.includes(header.key) && (
        <div className="flex">
          <div>
            {inquiry[header.key] &&
              datetimeViewJPConverter((inquiry[header.key] as string) ?? '')}
          </div>
          <DateTimePicker
            label={header.name}
            openTo="day"
            value={
              inquiry[header.key] != null
                ? dayjs(inquiry[header.key] as string)
                : null
            }
            onChange={handleChangeDateTime({
              key: `${header.key}` as InquiryDateHeaderKey,
              inquiry,
              setInquiry,
            })}
            renderInput={({ inputRef, inputProps, InputProps }) => (
              <div className="flex items-center">
                <input ref={inputRef} {...inputProps} className="w-0" />
                {InputProps?.endAdornment}
              </div>
            )}
            closeOnSelect={true}
            disableMaskedInput={true}
          />
        </div>
      )}
      {boolInputs.includes(header.key) && (
        <div>
          <BooleanRadioForm
            label={header.name}
            keyName={header.key as InquiryBoolHeaderKey}
            inquiry={inquiry}
            setInquiry={setInquiry}
          />
        </div>
      )}
      {selectInputs.includes(header.key) && (
        <div className="flex">
          <SelectForm
            label={header.name}
            keyName={header.key as InquirySelectHeaderKey}
            inquiry={inquiry}
            setInquiry={setInquiry}
            sx={{ minWidth: 100 }}
          />
        </div>
      )}
      {numberInputs.includes(header.key) && (
        <TextField
          id={header.key}
          label={header.name}
          variant="standard"
          value={inquiry[header.key] != null ? inquiry[header.key] : ''}
          onChange={handleChangeNumber({
            key: `${header.key}` as InquiryNumberHeaderKey,
            inquiry,
            setInquiry,
          })}
          inputProps={{
            inputMode: 'numeric',
            pattern: '[0-9]*',
          }}
        />
      )}
    </>
  )
}
