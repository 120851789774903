import type { Dayjs } from 'dayjs'
import type { SelectChangeEvent } from '@mui/material'
import type {
  AppointmentTextHeaderKey,
  AppointmentNumberHeaderKey,
  AppointmentDateHeaderKey,
  AppointmentBoolHeaderKey,
  AppointmentSelectHeaderKey,
  HandleChangeProps,
} from '../../models/Appointment'
import { datetimeFormat, dateFormat } from '../../fixture/common/formOptions'

export const handleChangeText =
  (props: HandleChangeProps<AppointmentTextHeaderKey>) =>
  (event: React.ChangeEvent<HTMLInputElement>) => {
    const { appointment, key, setAppointment } = props
    setAppointment({ ...appointment, [key]: event.target.value })
  }

export const handleChangeNumber =
  (props: HandleChangeProps<AppointmentNumberHeaderKey>) =>
  (event: React.ChangeEvent<HTMLInputElement>) => {
    const { key, appointment, setAppointment } = props
    const result = Math.abs(Number(event.target.value)).toString()

    if (result === 'NaN') {
      setAppointment({ ...appointment, [key]: 0 })
    } else {
      setAppointment({ ...appointment, [key]: Number(result) })
    }
  }

export const handleChangeDateTime =
  (props: HandleChangeProps<AppointmentDateHeaderKey>) =>
  (newValue: Dayjs | null): void => {
    const { appointment, key, setAppointment } = props
    const dateValue = newValue?.format(datetimeFormat)
    setAppointment({ ...appointment, [key]: dateValue })
  }

export const handleChangeDate =
  (props: HandleChangeProps<AppointmentDateHeaderKey>) =>
  (newValue: Dayjs | null): void => {
    const { appointment, key, setAppointment } = props
    const dateValue = newValue?.format(dateFormat)
    setAppointment({ ...appointment, [key]: dateValue })
  }

export const handleChangeSelect =
  (props: HandleChangeProps<AppointmentSelectHeaderKey>) =>
  (event: SelectChangeEvent) => {
    const { appointment, key, setAppointment } = props
    setAppointment({ ...appointment, [key]: event.target.value })
  }

export const handleChangeBool =
  (props: HandleChangeProps<AppointmentBoolHeaderKey>) =>
  (event: React.ChangeEvent<HTMLInputElement>) => {
    const { appointment, key, setAppointment } = props
    const incomming = event.target.value
    let newAppointment = {}
    if (incomming === '') {
      delete appointment[key]
      newAppointment = { ...appointment }
    } else if (incomming === 'true') {
      newAppointment = { ...appointment, [key]: true }
    } else if (incomming === 'false') {
      newAppointment = { ...appointment, [key]: false }
    }
    setAppointment(newAppointment)
  }
