import type { AppointmentHeader } from '../../models/Appointment'

export const cellTextInputs = [
  'appointment_family_number',
  'monthly_repayment_amount',
  'own_resources',
  'media',
  'negotiation_person',
  'memo',
  'remarks',
  'neck',
  'appointment_result_reason',
  'family_name',
  'name',
  'family_name_kana',
  'name_kana',
  'address_ward',
]

export const textInputs = [
  'appointment_family_number',
  'monthly_repayment_amount',
  'own_resources',
  'media',
  'negotiation_person',
  'memo',
  'remarks',
  'neck',
  'family_name',
  'name',
  'family_name_kana',
  'name_kana',
  'address_ward',
]
export const longTextInputs = ['appointment_result_reason']
export const numberInputs = [
  'inquiry_price',
  'annual_income',
  'appointment_count',
]
export const selectInputs = [
  'appointment_status',
  'rank',
  'floor_plan',
  'preparation',
  'appointment_result',
  'gender',
]
export const dateInputs = ['appointment_date', 'birth_date']

export const boolInputs = ['preparation_check', 'line_resistration']

const defalutStyle = {
  fontWeight: 'bold',
  whiteSpace: 'nowrap',
  fontSize: 13,
  background: '#0099FF',
}

const fontSizeS = 10

// 案内リスト
export const appointmentHeaders: AppointmentHeader[] = [
  { key: 'appointment_date', name: '時間', headSx: defalutStyle },
  { key: 'rank', name: 'ランク', headSx: defalutStyle },
  { key: 'full_name', name: '名前', headSx: defalutStyle },
  { key: 'age_and_gender', name: '年齢\n性別', headSx: defalutStyle },
  {
    key: 'appointment_family_number',
    name: '案内\n家族数',
    headSx: defalutStyle,
  },
  { key: 'address_ward', name: '区', headSx: defalutStyle },
  {
    key: 'inquiry_price_and_floor_plan',
    name: '問い合わせ価格\n間取り',
    headSx: defalutStyle,
  },
  {
    key: 'monthly_repayment_amount',
    name: '毎月\n返済額',
    headSx: defalutStyle,
  },
  {
    key: 'annual_income',
    name: '年収',
    headSx: defalutStyle,
    editLabel: '年収(円)',
  },
  { key: 'own_resources', name: '自己資金', headSx: defalutStyle },
  { key: 'appointment_count', name: '回数', headSx: defalutStyle },
  { key: 'media', name: '媒体', headSx: defalutStyle },
  { key: 'line_resistration', name: 'LINE登録', headSx: defalutStyle },
  { key: 'negotiation_person', name: '交渉\nヘルプ', headSx: defalutStyle },
  {
    key: 'memo',
    name: 'タッチ',
    headSx: { ...defalutStyle, textDecoration: 'underline' },
  },
  { key: 'remarks', name: '待ち合わせ\n場所等', headSx: defalutStyle },
  { key: 'neck', name: 'ネック', headSx: defalutStyle },
  { key: 'preparation', name: '準備', headSx: defalutStyle },
  {
    key: 'preparation_check',
    name: '準備\nチェック',
    headSx: { ...defalutStyle, fontSize: fontSizeS },
  },
]
