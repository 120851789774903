import React from 'react'
import type { Dayjs } from 'dayjs'
import dayjs from '../../../vendors/Dayjs'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
  Link,
} from '@mui/material'
import type { SxProps } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useNavigate } from 'react-router-dom'
import LaunchIcon from '@mui/icons-material/Launch'
import AddBoxIcon from '@mui/icons-material/AddBox'
import DescriptionIcon from '@mui/icons-material/Description'
import ModeEditIcon from '@mui/icons-material/ModeEdit'
import EditOffIcon from '@mui/icons-material/EditOff'
import SendIcon from '@mui/icons-material/Send'
import type { Inquiry } from '../../../models/Inquiry'
import type { ModalType } from '../../../models/Common'
import { appointmentStatusColorSelector } from '../../../utils/common/appointmentStatusColorSelector'
import { inquiriesParser } from '../../../utils/inquiry/inquiryViewParser'
// import { customerInquiryTranslator } from '../../../utils/inquiry/customerInquiryTranslator'
import { graphqlCustomersToInquiriesConverter } from '../../../utils/inquiry/graphqlCustomerToInquiryConverter'
import { inquirySearchFilter } from '../../../utils/inquiry/inquirySearchFilter'
import { inquiryToCustomerTranslator } from '../../../utils/inquiry/InquiryToCustomerTranslator'
import { InquiryModal } from '../../components/inquiry/InquiryModal'
import { SearchInputForm } from '../../components/inquiry/SearchInputForm'
import { TableCellInquiryFields } from '../../components/inquiry/TableCellInquiryFields'
import { IndentText } from '../../components/common/IndentText'
import { datetimeFormat } from '../../../fixture/common/formOptions'
import { inquiryHeaders } from '../../../fixture/inquiry/formOptions'
import { AppointmentStatusIndexView } from '../../components/inquiry/AppointmentStatusIndexView'
// import { mockCustomerCharts } from '../../../mock/Customer'
// import { mockInquiries } from '../../../mock/Inquiry'
import {
  useSearchCustomerQuery,
  useUpdateCustomerMutation,
} from '../../../vendors/graphql/generated'
import type { QuerySearchCustomerArgs } from '../../../vendors/graphql/generated'

const InquiryPage: React.FC = () => {
  const [updateCustomer] = useUpdateCustomerMutation()

  const navigate = useNavigate()

  const initInquiry = {}
  const thisStartOfWeekDay = dayjs().startOf('week')
  const thisEndOfWeekDay = thisStartOfWeekDay.add(6, 'day')

  const [inquiries, setInquiries] = React.useState<Inquiry[]>([])
  const [inputEditInquiry, setInputEditInquiry] =
    React.useState<Inquiry>(initInquiry)
  const [editModes, setEditModes] = React.useState<boolean[]>([])
  const [openModal, setOpenModal] = React.useState(false)
  const [modalInquiry, setModalInquiry] = React.useState<Inquiry>(initInquiry)

  const [searchText, setSearchText] = React.useState<string>('')
  const [startInquiryDate, setStartInquiryDate] = React.useState<Dayjs | null>(
    thisStartOfWeekDay
  )
  const [endInquiryDate, setEndInquiryDate] = React.useState<Dayjs | null>(
    thisEndOfWeekDay
  )
  const [searchReaction, setSearchReaction] = React.useState<string>('')
  const [searchAppointmentStatus, setSearchAppointmentStatus] =
    React.useState<string>('')

  const [modalType, setModalType] = React.useState<ModalType>('create')

  const initSearchCustomerArgs = {
    conditions: {
      start_date: thisStartOfWeekDay?.format(datetimeFormat),
      end_date: endInquiryDate?.format(datetimeFormat),
    },
    options: {},
  }
  const [searchCustomerArgs, setSearchCustomerArgs] =
    React.useState<QuerySearchCustomerArgs>(initSearchCustomerArgs)
  const { data, refetch } = useSearchCustomerQuery({
    variables: searchCustomerArgs,
  })

  const handleRefetch = async (): Promise<void> => {
    await refetch()
  }

  const handleCallApi = async (): Promise<void> => {
    setSearchCustomerArgs({
      conditions: {
        start_date: startInquiryDate?.format(datetimeFormat),
        end_date: endInquiryDate?.format(datetimeFormat),
      },
      options: {},
    })
    await refetch()
    setEditModes(Array(inquiries.length).fill(false))
  }

  const openUpdateModalHandler = (inquiry: Inquiry): void => {
    setModalInquiry(inquiry)
    setModalType('update')
    setOpenModal(true)
  }
  const openCreateModalHandler = (): void => {
    const initInquiry = {}
    setModalInquiry(initInquiry)
    setModalType('create')
    setOpenModal(true)
  }
  const toggleEditHandler = (
    inquiry: Inquiry,
    index: number,
    editModes: boolean[]
  ): void => {
    setInputEditInquiry(inquiry)
    resetEditModes(index, editModes)
  }
  const handleUpdateInquiry = async (
    index: number,
    editModes: boolean[]
  ): Promise<void> => {
    console.log('updateCustomer param: ', inputEditInquiry)
    const inputCustomer = inquiryToCustomerTranslator({
      inquiry: inputEditInquiry,
    })
    const key = {
      id: inputEditInquiry.id ?? '',
      meta: inputEditInquiry.meta ?? '',
    }
    await updateCustomer({
      variables: { input: inputCustomer, key, type: 'inquiry' },
    })
    setInputEditInquiry(initInquiry)
    resetEditModes(index, editModes)
    await refetch()
  }
  const resetEditModes = (index: number, editModes: boolean[]): void => {
    const toggleEditBool = !editModes[index]
    editModes.fill(false)
    editModes[index] = toggleEditBool
    setEditModes([...editModes])
  }
  const handleClose = (): void => {
    setOpenModal(false)
  }

  React.useEffect(() => {
    // const customers = mockCustomerCharts.map((chart) => chart.customer)
    // const inquiries = customerInquiryTranslator({
    //   customers,
    // })
    if (data) {
      const inquiries = graphqlCustomersToInquiriesConverter({
        data,
      })
      const filteredInquiries = inquirySearchFilter({
        inquiries,
        filterOption: {
          searchText,
          searchReaction,
          searchAppointmentStatus,
        },
      })
      setInquiries(filteredInquiries)
      setEditModes(Array(inquiries.length).fill(false))
    }
  }, [data, searchText, searchReaction, searchAppointmentStatus])

  const title = '反響一覧'

  const inquiriesView = React.useMemo(
    () => inquiriesParser({ inquiries }),
    [inquiries]
  )
  return (
    <>
      <InquiryModal
        open={openModal}
        handleClose={handleClose}
        inquiry={modalInquiry}
        type={modalType}
        handleRefetch={handleRefetch}
      />
      <SearchInputForm
        searchText={searchText}
        setSearchText={setSearchText}
        startDate={startInquiryDate}
        setStartDate={setStartInquiryDate}
        endDate={endInquiryDate}
        setEndDate={setEndInquiryDate}
        searchReaction={searchReaction}
        setSearchReaction={setSearchReaction}
        searchAppointmentStatus={searchAppointmentStatus}
        setSearchAppointmentStatus={setSearchAppointmentStatus}
        handleCallApi={handleCallApi}
      />
      <Paper className="md:m-5 md:py-4 m-2 py-2">
        <div className="my-2 md:mx-6 flex justify-between items-center">
          <div className="flex justify-start items-center">
            <Typography className="px-3 py-2" variant="h6">
              {title}
            </Typography>
            <IconButton
              onClick={() => {
                openCreateModalHandler()
              }}
            >
              <AddBoxIcon sx={{}} />
            </IconButton>
          </div>
          <div className="mr-10">
            <AppointmentStatusIndexView />
          </div>
        </div>
        <TableContainer className="max-w-full max-h-full">
          <Table
            sx={{
              marginY: 2,
              marginX: 2,
              // width: 700,
              '@media screen and (min-width:768px)': {
                // width: 1025,
                marginX: 5,
              },
            }}
            // padding="none"
            size="small"
            aria-label="simple table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell sx={{ border: 0 }} align="center"></TableCell>
                {inquiryHeaders.map((header, headerIndex) => {
                  let headerStyle: SxProps = {
                    ...header.headSx,
                    borderTop: 1,
                    borderRight: 1,
                    borderBottom: 1,
                  }
                  if (headerIndex === 0) {
                    headerStyle = {
                      ...headerStyle,
                      borderLeft: 1,
                    }
                  } else {
                    headerStyle = {
                      ...headerStyle,
                      borderLeft: 0,
                    }
                  }
                  return (
                    <TableCell
                      sx={headerStyle}
                      align="center"
                      key={headerIndex}
                    >
                      {IndentText(header.name)}
                    </TableCell>
                  )
                })}
                <TableCell sx={{ border: 0 }} align="center"></TableCell>
                <TableCell sx={{ border: 0 }} align="center"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                {inquiries.map((inquiry, inquiryIndex) => (
                  <TableRow key={inquiryIndex}>
                    <TableCell sx={{ px: 0, border: 0 }} align="center">
                      <div className="flex">
                        <IconButton
                          onClick={() => {
                            toggleEditHandler(inquiry, inquiryIndex, editModes)
                          }}
                        >
                          {editModes[inquiryIndex] ? (
                            <EditOffIcon />
                          ) : (
                            <ModeEditIcon />
                          )}
                        </IconButton>
                        {editModes[inquiryIndex] && (
                          <IconButton
                            onClick={async () => {
                              await handleUpdateInquiry(inquiryIndex, editModes)
                            }}
                          >
                            <SendIcon />
                          </IconButton>
                        )}
                      </div>
                    </TableCell>
                    {inquiryHeaders.map((header, bodyIndex) => {
                      const inquiryView = inquiriesView[inquiryIndex]
                      let bodyStyle: SxProps = {
                        whiteSpace: 'nowrap',
                        borderBottom: 1,
                        borderRight: 1,
                      }
                      if (bodyIndex === 0) {
                        bodyStyle = {
                          ...bodyStyle,
                          borderLeft: 1,
                        }
                      } else {
                        bodyStyle = {
                          ...bodyStyle,
                          borderLeft: 0,
                        }
                      }
                      return (
                        <TableCell
                          sx={bodyStyle}
                          align="center"
                          key={`${inquiryIndex}-${header.key}`}
                          className={appointmentStatusColorSelector(
                            inquiry.appointment_status
                          )}
                          onDoubleClick={() => {
                            !editModes[inquiryIndex] &&
                              toggleEditHandler(
                                inquiry,
                                inquiryIndex,
                                editModes
                              )
                          }}
                        >
                          {editModes[inquiryIndex] ? (
                            <TableCellInquiryFields
                              header={header}
                              inquiry={inputEditInquiry}
                              setInquiry={setInputEditInquiry}
                            />
                          ) : header.key === 'full_name' ? (
                            <Link
                              underline="none"
                              onClick={() => {
                                navigate(
                                  `/customer/${inquiry.id ? inquiry.id : ''}`
                                )
                              }}
                              sx={{ cursor: 'pointer' }}
                            >
                              {IndentText(inquiryView[header.key])}
                            </Link>
                          ) : (
                            <div>{IndentText(inquiryView[header.key])}</div>
                          )}
                        </TableCell>
                      )
                    })}
                    <TableCell sx={{ border: 0 }} align="center">
                      <IconButton
                        onClick={() => {
                          openUpdateModalHandler(inquiry)
                        }}
                      >
                        <LaunchIcon sx={{}} />
                      </IconButton>
                    </TableCell>
                    <TableCell sx={{ border: 0 }} align="center">
                      <IconButton
                        onClick={() => {
                          navigate(`/customer/${inquiry.id ? inquiry.id : ''}`)
                        }}
                      >
                        <DescriptionIcon sx={{}} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </LocalizationProvider>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  )
}

export default InquiryPage
