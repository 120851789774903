import React from 'react'
import { TextField } from '@mui/material'
import {
  DateTimePicker,
  DatePicker,
  MobileDateTimePicker,
} from '@mui/x-date-pickers'
import dayjs from '../../../../vendors/Dayjs'
import type { Inquiry, InquiryDateHeaderKey } from '../../../../models/Inquiry'
import {
  handleChangeDateTime,
  handleChangeDate,
} from '../../../../utils/inquiry/inquiryStateHandler'

interface DateTimePickerIconProps {
  label?: string
  keyName: InquiryDateHeaderKey
  inquiry: Inquiry
  setInquiry: React.Dispatch<React.SetStateAction<Inquiry>>
}

export const DateTimePickerIcon: React.FC<DateTimePickerIconProps> = (
  props: DateTimePickerIconProps
) => {
  const { label, keyName, inquiry, setInquiry } = props

  return (
    <>
      <DateTimePicker
        label={label}
        openTo="day"
        value={inquiry[keyName] != null ? dayjs(inquiry[keyName]) : null}
        onChange={handleChangeDateTime({
          key: keyName,
          inquiry,
          setInquiry,
        })}
        renderInput={({ inputRef, inputProps, InputProps }) => (
          <div className="flex items-center">
            <input ref={inputRef} {...inputProps} className="w-0" />
            {InputProps?.endAdornment}
          </div>
        )}
        closeOnSelect={true}
        disableMaskedInput={true}
      />
    </>
  )
}

export const DatePickerIcon: React.FC<DateTimePickerIconProps> = (
  props: DateTimePickerIconProps
) => {
  const { label, keyName, inquiry, setInquiry } = props

  return (
    <>
      <DatePicker
        label={label}
        openTo="year"
        value={inquiry[keyName] != null ? dayjs(inquiry[keyName]) : null}
        onChange={handleChangeDate({
          key: keyName,
          inquiry,
          setInquiry,
        })}
        renderInput={({ inputRef, inputProps, InputProps }) => (
          <div className="flex items-center">
            <input ref={inputRef} {...inputProps} className="w-0" />
            {InputProps?.endAdornment}
          </div>
        )}
        closeOnSelect={true}
        disableMaskedInput={true}
      />
    </>
  )
}

export const MobileDateTimePickerForm: React.FC<DateTimePickerIconProps> = (
  props: DateTimePickerIconProps
) => {
  const { label, keyName, inquiry, setInquiry } = props

  return (
    <>
      <MobileDateTimePicker
        label={label}
        openTo="day"
        value={inquiry[keyName] != null ? dayjs(inquiry[keyName]) : null}
        onChange={handleChangeDateTime({
          key: keyName,
          inquiry,
          setInquiry,
        })}
        renderInput={(params) => <TextField {...params} />}
        closeOnSelect={true}
        disableMaskedInput={true}
      />
    </>
  )
}
