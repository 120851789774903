// import type { Dayjs } from 'dayjs'
import type { Inquiry, InquirySelectHeaderKey } from '../../models/Inquiry'
// import dayjs from '../../vendors/Dayjs'

interface FilterOption {
  searchText: string
  // startInquiryDate: Dayjs | null
  // endInquiryDate: Dayjs | null
  searchReaction: string
  searchAppointmentStatus: string
}

interface InquirySearchFilter {
  inquiries: Inquiry[]
  filterOption: FilterOption
}

const searchTextSelector = (searchText: string, inquiry: Inquiry): boolean => {
  if (searchText === '') {
    return true
  }

  let names = ''
  names += String(inquiry.family_name)
  names += String(inquiry.name)
  names += String(inquiry.family_name_kana)
  names += String(inquiry.name_kana)
  if (names.includes(searchText)) {
    return true
  }

  if (inquiry.family_name != null && inquiry.name != null) {
    const fullNameWithSpace = inquiry.family_name + ' ' + inquiry.name
    const fullName = inquiry.family_name + inquiry.name
    if (
      fullNameWithSpace.includes(searchText) ||
      fullName.includes(searchText)
    ) {
      return true
    }
  }

  if (inquiry.family_name_kana != null && inquiry.name_kana != null) {
    const fullNameKana = inquiry.family_name_kana + ' ' + inquiry.name_kana
    if (fullNameKana.includes(searchText)) {
      return true
    }
  }

  return false
}

// const inquiryDateFilter = (
//   startInquiryDate: Dayjs | null,
//   endInquiryDate: Dayjs | null,
//   inquiry: Inquiry
// ): boolean => {
//   if (startInquiryDate == null && endInquiryDate == null) {
//     return true
//   }

//   const inquiryDate = inquiry.inquiry_date
//   if (inquiryDate == null) {
//     return false
//   }
//   const inquiryDayjs = dayjs(inquiryDate)

//   if (startInquiryDate == null && endInquiryDate != null) {
//     if (inquiryDayjs < endInquiryDate.add(1, 'day')) {
//       return true
//     }
//   }

//   if (startInquiryDate != null && endInquiryDate == null) {
//     if (startInquiryDate <= inquiryDayjs) {
//       return true
//     }
//   }

//   if (startInquiryDate != null && endInquiryDate != null) {
//     if (
//       startInquiryDate <= inquiryDayjs &&
//       inquiryDayjs < endInquiryDate.add(1, 'day')
//     ) {
//       return true
//     }
//   }

//   return false
// }

// const activeSelector = (isActive: boolean, inquiry: Inquiry): boolean => {
//   if (!isActive || inquiry.active) {
//     return true
//   }
//   return false
// }

const searchSelectFilter = (
  searchSelectedValue: string,
  inquiry: Inquiry,
  key: InquirySelectHeaderKey
): boolean => {
  if (searchSelectedValue === '' || searchSelectedValue === inquiry[key]) {
    return true
  }
  return false
}

export const inquirySearchFilter = (props: InquirySearchFilter): Inquiry[] => {
  const { inquiries, filterOption } = props
  return inquiries.filter(
    (inquiry) =>
      searchTextSelector(filterOption.searchText, inquiry) &&
      // activeSelector(filterOption.isActive, inquiry) &&
      searchSelectFilter(filterOption.searchReaction, inquiry, 'reaction') &&
      searchSelectFilter(
        filterOption.searchAppointmentStatus,
        inquiry,
        'appointment_status'
      )
    // inquiryDateFilter(
    //   filterOption.startInquiryDate,
    //   filterOption.endInquiryDate,
    //   inquiry
    // )
  )
}
