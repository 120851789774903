import {
  TextFieldForm,
  TextFieldParentInfoForm,
} from '../../common/editForms/TextFieldForm'
import {
  NumberInputForm,
  NumberInputParentInfoForm,
} from '../../common/editForms/NumberInputForm'
import {
  SelectForm,
  SelectParentInfoForm,
} from '../../common/editForms/SelectForm'
import { CustomerBooleanRadioForm } from '../../common/editForms/BooleanRadioForm'
import { ChildrenForm, SmChildrenForm } from './CustomerChartChildren'
import {
  FamilyMembersForm,
  SmFamilyMembersForm,
} from './CustomerChartFamilyMember'
import type {
  Customer,
  CustomerView,
  AssetBlockNumberHeaderKey,
} from '../../../../models/Customer'
import { genderColorStringCreater } from '../../../../utils/customer/customerChartModules'

interface CustomerChartAssetBlockProps {
  editCustomer: Customer
  customerView: CustomerView
  setEditCustomer: React.Dispatch<React.SetStateAction<Customer>>
  editMode: boolean
}

interface AssetItemProps {
  editCustomer: Customer
  customerView: CustomerView
  setEditCustomer: React.Dispatch<React.SetStateAction<Customer>>
  editMode: boolean
  label: string
  keyName: AssetBlockNumberHeaderKey
}

export const AssetItem: React.FC<AssetItemProps> = (props: AssetItemProps) => {
  const {
    customerView,
    editCustomer,
    setEditCustomer,
    editMode,
    label,
    keyName,
  } = props
  return (
    <div className="relative h-full flex items-center justify-center">
      <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
        {label}
      </div>

      {editMode ? (
        <div className="py-6 px-2 text-base h-full flex items-center justify-center">
          <NumberInputForm
            keyName={keyName}
            customer={editCustomer}
            setCustomer={setEditCustomer}
            classString="w-20"
          />
          <div className="font-semibold text-sm ml-1">円</div>
        </div>
      ) : (
        <div className="py-4 px-8 text-base">{customerView[keyName]}</div>
      )}

      {!editMode && (
        <div className="absolute flex bottom-0 right-0 py-1 px-2 font-semibold text-xs">
          万円
        </div>
      )}
    </div>
  )
}

export const CustomerChartAssetBlock: React.FC<CustomerChartAssetBlockProps> = (
  props: CustomerChartAssetBlockProps
) => {
  const { customerView, editCustomer, setEditCustomer, editMode } = props
  const cutomerGenderColor = genderColorStringCreater(
    editCustomer.customer_info?.gender
  )
  const consortGenderColor = genderColorStringCreater(
    editCustomer.consort_info?.gender
  )
  return (
    <>
      <div className="grid grid-cols-12 rounded-none">
        <div
          className={`relative col-start-1 col-span-2 flex items-center justify-center border border-current ${cutomerGenderColor}`}
        >
          <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
            年収(本人)
          </div>
          <div className="py-4 px-4 text-base">
            {editMode ? (
              <div className="flex items-center justify-center">
                <NumberInputParentInfoForm
                  keyName1="customer_info"
                  keyName2="annual_income"
                  customer={editCustomer}
                  setCustomer={setEditCustomer}
                  classString="w-20"
                />
                <div className="font-semibold text-sm ml-1">円</div>
              </div>
            ) : (
              customerView.customer_info?.annual_income
            )}
          </div>
          {!editMode && (
            <div className="absolute flex bottom-1 right-1 py-1 px-2 font-semibold text-xs">
              万円
            </div>
          )}
        </div>
        <div
          className={`relative col-span-1 flex items-center justify-center border-y border-r border-current ${cutomerGenderColor}`}
        >
          <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
            勤務年数
          </div>
          <div className="py-4 px-4 text-base">
            {editMode ? (
              <div className="flex items-center justify-center">
                <NumberInputParentInfoForm
                  keyName1="customer_info"
                  keyName2="length_of_service"
                  customer={editCustomer}
                  setCustomer={setEditCustomer}
                  classString="w-10"
                />
              </div>
            ) : (
              customerView.customer_info?.length_of_service
            )}
          </div>
          <div className="absolute flex bottom-1 right-1 py-1 px-2 font-semibold text-xs">
            年
          </div>
        </div>
        <div
          className={`relative col-span-1 flex items-center justify-center border-y border-r border-current ${cutomerGenderColor}`}
        >
          <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
            勤務形態
          </div>
          <div className="py-4 px-4 text-base">
            {editMode ? (
              <div className="flex items-center justify-center">
                <SelectParentInfoForm
                  keyName1="customer_info"
                  keyName2="work_style"
                  customer={editCustomer}
                  setCustomer={setEditCustomer}
                />
              </div>
            ) : (
              customerView.customer_info?.work_style
            )}
          </div>
        </div>
        <div
          className={`col-span-3 border-r border-y border-current ${cutomerGenderColor}`}
        >
          <div className="relative py-1 px-4 border-b border-current">
            <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
              勤務先
            </div>
            <div className="h-8 flex items-center justify-center">
              {editMode ? (
                <TextFieldParentInfoForm
                  keyName1="customer_info"
                  keyName2="workplace"
                  customer={editCustomer}
                  setCustomer={setEditCustomer}
                  sx={{ px: 6 }}
                />
              ) : (
                customerView.customer_info?.workplace
              )}
            </div>
          </div>
          <div className="relative py-1 px-4">
            <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
              業種
            </div>
            <div className="h-8 flex items-center justify-center">
              {editMode ? (
                <TextFieldParentInfoForm
                  keyName1="customer_info"
                  keyName2="industry_type"
                  customer={editCustomer}
                  setCustomer={setEditCustomer}
                  sx={{ px: 6 }}
                />
              ) : (
                customerView.customer_info?.industry_type
              )}
            </div>
          </div>
        </div>
        <div
          className={`relative col-span-2 flex items-center justify-center border-y border-r border-current ${cutomerGenderColor}`}
        >
          <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
            最寄り駅
          </div>
          <div className="py-4 px-4 text-base">
            {editMode ? (
              <div className="flex items-center justify-center">
                <TextFieldParentInfoForm
                  keyName1="customer_info"
                  keyName2="nearest_station"
                  customer={editCustomer}
                  setCustomer={setEditCustomer}
                  sx={{ px: 4 }}
                />
              </div>
            ) : (
              customerView.customer_info?.nearest_station
            )}
          </div>
        </div>
        <div
          className={`relative col-span-2 flex items-center justify-center border-y border-r border-current ${cutomerGenderColor}`}
        >
          <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
            出身
          </div>
          <div className="py-4 px-4 text-base">
            {editMode ? (
              <div className="flex items-center justify-center">
                <TextFieldParentInfoForm
                  keyName1="customer_info"
                  keyName2="birthplace"
                  customer={editCustomer}
                  setCustomer={setEditCustomer}
                  sx={{ px: 4 }}
                />
              </div>
            ) : (
              customerView.customer_info?.birthplace
            )}
          </div>
        </div>
        <div
          className={`relative col-start-1 col-span-2 flex items-center justify-center border-l border-r border-b border-current ${consortGenderColor}`}
        >
          <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
            年収(配偶者)
          </div>
          <div className="py-4 px-4 text-base">
            {editMode ? (
              <div className="flex items-center justify-center">
                <NumberInputParentInfoForm
                  keyName1="consort_info"
                  keyName2="annual_income"
                  customer={editCustomer}
                  setCustomer={setEditCustomer}
                  classString="w-20"
                />
                <div className="font-semibold text-sm ml-1">円</div>
              </div>
            ) : (
              customerView.consort_info?.annual_income
            )}
          </div>
          {!editMode && (
            <div className="absolute flex bottom-1 right-1 py-1 px-2 font-semibold text-xs">
              万円
            </div>
          )}
        </div>
        <div
          className={`relative col-span-1 flex items-center justify-center border-r border-b border-current ${consortGenderColor}`}
        >
          <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
            勤務年数
          </div>
          <div className="py-4 px-4 text-base">
            {editMode ? (
              <div className="flex items-center justify-center">
                <NumberInputParentInfoForm
                  keyName1="consort_info"
                  keyName2="length_of_service"
                  customer={editCustomer}
                  setCustomer={setEditCustomer}
                  classString="w-10"
                />
              </div>
            ) : (
              customerView.consort_info?.length_of_service
            )}
          </div>
          <div className="absolute flex bottom-1 right-1 py-1 px-2 font-semibold text-xs">
            年
          </div>
        </div>
        <div
          className={`relative col-span-1 flex items-center justify-center border-r border-b border-current ${consortGenderColor}`}
        >
          <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
            勤務形態
          </div>
          <div className="py-4 px-4 text-base">
            {editMode ? (
              <div className="flex items-center justify-center">
                <SelectParentInfoForm
                  keyName1="consort_info"
                  keyName2="work_style"
                  customer={editCustomer}
                  setCustomer={setEditCustomer}
                />
              </div>
            ) : (
              customerView.consort_info?.work_style
            )}
          </div>
        </div>
        <div
          className={`relative col-span-3 border-r border-b border-current ${consortGenderColor}`}
        >
          <div className="relative py-1 px-4 border-b border-current">
            <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
              勤務先
            </div>
            <div className="h-8 flex items-center justify-center">
              {editMode ? (
                <TextFieldParentInfoForm
                  keyName1="consort_info"
                  keyName2="workplace"
                  customer={editCustomer}
                  setCustomer={setEditCustomer}
                  sx={{ px: 6 }}
                />
              ) : (
                customerView.consort_info?.workplace
              )}
            </div>
          </div>
          <div className="relative py-1 px-4">
            <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
              業種
            </div>
            <div className="h-8 flex items-center justify-center">
              {editMode ? (
                <TextFieldParentInfoForm
                  keyName1="consort_info"
                  keyName2="industry_type"
                  customer={editCustomer}
                  setCustomer={setEditCustomer}
                  sx={{ px: 6 }}
                />
              ) : (
                customerView.consort_info?.industry_type
              )}
            </div>
          </div>
        </div>
        <div
          className={`relative col-span-2 flex items-center justify-center border-r border-b border-current ${consortGenderColor}`}
        >
          <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
            最寄り駅
          </div>
          <div className="py-4 px-4 text-base">
            {editMode ? (
              <div className="flex items-center justify-center">
                <TextFieldParentInfoForm
                  keyName1="consort_info"
                  keyName2="nearest_station"
                  customer={editCustomer}
                  setCustomer={setEditCustomer}
                  sx={{ px: 4 }}
                />
              </div>
            ) : (
              customerView.consort_info?.nearest_station
            )}
          </div>
        </div>
        <div
          className={`relative col-span-2 flex items-center justify-center border-r border-b border-current ${consortGenderColor}`}
        >
          <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
            出身
          </div>
          <div className="py-4 px-4 text-base">
            {editMode ? (
              <div className="flex items-center justify-center">
                <TextFieldParentInfoForm
                  keyName1="consort_info"
                  keyName2="birthplace"
                  customer={editCustomer}
                  setCustomer={setEditCustomer}
                  sx={{ px: 4 }}
                />
              </div>
            ) : (
              customerView.consort_info?.birthplace
            )}
          </div>
        </div>
        <ChildrenForm
          childrenView={customerView.children_info}
          customerChart={editCustomer}
          setCustomer={setEditCustomer}
          editMode={editMode}
        />
        <FamilyMembersForm
          familyMemberView={customerView.family_member_info}
          customerChart={editCustomer}
          setCustomer={setEditCustomer}
          editMode={editMode}
        />
      </div>
      <div className="grid grid-cols-12 rounded-none mt-6">
        <div className="col-start-1 col-span-2 relative flex items-center justify-center border-x border-y border-current bg-emerald-100">
          <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
            自己資金
          </div>
          <div className="py-4 px-4 text-base">
            {editMode ? (
              <div className="flex items-center justify-center">
                <NumberInputForm
                  keyName="total_customer_assets"
                  customer={editCustomer}
                  setCustomer={setEditCustomer}
                  classString="w-20"
                />
                <div className="font-semibold text-sm ml-1">円</div>
              </div>
            ) : (
              customerView.total_customer_assets
            )}
          </div>
          {!editMode && (
            <div className="absolute flex bottom-0 right-0 py-1 px-2 font-semibold text-xs">
              万円
            </div>
          )}
        </div>
        <div className="col-span-6 flex items-center justify-center border-r border-y border-current bg-emerald-100">
          <AssetItem
            editCustomer={editCustomer}
            customerView={customerView}
            setEditCustomer={setEditCustomer}
            editMode={editMode}
            label="本人"
            keyName="customer_asset"
          />
          <AssetItem
            editCustomer={editCustomer}
            customerView={customerView}
            setEditCustomer={setEditCustomer}
            editMode={editMode}
            label="親"
            keyName="parent_asset"
          />
          <AssetItem
            editCustomer={editCustomer}
            customerView={customerView}
            setEditCustomer={setEditCustomer}
            editMode={editMode}
            label="有価証券"
            keyName="stocks_and_bonds"
          />
          <AssetItem
            editCustomer={editCustomer}
            customerView={customerView}
            setEditCustomer={setEditCustomer}
            editMode={editMode}
            label="その他"
            keyName="other_asset"
          />
        </div>
        <div
          className={
            'relative col-span-1 flex items-center justify-center border-y border-r border-current bg-emerald-100'
          }
        >
          <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
            保険
          </div>
          <div className="py-6 px-4 text-base">
            {editMode ? (
              <div className="flex items-center justify-center">
                <SelectForm
                  keyName="insurance"
                  customer={editCustomer}
                  setCustomer={setEditCustomer}
                  classString="pb-3"
                />
              </div>
            ) : (
              customerView.insurance
            )}
          </div>
        </div>
        <div
          className={
            'relative col-span-3 flex items-center justify-center border-y border-r border-current bg-emerald-100'
          }
        >
          <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
            病気
          </div>
          <div className="py-6 px-4 text-base">
            {editMode ? (
              <div className="flex items-center justify-center">
                <CustomerBooleanRadioForm
                  keyName="have_disease"
                  customer={editCustomer}
                  setCustomer={setEditCustomer}
                />
              </div>
            ) : (
              customerView.have_disease
            )}
          </div>
        </div>
        <div className="col-start-1 col-span-2 relative flex items-center justify-center border-x border-b border-current bg-emerald-100">
          <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
            借入れ
          </div>
          <div className="py-4 px-4 text-base">
            {editMode ? (
              <div className="flex items-center justify-center">
                <NumberInputForm
                  keyName="debt"
                  customer={editCustomer}
                  setCustomer={setEditCustomer}
                  classString="w-20"
                />
                <div className="font-semibold text-sm ml-1">円</div>
              </div>
            ) : (
              customerView.debt
            )}
          </div>
          {!editMode && (
            <div className="absolute flex bottom-0 right-0 py-1 px-2 font-semibold text-xs">
              万円
            </div>
          )}
        </div>
        <div className="col-span-10 relative flex items-center justify-center border-r border-b border-current bg-emerald-100">
          <div className="absolute top-0 left-0 py-1 px-2 font-semibold text-xs">
            内訳
          </div>
          <div className="py-4 px-8 text-base w-full">
            {editMode ? (
              <TextFieldForm
                keyName="details"
                customer={editCustomer}
                setCustomer={setEditCustomer}
              />
            ) : (
              customerView.details
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export const SmAssetItem: React.FC<AssetItemProps> = (
  props: AssetItemProps
) => {
  const {
    customerView,
    editCustomer,
    setEditCustomer,
    editMode,
    label,
    keyName,
  } = props
  return (
    <div className="relative h-full flex items-center justify-center">
      <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
        {label}
      </div>

      {editMode ? (
        <div className="py-6 px-2 text-base h-full flex items-center justify-center">
          <NumberInputForm
            keyName={keyName}
            customer={editCustomer}
            setCustomer={setEditCustomer}
            classString="w-20"
          />
          <div className="font-semibold text-sm ml-1">円</div>
        </div>
      ) : (
        <div className="py-4 px-8 text-base h-10">{customerView[keyName]}</div>
      )}

      {!editMode && (
        <div className="absolute flex bottom-0 right-0 px-1 font-semibold text-xs">
          万円
        </div>
      )}
    </div>
  )
}

export const SmCustomerChartAssetBlock: React.FC<
  CustomerChartAssetBlockProps
> = (props: CustomerChartAssetBlockProps) => {
  const { customerView, editCustomer, setEditCustomer, editMode } = props
  const cutomerGenderColor = genderColorStringCreater(
    editCustomer.customer_info?.gender
  )
  const consortGenderColor = genderColorStringCreater(
    editCustomer.consort_info?.gender
  )
  return (
    <>
      <div className="grid grid-cols-4 rounded-none">
        <div
          className={`relative col-start-1 col-span-2 flex items-center justify-center border border-current ${cutomerGenderColor}`}
        >
          <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
            年収(本人)
          </div>
          <div className="flex items-center h-12 py-1 px-4 text-base">
            {editMode ? (
              <div className="flex items-center justify-center">
                <NumberInputParentInfoForm
                  keyName1="customer_info"
                  keyName2="annual_income"
                  customer={editCustomer}
                  setCustomer={setEditCustomer}
                  classString="w-20"
                />
                <div className="font-semibold text-sm ml-1">円</div>
              </div>
            ) : (
              customerView.customer_info?.annual_income
            )}
          </div>
          {!editMode && (
            <div className="absolute flex bottom-0 right-0 px-1 font-semibold text-xs">
              万円
            </div>
          )}
        </div>
        <div
          className={`relative col-span-2 flex items-center justify-center border-y border-r border-current ${cutomerGenderColor}`}
        >
          <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
            勤務年数
          </div>
          <div className="flex items-center h-12 py-1 px-4 text-base">
            {editMode ? (
              <div className="flex items-center justify-center">
                <NumberInputParentInfoForm
                  keyName1="customer_info"
                  keyName2="length_of_service"
                  customer={editCustomer}
                  setCustomer={setEditCustomer}
                  classString="w-10"
                />
              </div>
            ) : (
              customerView.customer_info?.length_of_service
            )}
          </div>
          <div className="absolute flex bottom-1 right-1 px-1 font-semibold text-xs">
            年
          </div>
        </div>
        <div
          className={`relative col-span-2 flex items-center justify-center border-b border-x border-current ${cutomerGenderColor}`}
        >
          <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
            勤務形態
          </div>
          <div className="py-4 px-4 text-base">
            {editMode ? (
              <div className="flex items-center justify-center">
                <SelectParentInfoForm
                  keyName1="customer_info"
                  keyName2="work_style"
                  customer={editCustomer}
                  setCustomer={setEditCustomer}
                />
              </div>
            ) : (
              customerView.customer_info?.work_style
            )}
          </div>
        </div>
        <div
          className={`col-span-2 border-r border-b border-current ${cutomerGenderColor}`}
        >
          <div className="relative py-1 px-4 border-b border-current">
            <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
              勤務先
            </div>
            <div className="h-8 flex items-center justify-center">
              {editMode ? (
                <TextFieldParentInfoForm
                  keyName1="customer_info"
                  keyName2="workplace"
                  customer={editCustomer}
                  setCustomer={setEditCustomer}
                  sx={{ px: 6 }}
                />
              ) : (
                customerView.customer_info?.workplace
              )}
            </div>
          </div>
          <div className="relative py-1 px-4">
            <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
              業種
            </div>
            <div className="h-8 flex items-center justify-center">
              {editMode ? (
                <TextFieldParentInfoForm
                  keyName1="customer_info"
                  keyName2="industry_type"
                  customer={editCustomer}
                  setCustomer={setEditCustomer}
                  sx={{ px: 6 }}
                />
              ) : (
                customerView.customer_info?.industry_type
              )}
            </div>
          </div>
        </div>
        <div
          className={`relative col-span-2 flex items-center justify-center border-b border-x border-current ${cutomerGenderColor}`}
        >
          <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
            最寄り駅
          </div>
          <div className="flex items-center h-12 py-1 px-4 text-base">
            {editMode ? (
              <div className="flex items-center justify-center">
                <TextFieldParentInfoForm
                  keyName1="customer_info"
                  keyName2="nearest_station"
                  customer={editCustomer}
                  setCustomer={setEditCustomer}
                  sx={{ px: 4 }}
                />
              </div>
            ) : (
              customerView.customer_info?.nearest_station
            )}
          </div>
        </div>
        <div
          className={`relative col-span-2 flex items-center justify-center border-b border-r border-current ${cutomerGenderColor}`}
        >
          <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
            出身
          </div>
          <div className="flex items-center h-12 py-1 px-4 text-base">
            {editMode ? (
              <div className="flex items-center justify-center">
                <TextFieldParentInfoForm
                  keyName1="customer_info"
                  keyName2="birthplace"
                  customer={editCustomer}
                  setCustomer={setEditCustomer}
                  sx={{ px: 4 }}
                />
              </div>
            ) : (
              customerView.customer_info?.birthplace
            )}
          </div>
        </div>
        <div
          className={`relative col-start-1 col-span-2 flex items-center justify-center border-x border-b border-current ${consortGenderColor}`}
        >
          <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
            年収(配偶者)
          </div>
          <div className="flex items-center h-12 py-1 px-4 text-base">
            {editMode ? (
              <div className="flex items-center justify-center">
                <NumberInputParentInfoForm
                  keyName1="consort_info"
                  keyName2="annual_income"
                  customer={editCustomer}
                  setCustomer={setEditCustomer}
                  classString="w-20"
                />
                <div className="font-semibold text-sm ml-1">円</div>
              </div>
            ) : (
              customerView.consort_info?.annual_income
            )}
          </div>
          {!editMode && (
            <div className="absolute flex bottom-0 right-0 px-1 font-semibold text-xs">
              万円
            </div>
          )}
        </div>
        <div
          className={`relative col-span-2 flex items-center justify-center border-r border-b border-current ${consortGenderColor}`}
        >
          <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
            勤務年数
          </div>
          <div className="flex items-center h-12 py-1 px-4 text-base">
            {editMode ? (
              <div className="flex items-center justify-center">
                <NumberInputParentInfoForm
                  keyName1="consort_info"
                  keyName2="length_of_service"
                  customer={editCustomer}
                  setCustomer={setEditCustomer}
                  classString="w-10"
                />
              </div>
            ) : (
              customerView.consort_info?.length_of_service
            )}
          </div>
          <div className="absolute flex bottom-0 right-0 px-1 font-semibold text-xs">
            年
          </div>
        </div>
        <div
          className={`relative col-span-2 flex items-center justify-center border-x border-b border-current ${consortGenderColor}`}
        >
          <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
            勤務形態
          </div>
          <div className="py-4 px-4 text-base">
            {editMode ? (
              <div className="flex items-center justify-center">
                <SelectParentInfoForm
                  keyName1="consort_info"
                  keyName2="work_style"
                  customer={editCustomer}
                  setCustomer={setEditCustomer}
                />
              </div>
            ) : (
              customerView.consort_info?.work_style
            )}
          </div>
        </div>
        <div
          className={`relative col-span-2 border-r border-b border-current ${consortGenderColor}`}
        >
          <div className="relative py-1 px-4 border-b border-current">
            <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
              勤務先
            </div>
            <div className="h-8 flex items-center justify-center">
              {editMode ? (
                <TextFieldParentInfoForm
                  keyName1="consort_info"
                  keyName2="workplace"
                  customer={editCustomer}
                  setCustomer={setEditCustomer}
                  sx={{ px: 6 }}
                />
              ) : (
                customerView.consort_info?.workplace
              )}
            </div>
          </div>
          <div className="relative py-1 px-4">
            <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
              業種
            </div>
            <div className="h-8 flex items-center justify-center">
              {editMode ? (
                <TextFieldParentInfoForm
                  keyName1="consort_info"
                  keyName2="industry_type"
                  customer={editCustomer}
                  setCustomer={setEditCustomer}
                  sx={{ px: 6 }}
                />
              ) : (
                customerView.consort_info?.industry_type
              )}
            </div>
          </div>
        </div>
        <div
          className={`relative col-span-2 flex items-center justify-center border-x border-b border-current ${consortGenderColor}`}
        >
          <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
            最寄り駅
          </div>
          <div className="flex items-center h-12 py-1 px-4 text-base">
            {editMode ? (
              <div className="flex items-center justify-center">
                <TextFieldParentInfoForm
                  keyName1="consort_info"
                  keyName2="nearest_station"
                  customer={editCustomer}
                  setCustomer={setEditCustomer}
                  sx={{ px: 4 }}
                />
              </div>
            ) : (
              customerView.consort_info?.nearest_station
            )}
          </div>
        </div>
        <div
          className={`relative col-span-2 flex items-center justify-center border-r border-b border-current ${consortGenderColor}`}
        >
          <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
            出身
          </div>
          <div className="flex items-center h-12 py-1 px-4 text-base">
            {editMode ? (
              <div className="flex items-center justify-center">
                <TextFieldParentInfoForm
                  keyName1="consort_info"
                  keyName2="birthplace"
                  customer={editCustomer}
                  setCustomer={setEditCustomer}
                  sx={{ px: 4 }}
                />
              </div>
            ) : (
              customerView.consort_info?.birthplace
            )}
          </div>
        </div>
        <SmChildrenForm
          childrenView={customerView.children_info}
          customerChart={editCustomer}
          setCustomer={setEditCustomer}
          editMode={editMode}
        />
        <SmFamilyMembersForm
          familyMemberView={customerView.family_member_info}
          customerChart={editCustomer}
          setCustomer={setEditCustomer}
          editMode={editMode}
        />
      </div>
      <div className="grid grid-cols-4 rounded-none mt-6">
        <div className="col-start-1 col-span-2 relative flex items-center justify-center border-x border-t border-current bg-emerald-100">
          <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
            自己資金
          </div>
          <div className="py-4 px-4 text-base">
            {editMode ? (
              <div className="flex items-center justify-center">
                <NumberInputForm
                  keyName="total_customer_assets"
                  customer={editCustomer}
                  setCustomer={setEditCustomer}
                  classString="w-20"
                />
                <div className="font-semibold text-sm ml-1">円</div>
              </div>
            ) : (
              <div className="h-4">{customerView.total_customer_assets}</div>
            )}
          </div>
          {!editMode && (
            <div className="absolute flex bottom-0 right-0 px-1 font-semibold text-xs">
              万円
            </div>
          )}
        </div>
        <div className="col-start-1 col-span-4 flex items-center justify-center border-x border-y border-current bg-emerald-100">
          <SmAssetItem
            editCustomer={editCustomer}
            customerView={customerView}
            setEditCustomer={setEditCustomer}
            editMode={editMode}
            label="本人"
            keyName="customer_asset"
          />
          <SmAssetItem
            editCustomer={editCustomer}
            customerView={customerView}
            setEditCustomer={setEditCustomer}
            editMode={editMode}
            label="親"
            keyName="parent_asset"
          />
        </div>
        <div className="col-start-1 col-span-4 flex items-center justify-center border-x border-b border-current bg-emerald-100">
          <SmAssetItem
            editCustomer={editCustomer}
            customerView={customerView}
            setEditCustomer={setEditCustomer}
            editMode={editMode}
            label="有価証券"
            keyName="stocks_and_bonds"
          />
          <SmAssetItem
            editCustomer={editCustomer}
            customerView={customerView}
            setEditCustomer={setEditCustomer}
            editMode={editMode}
            label="その他"
            keyName="other_asset"
          />
        </div>
        <div
          className={
            'relative col-span-1 flex items-center justify-center border-b border-x border-current bg-emerald-100'
          }
        >
          <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
            保険
          </div>
          <div className="py-2 px-2 text-base">
            {editMode ? (
              <div className="flex items-center justify-center">
                <SelectForm
                  keyName="insurance"
                  customer={editCustomer}
                  setCustomer={setEditCustomer}
                  classString="pb-3"
                />
              </div>
            ) : (
              customerView.insurance
            )}
          </div>
        </div>
        <div
          className={
            'relative col-span-3 flex items-center justify-center border-b border-r border-current bg-emerald-100'
          }
        >
          <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
            病気
          </div>
          <div className="py-2 px-2 text-base">
            {editMode ? (
              <div className="flex items-center justify-center">
                <CustomerBooleanRadioForm
                  keyName="have_disease"
                  customer={editCustomer}
                  setCustomer={setEditCustomer}
                />
              </div>
            ) : (
              customerView.have_disease
            )}
          </div>
        </div>
        <div className="col-start-1 col-span-2 relative flex items-center justify-center border-x border-current bg-emerald-100">
          <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
            借入れ
          </div>
          <div className="py-4 px-4 text-base">
            {editMode ? (
              <div className="flex items-center justify-center">
                <NumberInputForm
                  keyName="debt"
                  customer={editCustomer}
                  setCustomer={setEditCustomer}
                  classString="w-20"
                />
                <div className="font-semibold text-sm ml-1">円</div>
              </div>
            ) : (
              <div className="h-4">{customerView.debt}</div>
            )}
          </div>
          {!editMode && (
            <div className="absolute flex bottom-0 right-0 px-1 font-semibold text-xs">
              万円
            </div>
          )}
        </div>
        <div className="col-span-4 relative flex items-center justify-center border border-current bg-emerald-100">
          <div className="absolute top-0 left-0 px-1 font-semibold text-xs">
            内訳
          </div>
          <div className="py-4 px-8 text-base w-full">
            {editMode ? (
              <TextFieldForm
                keyName="details"
                customer={editCustomer}
                setCustomer={setEditCustomer}
              />
            ) : (
              <div className="h-4">{customerView.details}</div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
