import React from 'react'
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from '@mui/material'
import type { SxProps } from '@mui/material'
import ModeEditIcon from '@mui/icons-material/ModeEdit'
import AddBoxIcon from '@mui/icons-material/AddBox'
import { CustomerHistoryModal } from '../../components/customer/CustomerHistoryModal'
import type { CustomerHistoryModalType } from '../../components/customer/CustomerHistoryModal'
import type { Customer } from '../../../models/Customer'
import type {
  CustomerHistory,
  CustomerHistoryHeader,
} from '../../../models/CustomerHistory'
import { customerHistoriesViewParser } from '../../../utils/customer/customerHistoryViewParser'
import { useQueryCustomerHistoryQuery } from '../../../vendors/graphql/generated'
import { customerHistoryGraphqlConverter } from '../../../utils/customer/customerHistoryGraphqlConverter'

interface CustomerChartHistoryTableProps {
  title: string
  customer: Customer
}

export const CustomerChartHistoryTable: React.FC<
  CustomerChartHistoryTableProps
> = (props: CustomerChartHistoryTableProps) => {
  const { title, customer } = props
  const customerId = customer.id ?? ''

  const { data, refetch } = useQueryCustomerHistoryQuery({
    variables: {
      key: {
        customer_id: customerId,
        meta: 'CustomerHistory',
      },
    },
  })

  const handleRefetch = async (): Promise<void> => {
    await refetch()
  }

  const [customerHistories, setCustomerHistories] = React.useState(
    [] as CustomerHistory[]
  )

  const headers: CustomerHistoryHeader[] = [
    { key: 'memo', name: '内容' },
    { key: 'history_date', name: '対応日' },
  ]
  let headerStyle: SxProps = {
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    borderTop: 1,
    borderRight: 1,
    borderBottom: 1,
  }
  let bodyStyle: SxProps = {
    whiteSpace: 'nowrap',
    borderRight: 1,
    borderBottom: 1,
  }
  const initHistory = { memo: '', history_date: '' }

  const [inputCustomerHistory, setInputCustomerHistory] =
    React.useState<CustomerHistory>(initHistory)
  const [openHistoryModal, setOpenHistoryModal] = React.useState(false)
  const [historyModalType, setHistoryModalType] =
    React.useState<CustomerHistoryModalType>('create')

  const openHistoryModalHandler = (
    customerHistory: CustomerHistory,
    type: CustomerHistoryModalType
  ): void => {
    setInputCustomerHistory(customerHistory)
    setOpenHistoryModal(true)
    setHistoryModalType(type)
  }
  const handleHistoryModalClose = (): void => {
    setOpenHistoryModal(false)
  }

  const customerHistoriesView = customerHistoriesViewParser({
    histories: customerHistories,
  })

  React.useEffect(() => {
    if (data) {
      const customerHistories = customerHistoryGraphqlConverter({
        data,
      })
      setCustomerHistories(customerHistories)
    }
  }, [data, setCustomerHistories])

  return (
    <div>
      <CustomerHistoryModal
        title={title}
        open={openHistoryModal}
        handleClose={handleHistoryModalClose}
        history={inputCustomerHistory}
        type={historyModalType}
        handleRefetch={handleRefetch}
        customerId={customerId}
      />
      <div className="flex justify-start">
        <Typography className="pt-4" variant="subtitle1">
          {title}
        </Typography>
        <div className="pt-2 pl-2">
          <IconButton
            onClick={() => {
              openHistoryModalHandler(initHistory, 'create')
            }}
          >
            <AddBoxIcon sx={{}} />
          </IconButton>
        </div>
      </div>

      <TableContainer className="my-2 max-h-full max-w-full">
        <Table size="small" aria-label="simple table" stickyHeader>
          <TableHead>
            <TableRow>
              {headers.map((header, headerIndex) => {
                if (headerIndex === 0) {
                  headerStyle = { ...headerStyle, borderLeft: 1 }
                } else {
                  headerStyle = { ...headerStyle, borderLeft: 0 }
                }
                return (
                  <TableCell
                    sx={headerStyle}
                    align="center"
                    key={`header-${header.key}`}
                  >
                    {header.name}
                  </TableCell>
                )
              })}
              <TableCell sx={{ px: 0, border: 0 }} align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customerHistories.map((customerHistory, chIndex) => (
              <TableRow key={chIndex}>
                {headers.map((header, headerIndex) => {
                  const customerHistoryView = customerHistoriesView[chIndex]
                  if (headerIndex === 0) {
                    bodyStyle = { ...bodyStyle, borderLeft: 1 }
                  } else {
                    bodyStyle = { ...bodyStyle, borderLeft: 0 }
                  }
                  return (
                    <TableCell
                      sx={bodyStyle}
                      align="center"
                      key={`${chIndex}-${header.key}`}
                    >
                      {customerHistoryView[header.key]}
                    </TableCell>
                  )
                })}
                <TableCell sx={{ px: 0, border: 0 }} align="center">
                  <IconButton
                    onClick={() => {
                      openHistoryModalHandler(customerHistory, 'update')
                    }}
                  >
                    <ModeEditIcon sx={{}} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
