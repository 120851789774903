import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import Router from './router'
import { Box } from '@mui/material'
import { Layout } from './views/components/Menu'

const App: React.FC = () => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0()

  React.useEffect(() => {
    if (isLoading || isAuthenticated) {
      return
    }
    ;(async (): Promise<void> => {
      await loginWithRedirect()
    })().catch((err) => {
      console.log(err)
    })
  }, [isLoading, isAuthenticated])
  return (
    <>
      {isAuthenticated ? (
        <>
          <AuthenticatedPage />
        </>
      ) : (
        <>
          <UnAuthenticatedPage />
        </>
      )}
    </>
  )
}

const UnAuthenticatedPage: React.FC = () => {
  // const { loginWithRedirect } = useAuth0()
  return (
    <>
      <Box className="m-4">
        {/* <Typography className="pb-2 " variant="h5">
          Tokyo Mansions Management
        </Typography>
        <Button variant="contained" onClick={loginWithRedirect}>
          Log in
        </Button> */}
      </Box>
    </>
  )
}

const AuthenticatedPage: React.FC = () => {
  return (
    <>
      <Layout>
        <Router />
      </Layout>
    </>
  )
}

export default App
