import { CompromisePointCheckBoxForm } from '../../common/editForms/CompromisePointCheckBoxForm'
import type { Customer, CustomerView } from '../../../../models/Customer'
import type { CompromisePoint } from '../../../../models/SelectCandidate'
import { compromisePointDict } from '../../../../fixture/common/formOptions'

interface CustomerChartGraphBlockProps {
  editCustomer: Customer
  customerView: CustomerView
  setEditCustomer: React.Dispatch<React.SetStateAction<Customer>>
  editMode: boolean
}

interface CompromisePointViewProps {
  compromisePoints: CompromisePoint[]
}

export const CustomerChartGraphBlock: React.FC<CustomerChartGraphBlockProps> = (
  props: CustomerChartGraphBlockProps
) => {
  const { customerView, editCustomer, setEditCustomer, editMode } = props
  const blockColor = ''
  return (
    <>
      <div className="grid grid-cols-12 rounded-none">
        <div className="col-start-1 col-span-6 border border-current p-1 font-semibold bg-cyan-100">
          安さ引き換えに妥協できる点
        </div>
        <div
          className={`col-start-1 col-span-6 relative flex items-center justify-center border-x border-b border-current ${blockColor}`}
        >
          <div className="pt-8 pb-6 px-4 text-3xl">安さ</div>
          <div className="pt-8 pb-6 px-4 text-base">＜</div>
          <div className="pt-8 pb-6 px-4 text-base">
            {editMode ? (
              <CompromisePointCheckBoxForm
                keyName="compromise_point"
                customer={editCustomer}
                setCustomer={setEditCustomer}
                classString=""
              />
            ) : (
              <CompromisePointView
                compromisePoints={customerView.compromise_point}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

const CompromisePointView: React.FC<CompromisePointViewProps> = (
  props: CompromisePointViewProps
) => {
  const { compromisePoints } = props
  return (
    <div>
      {compromisePoints.map((point, index) => {
        return (
          <div className="text-xl" key={`compromisePointView-${index}`}>
            {compromisePointDict[point]}
          </div>
        )
      })}
    </div>
  )
}

export const SmCustomerChartGraphBlock: React.FC<
  CustomerChartGraphBlockProps
> = (props: CustomerChartGraphBlockProps) => {
  const { customerView, editCustomer, setEditCustomer, editMode } = props
  const blockColor = ''
  return (
    <>
      <div className="grid grid-cols-4 rounded-none">
        <div className="col-start-1 col-span-4 border border-current p-1 font-semibold bg-cyan-100">
          安さ引き換えに妥協できる点
        </div>
        <div
          className={`col-start-1 col-span-4 relative flex items-center justify-center border-x border-b border-current ${blockColor}`}
        >
          <div className="pt-8 pb-6 px-4 text-3xl">安さ</div>
          <div className="pt-8 pb-6 px-4 text-base">＜</div>
          <div className="pt-8 pb-6 px-4 text-base">
            {editMode ? (
              <CompromisePointCheckBoxForm
                keyName="compromise_point"
                customer={editCustomer}
                setCustomer={setEditCustomer}
                classString=""
              />
            ) : (
              <CompromisePointView
                compromisePoints={customerView.compromise_point}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}
