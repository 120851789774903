import React from 'react'
import { TextField } from '@mui/material'
import type { SxProps } from '@mui/material'
import type {
  Appointment,
  AppointmentTextHeaderKey,
} from '../../../../models/Appointment'
import { handleChangeText } from '../../../../utils/appointment/appointmentStateHandler'

interface TextFieldFormProps {
  label?: string
  keyName: AppointmentTextHeaderKey
  appointment: Appointment
  setAppointment: React.Dispatch<React.SetStateAction<Appointment>>
  sx?: SxProps
  disabled?: boolean
}

export const TextFieldForm: React.FC<TextFieldFormProps> = (
  props: TextFieldFormProps
) => {
  const { label, keyName, appointment, setAppointment, sx, disabled } = props

  return (
    <>
      <TextField
        fullWidth
        id={keyName}
        label={label}
        variant="standard"
        value={appointment[keyName] != null ? appointment[keyName] : ''}
        sx={sx}
        onChange={handleChangeText({
          key: keyName,
          appointment,
          setAppointment,
        })}
        disabled={disabled}
      />
    </>
  )
}
