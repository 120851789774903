import type { Dayjs } from 'dayjs'
import type { SelectChangeEvent } from '@mui/material'
import type {
  InquiryTextHeaderKey,
  InquiryNumberHeaderKey,
  InquiryDateHeaderKey,
  InquiryBoolHeaderKey,
  InquirySelectHeaderKey,
  HandleChangeProps,
} from '../../models/Inquiry'
import { datetimeFormat, dateFormat } from '../../fixture/common/formOptions'

export const handleChangeText =
  (props: HandleChangeProps<InquiryTextHeaderKey>) =>
  (event: React.ChangeEvent<HTMLInputElement>) => {
    const { inquiry, key, setInquiry } = props
    setInquiry({ ...inquiry, [key]: event.target.value })
  }

export const handleChangeNumber =
  (props: HandleChangeProps<InquiryNumberHeaderKey>) =>
  (event: React.ChangeEvent<HTMLInputElement>) => {
    const { key, inquiry, setInquiry } = props
    const result = Math.abs(Number(event.target.value)).toString()

    if (result === 'NaN') {
      setInquiry({ ...inquiry, [key]: 0 })
    } else {
      setInquiry({ ...inquiry, [key]: Number(result) })
    }
  }

export const handleChangeDateTime =
  (props: HandleChangeProps<InquiryDateHeaderKey>) =>
  (newValue: Dayjs | null): void => {
    const { inquiry, key, setInquiry } = props
    const dateValue = newValue?.format(datetimeFormat)
    setInquiry({ ...inquiry, [key]: dateValue })
  }

export const handleChangeDate =
  (props: HandleChangeProps<InquiryDateHeaderKey>) =>
  (newValue: Dayjs | null): void => {
    const { inquiry, key, setInquiry } = props
    const dateValue = newValue?.format(dateFormat)
    setInquiry({ ...inquiry, [key]: dateValue })
  }

export const handleChangeSelect =
  (props: HandleChangeProps<InquirySelectHeaderKey>) =>
  (event: SelectChangeEvent) => {
    const { inquiry, key, setInquiry } = props
    setInquiry({ ...inquiry, [key]: event.target.value })
  }

export const handleChangeBool =
  (props: HandleChangeProps<InquiryBoolHeaderKey>) =>
  (event: React.ChangeEvent<HTMLInputElement>) => {
    const { inquiry, key, setInquiry } = props
    const incomming = event.target.value
    let newInquiry = {}
    if (incomming === '') {
      delete inquiry[key]
      newInquiry = { ...inquiry }
    } else if (incomming === 'true') {
      newInquiry = { ...inquiry, [key]: true }
    } else if (incomming === 'false') {
      newInquiry = { ...inquiry, [key]: false }
    }
    setInquiry(newInquiry)
  }
