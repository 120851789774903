import React from 'react'
import type { Dayjs } from 'dayjs'
import { Button, Paper } from '@mui/material'
import { SearchTextField } from '../common/SearchTextField'
import {
  SearchDatePickers,
  SmSearchDatePickers,
} from '../common/SearchDatePickers'
import {
  SearchSelector,
  SearchWeekCandidateSelector,
} from '../common/SearchSelector'
import { menuItemsDict } from '../../../fixture/common/formOptions'
import { dateCandidatesGenerator } from '../../../utils/common/dateCandidatesGenerator'
import type { Candidate } from '../../../models/Common'

type ApiCall = () => void

interface SearchInputFormProps {
  searchText: string
  setSearchText: React.Dispatch<React.SetStateAction<string>>
  startDate: Dayjs | null
  setStartDate: React.Dispatch<React.SetStateAction<Dayjs | null>>
  endDate: Dayjs | null
  setEndDate: React.Dispatch<React.SetStateAction<Dayjs | null>>
  searchAppointmentStatus: string
  setSearchAppointmentStatus: React.Dispatch<React.SetStateAction<string>>
  handleCallApi: ApiCall
}

interface ResponsiveSearchInputFormProps {
  searchText: string
  setSearchText: React.Dispatch<React.SetStateAction<string>>
  startDate: Dayjs | null
  setStartDate: React.Dispatch<React.SetStateAction<Dayjs | null>>
  endDate: Dayjs | null
  setEndDate: React.Dispatch<React.SetStateAction<Dayjs | null>>
  searchAppointmentStatus: string
  setSearchAppointmentStatus: React.Dispatch<React.SetStateAction<string>>
  handleCallApi: ApiCall
  handleSearchInputClear: () => void
  dateCandidates: Candidate[]
  dateCandidate: string
  setDateCandidate: React.Dispatch<React.SetStateAction<string>>
}

export const SearchInputForm: React.FC<SearchInputFormProps> = (
  props: SearchInputFormProps
) => {
  const {
    searchText,
    setSearchText,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    searchAppointmentStatus,
    setSearchAppointmentStatus,
    handleCallApi,
  } = props
  const [dateCandidate, setDateCandidate] = React.useState<string>('')

  const handleSearchInputClear = (): void => {
    // const nowDays = dayjs()
    // setStartDate(nowDays.startOf('month'))
    // setEndDate(nowDays.add(2, 'month').endOf('month'))
    setStartDate(null)
    setEndDate(null)
    setDateCandidate('')
    setSearchText('')
    setSearchAppointmentStatus('')
  }

  const dateCandidates = dateCandidatesGenerator({
    startWeekIndex: -12,
    endWeekIndex: 4,
  })

  return (
    <>
      <div className="md:block hidden">
        <MdSearchInputForm
          searchText={searchText}
          setSearchText={setSearchText}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          searchAppointmentStatus={searchAppointmentStatus}
          setSearchAppointmentStatus={setSearchAppointmentStatus}
          handleCallApi={handleCallApi}
          handleSearchInputClear={handleSearchInputClear}
          dateCandidates={dateCandidates}
          dateCandidate={dateCandidate}
          setDateCandidate={setDateCandidate}
        />
      </div>
      <div className="md:hidden">
        <SmSearchInputForm
          searchText={searchText}
          setSearchText={setSearchText}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          searchAppointmentStatus={searchAppointmentStatus}
          setSearchAppointmentStatus={setSearchAppointmentStatus}
          handleCallApi={handleCallApi}
          handleSearchInputClear={handleSearchInputClear}
          dateCandidates={dateCandidates}
          dateCandidate={dateCandidate}
          setDateCandidate={setDateCandidate}
        />
      </div>
    </>
  )
}

const MdSearchInputForm: React.FC<ResponsiveSearchInputFormProps> = (
  props: ResponsiveSearchInputFormProps
) => {
  const {
    searchText,
    setSearchText,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    searchAppointmentStatus,
    setSearchAppointmentStatus,
    handleCallApi,
    handleSearchInputClear,
    dateCandidates,
    dateCandidate,
    setDateCandidate,
  } = props

  return (
    <>
      <Paper className="m-5 px-5 py-2">
        <div className="px-3 pt-1 text-lg font-medium">検索</div>
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-between">
            <div className="my-4">
              <SearchDatePickers
                label="案内"
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                setDateCandidate={setDateCandidate}
              />
            </div>
            <div className="ml-6">
              <SearchWeekCandidateSelector
                candidates={dateCandidates}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                dateCandidate={dateCandidate}
                setDateCandidate={setDateCandidate}
                label="週候補選択"
              />
            </div>
          </div>
        </div>

        <div className="px-3 pt-1 text-lg font-medium">絞り込み</div>
        <div className="flex items-center justify-between pb-4">
          <div className="ml-6 flex">
            <div className="w-2/5">
              <SearchTextField
                label="顧客名"
                textValue={searchText}
                setTextValue={setSearchText}
              />
            </div>
            <div className="ml-4">
              <SearchSelector
                candidates={menuItemsDict.appointment_status}
                selectValue={searchAppointmentStatus}
                setSelectValue={setSearchAppointmentStatus}
                label="案内ステータス"
              />
            </div>
          </div>
          <div className="mr-4">
            <Button variant="outlined" onClick={handleSearchInputClear}>
              入力クリア
            </Button>
            <Button sx={{ ml: 2 }} variant="contained" onClick={handleCallApi}>
              検索
            </Button>
          </div>
        </div>
      </Paper>
    </>
  )
}

const SmSearchInputForm: React.FC<ResponsiveSearchInputFormProps> = (
  props: ResponsiveSearchInputFormProps
) => {
  const {
    searchText,
    setSearchText,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    searchAppointmentStatus,
    setSearchAppointmentStatus,
    handleCallApi,
    handleSearchInputClear,
    dateCandidates,
    dateCandidate,
    setDateCandidate,
  } = props

  return (
    <>
      <Paper className="m-2 px-5 py-2">
        <div className="text-sm font-medium mb-2">検索</div>
        <div className="">
          <SmSearchDatePickers
            label="案内"
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            setDateCandidate={setDateCandidate}
          />
        </div>
        <div className="flex justify-end">
          <div className="">
            <SearchWeekCandidateSelector
              candidates={dateCandidates}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              dateCandidate={dateCandidate}
              setDateCandidate={setDateCandidate}
              label="週候補選択"
            />
          </div>
        </div>

        <div className="text-sm font-medium mt-1">絞り込み</div>
        <div className="flex">
          <div className="w-2/5">
            <SearchTextField
              label="顧客名"
              textValue={searchText}
              setTextValue={setSearchText}
            />
          </div>
          <div className="ml-4">
            <SearchSelector
              candidates={menuItemsDict.appointment_status}
              selectValue={searchAppointmentStatus}
              setSelectValue={setSearchAppointmentStatus}
              label="案内ステータス"
            />
          </div>
        </div>
        <div className="flex justify-end mt-3">
          <div className="mt-2 ml-2">
            <Button variant="outlined" onClick={handleSearchInputClear}>
              クリア
            </Button>
            <Button sx={{ ml: 1 }} variant="contained" onClick={handleCallApi}>
              検索
            </Button>
          </div>
        </div>
      </Paper>
    </>
  )
}
